import {
  GET_CHAT_MESSAGES,
  GET_DATA,
  GET_LIST,
  GET_DOCUMENTS,
  GET_DOCUMENT,
  GET_CLIENTS,
  GET_CONFIG,
  SAVE_CONFIG,
  SAVE_EMAIL_ADDRESS,
  SAVE_EMAIL_ADDRESS_ERROR,
  LOGIN_FAIL,
  USER_LOADED,
} from "./types";
import axios from "axios";

import { ApiURL } from "../../config";
import { returnErrors } from "./messages";

export const updateFirebaseToken =
  (firebaseToken) => async (dispatch, getState) => {
    try {
      let res = await axios.post(
        `${ApiURL}/notifications/updateFirebaseToken`,
        { firebaseToken },
        tokenConfig(getState),
      );
      dispatch({
        type: GET_DATA,
        payload: res.data,
      });
    } catch (err) {
      console.log("logAction: ", err);
    }
  };
export const sendFirebaseNotification =
  (firebaseTokens) => async (dispatch, getState) => {
    try {
      let res = await axios.post(
        `${ApiURL}/notifications/sendFirebaseNotification`,
        { firebaseTokens },
        tokenConfig(getState),
      );
      dispatch({
        type: GET_DATA,
        payload: res.data,
      });
    } catch (err) {
      console.log("logAction: ", err);
    }
  };
export const exportOverview = (tableName) => async (dispatch, getState) => {
  try {
    let res = await axios.post(
      `${ApiURL}/export/overview`,
      { tableName },
      tokenConfig(getState),
    );
    dispatch({
      type: GET_DATA,
      payload: res.data,
    });
  } catch (err) {
    console.log("logAction: ", err);
  }
};
export const getList =
  (tableName, extraTables) => async (dispatch, getState) => {
    try {
      let res = await axios.post(
        `${ApiURL}/getList`,
        { tableName, extraTables },
        tokenConfig(getState),
      );
      dispatch({
        type: GET_DATA,
        payload: res.data,
      });
    } catch (err) {
      console.log("logAction: ", err);
    }
  };
export const testEmail = () => async (dispatch, getState) => {
  try {
    let res = await axios.get(`${ApiURL}/test-mail`, tokenConfig(getState));
    dispatch({
      type: GET_DATA,
      payload: res.data,
    });
  } catch (err) {
    console.log("logAction: ", err);
  }
};
export const getUserRoles = (organizationId) => async (dispatch, getState) => {
  try {
    let res = await axios.post(
      `${ApiURL}/getUserRoles`,
      { organizationId },
      tokenConfig(getState),
    );
    dispatch({
      type: GET_DATA,
      payload: res.data,
    });
  } catch (err) {
    console.log("logAction: ", err);
  }
};

export const getLinkedTable =
  (linkedTableName, linkedTableInfo) => async (dispatch, getState) => {
    try {
      let res = await axios.post(
        `${ApiURL}/getLinkedTable`,
        { linkedTableName, linkedTableInfo },
        tokenConfig(getState),
      );
      dispatch({
        type: GET_DATA,
        payload: res.data,
      });
    } catch (err) {
      console.log("logAction: ", err);
    }
  };
export const getTable =
  (
    tableName,
    currentPage,
    recordsPerPage,
    sortFieldName,
    sortDirection,
    searchText,
    organizationId,
    clickedSearchColumns,
    clickedFilters,
  ) =>
  async (dispatch, getState) => {
    try {
      let res = await axios.post(
        `${ApiURL}/getTable`,
        {
          tableName,
          currentPage,
          recordsPerPage,
          sortFieldName,
          sortDirection,
          searchText,
          organizationId,
          searchColumns: clickedSearchColumns,
          clickedFilters,
        },
        tokenConfig(getState),
      );
      dispatch({
        type: GET_DATA,
        payload: res.data,
      });
    } catch (err) {
      console.log("logAction: ", err);
    }
  };
export const getSubDataTable =
  (
    tableName,
    currentPage,
    recordsPerPage,
    sortFieldName,
    sortDirection,
    searchText,
  ) =>
  async (dispatch, getState) => {
    try {
      let res = await axios.post(
        `${ApiURL}/getTable`,
        {
          tableName,
          currentPage,
          recordsPerPage,
          sortFieldName,
          sortDirection,
          searchText,
        },
        tokenConfig(getState),
      );
      dispatch({
        type: GET_DATA,
        payload: res.data,
      });
    } catch (err) {
      console.log("logAction: ", err);
    }
  };
export const getContent =
  (contentPage, languageCode) => async (dispatch, getState) => {
    try {
      let res = await axios.post(
        `${ApiURL}/getContent`,
        { contentPage, languageCode },
        tokenConfig(getState),
      );
      dispatch({
        type: GET_DATA,
        payload: res.data,
      });
    } catch (err) {
      console.log("logAction: ", err);
    }
  };

export const getRecord = (tableName, tableId) => async (dispatch, getState) => {
  try {
    let res = await axios.post(
      `${ApiURL}/getRecord`,
      { tableName, tableId },
      tokenConfig(getState),
    );
    dispatch({
      type: GET_DATA,
      payload: res.data,
    });
  } catch (err) {
    console.log("logAction: ", err);
  }
};
export const deleteRecord =
  (tableName, tableId) => async (dispatch, getState) => {
    try {
      let res = await axios.post(
        `${ApiURL}/deleteRecord`,
        { tableName, tableId },
        tokenConfig(getState),
      );
      dispatch({
        type: GET_DATA,
        payload: res.data,
      });
    } catch (err) {
      console.log("logAction: ", err);
    }
  };
export const archiveRecord =
  (tableName, tableId, activeStatus) => async (dispatch, getState) => {
    try {
      let res = await axios.post(
        `${ApiURL}/archiveRecord`,
        { tableName, tableId, activeStatus },
        tokenConfig(getState),
      );
      dispatch({
        type: GET_DATA,
        payload: res.data,
      });
    } catch (err) {
      console.log("logAction: ", err);
    }
  };
export const inviteUser =
  (organizationId, emailAddress, userRole, firstName, lastName) =>
  async (dispatch, getState) => {
    try {
      let res = await axios.post(
        `${ApiURL}/sendUserInvite`,
        {
          firstName: firstName,
          lastName: lastName,
          inviteCompany: organizationId,
          inviteEmail: emailAddress,
          userRole: userRole,
        },
        tokenConfig(getState),
      );
      dispatch({
        type: GET_DATA,
        payload: res.data,
      });
    } catch (err) {
      console.log("logAction: ", err);
    }
  };

export const saveAccount =
  (
    inviteId,
    firstName,
    lastName,
    email,
    password,
    passwordConfirm,
    cellPhone,
    workPhone,
  ) =>
  async (dispatch, getState) => {
    try {
      let res = await axios.post(
        `${ApiURL}/saveAccount`,
        {
          inviteId,
          firstName,
          lastName,
          email,
          password,
          passwordConfirm,
          cellPhone,
          workPhone,
        },
        tokenConfig(getState),
      );
      dispatch({
        type: GET_DATA,
        payload: res.data,
      });
    } catch (err) {
      console.log("logAction: ", err);
    }
  };

export const loadInvite = (inviteId) => async (dispatch, getState) => {
  try {
    let res = await axios.post(
      `${ApiURL}/loadInvite`,
      {
        inviteId,
      },
      tokenConfig(getState),
    );
    dispatch({
      type: GET_DATA,
      payload: res.data,
    });
  } catch (err) {
    console.log("logAction: ", err);
  }
};
export const resendInvite = (userId) => async (dispatch, getState) => {
  try {
    let res = await axios.post(
      `${ApiURL}/resendInvite`,
      {
        userId,
      },
      tokenConfig(getState),
    );
    dispatch({
      type: GET_DATA,
      payload: res.data,
    });
  } catch (err) {
    console.log("logAction: ", err);
  }
};

export const archiveSubDataRecord =
  (tableName, tableId, activeStatus) => async (dispatch, getState) => {
    try {
      let res = await axios.post(
        `${ApiURL}/archiveRecord`,
        { tableName, tableId, activeStatus },
        tokenConfig(getState),
      );
      dispatch({
        type: GET_DATA,
        payload: res.data,
      });
    } catch (err) {
      console.log("logAction: ", err);
    }
  };
export const saveRecord =
  (tableName, tableId, data) => async (dispatch, getState) => {
    try {
      let res = await axios.post(
        `${ApiURL}/saveRecord`,
        { tableName, tableId, data },
        tokenConfig(getState),
      );
      dispatch({
        type: GET_DATA,
        payload: res.data,
      });
    } catch (err) {
      console.log("logAction: ", err);
    }
  };

export const startNewChat = (chatMember) => async (dispatch, getState) => {
  try {
    let res = await axios.post(
      `${ApiURL}/startNewChat`,
      { chatMember },
      tokenConfig(getState),
    );
    dispatch({
      type: GET_DATA,
      payload: res.data,
    });
  } catch (err) {
    console.log("logAction: ", err);
  }
};
export const loadDashboard = () => async (dispatch, getState) => {
  try {
    let res = await axios.get(`${ApiURL}/loadDashboard`, tokenConfig(getState));
    dispatch({
      type: GET_DATA,
      payload: res.data,
    });
  } catch (err) {
    console.log("logAction: ", err);
  }
};

export const getProfileInfo = () => async (dispatch, getState) => {
  try {
    let res = await axios.get(
      `${ApiURL}/getProfileInfo`,
      tokenConfig(getState),
    );
    dispatch({
      type: GET_DATA,
      payload: res.data,
    });
  } catch (err) {
    console.log("logAction: ", err);
  }
};

export const getChatMessages = (roomId) => async (dispatch, getState) => {
  try {
    let res = await axios.get(
      `${ApiURL}/getChatMessages/${roomId}`,
      tokenConfig(getState),
    );
    dispatch({
      type: GET_CHAT_MESSAGES,
      payload: res.data,
    });
  } catch (err) {
    console.log("logAction: ", err);
  }
};
export const getChatRooms = () => async (dispatch, getState) => {
  try {
    let res = await axios.get(`${ApiURL}/getChatRooms`, tokenConfig(getState));
    dispatch({
      type: GET_CHAT_MESSAGES,
      payload: res.data,
    });
  } catch (err) {
    console.log("logAction: ", err);
  }
};

export const getNotifications = () => async (dispatch, getState) => {
  try {
    let res = await axios.get(
      `${ApiURL}/getNotifications`,
      tokenConfig(getState),
    );
    dispatch({
      type: USER_LOADED,
      payload: res.data,
    });
  } catch (err) {
    console.log("logAction: ", err);
  }
};
export const getNotificationList = () => async (dispatch, getState) => {
  try {
    let res = await axios.get(
      `${ApiURL}/getNotificationList`,
      tokenConfig(getState),
    );
    dispatch({
      type: GET_DATA,
      payload: res.data,
    });
  } catch (err) {
    console.log("logAction: ", err);
  }
};

export const markNotificationRead =
  (notificationId) => async (dispatch, getState) => {
    try {
      let res = await axios.post(
        `${ApiURL}/markNotificationRead`,
        { notificationId },
        tokenConfig(getState),
      );
      dispatch({
        type: GET_DATA,
        payload: res.data,
      });
    } catch (err) {
      console.log("logAction: ", err);
    }
  };

export const saveConfigSettings =
  (
    fromName,
    fromEmail,
    paymentSubject,
    reportEmailTime,
    reportEmailSubject,
    reportEmailMessage,
  ) =>
  async (dispatch, getState) => {
    try {
      const res = await axios.post(
        `${ApiURL}/saveConfigSettings`,
        {
          fromName,
          fromEmail,
          paymentSubject,
          reportEmailTime,
          reportEmailSubject,
          reportEmailMessage,
        },
        tokenConfig(getState),
      );
      dispatch({
        type: SAVE_CONFIG,
        payload: res.data,
      });
    } catch (err) {
      dispatch(returnErrors(err.response.data, err.response.status));
      dispatch({ type: LOGIN_FAIL });
    }
  };
export const getConfigSettings = () => async (dispatch, getState) => {
  try {
    const res = await axios.get(
      `${ApiURL}/getConfigSettings`,
      tokenConfig(getState),
    );
    dispatch({
      type: GET_CONFIG,
      payload: res.data,
    });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
    dispatch({ type: LOGIN_FAIL });
  }
};

// DOCUMENT CENTER

export const saveEmailAddresses =
  (emailAddresses, reportType) => async (dispatch, getState) => {
    try {
      const res = await axios.post(
        `${ApiURL}/saveEmailAddresses`,
        { emailAddresses, reportType },
        tokenConfig(getState),
      );
      dispatch({ type: SAVE_EMAIL_ADDRESS, payload: res.data });
    } catch (err) {
      dispatch({ type: SAVE_EMAIL_ADDRESS_ERROR, payload: err.response });
      dispatch({ type: LOGIN_FAIL });
    }
  };

export const deleteUserRole = (roleId) => async (dispatch, getState) => {
  try {
    const res = await axios.post(
      `${ApiURL}/deleteUserRole`,
      { id: roleId },
      tokenConfig(getState),
    );
    dispatch({
      type: GET_LIST,
      payload: res.data,
    });
  } catch (e) {
    console.log("e", e);
  }
};
export const getUserRole = (roleId) => async (dispatch, getState) => {
  try {
    const res = await axios.post(
      `${ApiURL}/getUserRole`,
      { id: roleId },
      tokenConfig(getState),
    );
    dispatch({
      type: GET_DATA,
      payload: res.data,
    });
  } catch (e) {
    console.log("e", e);
  }
};
export const saveUserRole = () => async (dispatch, getState) => {
  try {
    const res = await axios.get(
      `${ApiURL}/saveUserRole`,
      tokenConfig(getState),
    );
    dispatch({
      type: GET_DATA,
      payload: res.data,
    });
  } catch (e) {
    console.log("e", e);
  }
};

// DOCUMENTS
export const getDocuments = () => async (dispatch, getState) => {
  try {
    const res = await axios.get(
      `${ApiURL}/documentCenter/getDocuments`,
      tokenConfig(getState),
    );
    dispatch({
      type: GET_DOCUMENTS,
      payload: res.data,
    });
  } catch (e) {
    console.log("e", e);
  }
};
export const getMyDocuments = () => async (dispatch, getState) => {
  try {
    const res = await axios.get(
      `${ApiURL}/documentCenter/getMyDocuments`,
      tokenConfig(getState),
    );
    dispatch({
      type: GET_DOCUMENTS,
      payload: res.data,
    });
  } catch (e) {
    console.log("e", e);
  }
};
export const getFileCabinet = () => async (dispatch, getState) => {
  try {
    const res = await axios.get(
      `${ApiURL}/documentCenter/getFileCabinet`,
      tokenConfig(getState),
    );
    dispatch({
      type: GET_DOCUMENTS,
      payload: res.data,
    });
  } catch (e) {
    console.log("e", e);
  }
};
export const deleteFile = (fileId, clientId) => async (dispatch, getState) => {
  try {
    const res = await axios.post(
      `${ApiURL}/documentCenter/deleteFile`,
      { fileId, clientId },
      tokenConfig(getState),
    );
    dispatch({
      type: GET_LIST,
      payload: res.data,
    });
    dispatch({
      type: GET_DOCUMENTS,
      payload: res.data,
    });
  } catch (e) {
    console.log("e", e);
  }
};
export const moveFile =
  (clientId, fileId, folderId) => async (dispatch, getState) => {
    try {
      const res = await axios.post(
        `${ApiURL}/documentCenter/fileMove`,
        { clientId, fileId, folderId },
        tokenConfig(getState),
      );
      dispatch({
        type: GET_LIST,
        payload: res.data,
      });
    } catch (e) {
      console.log("e", e);
    }
  };
export const archiveFile = (fileId) => async (dispatch, getState) => {
  try {
    const res = await axios.post(
      `${ApiURL}/documentCenter/archiveFile`,
      { fileId },
      tokenConfig(getState),
    );
    dispatch({
      type: GET_DOCUMENTS,
      payload: res.data,
    });
  } catch (e) {
    console.log("e", e);
  }
};
export const getFileDetails = (fileId) => async (dispatch, getState) => {
  try {
    const res = await axios.post(
      `${ApiURL}/documentCenter/getFileDetails`,
      { fileId },
      tokenConfig(getState),
    );
    dispatch({
      type: GET_DOCUMENT,
      payload: res.data,
    });
  } catch (e) {
    console.log("e", e);
  }
};
export const getDocumentClients = () => async (dispatch, getState) => {
  try {
    const res = await axios.get(
      `${ApiURL}/documentCenter/getDocumentClients`,
      tokenConfig(getState),
    );
    dispatch({
      type: GET_CLIENTS,
      payload: res.data,
    });
  } catch (e) {
    console.log("e", e);
  }
};
export const updateDocumentClients =
  (sortdir, sortField) => async (dispatch, getState) => {
    try {
      const res = await axios.post(
        `${ApiURL}/documentCenter/updateDocumentClients`,
        { sortdir, sortField },
        tokenConfig(getState),
      );
      dispatch({
        type: GET_CLIENTS,
        payload: res.data,
      });
    } catch (e) {
      console.log("e", e);
    }
  };
export const getClientFolders =
  (clientId, sortDir, sortField) => async (dispatch, getState) => {
    try {
      const res = await axios.post(
        `${ApiURL}/documentCenter/getClientFolders`,
        { clientId, sortDir, sortField },
        tokenConfig(getState),
      );
      dispatch({
        type: GET_LIST,
        payload: res.data,
      });
    } catch (e) {
      console.log("e", e);
    }
  };
export const getClientFiles =
  (clientId, fullPath, sortDir, sortField, folderId) =>
  async (dispatch, getState) => {
    try {
      const res = await axios.post(
        `${ApiURL}/documentCenter/getClientFiles`,
        { clientId, fullPath, sortDir, sortField, folderId },
        tokenConfig(getState),
      );
      dispatch({
        type: GET_LIST,
        payload: res.data,
      });
      dispatch({
        type: GET_DATA,
        payload: res.data,
      });
    } catch (e) {
      console.log("e", e);
    }
  };
export const deleteFolder =
  (clientId, folderId) => async (dispatch, getState) => {
    try {
      const res = await axios.post(
        `${ApiURL}/documentCenter/deleteFolder`,
        { clientId, folderId },
        tokenConfig(getState),
      );
      dispatch({
        type: GET_DOCUMENTS,
        payload: res.data,
      });
    } catch (e) {
      console.log("e", e);
    }
  };
export const favoriteFile = (fileId) => async (dispatch, getState) => {
  try {
    const res = await axios.post(
      `${ApiURL}/documentCenter/favoriteFile`,
      { fileId },
      tokenConfig(getState),
    );

    dispatch({
      type: GET_DATA,
      payload: res.data,
    });
  } catch (e) {
    console.log("e", e);
  }
};
export const unFavoriteFile = (fileId) => async (dispatch, getState) => {
  try {
    const res = await axios.post(
      `${ApiURL}/documentCenter/unFavoriteFile`,
      { fileId },
      tokenConfig(getState),
    );

    dispatch({
      type: GET_DATA,
      payload: res.data,
    });
  } catch (e) {
    console.log("e", e);
  }
};

// SETTINGS
export const syncFolders = () => async (dispatch, getState) => {
  try {
    const res = await axios.get(`${ApiURL}/syncFolders`, tokenConfig(getState));
    dispatch({
      type: GET_LIST,
      payload: res.data,
    });
  } catch (e) {
    console.log("e", e);
  }
};

export const tokenConfig = (getState = "", controller = "") => {
  // GET TOKEN FROM STATE
  const token = localStorage.getItem("token");

  // HEADERS
  const config = {
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  };

  // IF TOKEN ADD TO HEADERS
  if (token) {
    config.headers["Authorization"] = token;
  }
  return config;
};
