import React from "react";
import { Spinner } from "reactstrap";

const Loader = () => (
  <div className="container vh-50 d-flex">
    <div className="justify-content-center align-self-center w-100 text-center">
      <Spinner color="primary" />
    </div>
  </div>
);

export default Loader;
