import React from "react";
import SidebarItem from "./SidebarItem";

const SideBarLink = ({ permissionFound, name, to, icon, badgeColor, badgeText }) => {
	if (permissionFound !== -1) {
		return (<SidebarItem
			name={name}
			to={to}
			icon={icon}
			badgeColor={badgeColor}
			badgeText={badgeText}
		/>);
	}
	else {
		return null;
	}
}

export default SideBarLink;
