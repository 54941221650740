import {withRouter} from "../../../client/utils/withRouter";
import {NavLink} from "react-router-dom";
import {Badge} from "reactstrap";
import React from "react";

const SidebarItem = ({ name, badgeColor, badgeText, icon: Icon, location, to }) => {
	const getSidebarItemClass = path => {
		return location?.pathname === path ? "active" : "";
	};

	return (
		<li className={"sidebar-item " + getSidebarItemClass(to)}>
			<NavLink to={to}  className="sidebar-link active">
				{Icon ? <Icon size={18} className="align-middle me-3" /> : null}
				{name}
				{badgeColor && badgeText ? (
					<Badge color={badgeColor} size={18} className="sidebar-badge">
						{badgeText}
					</Badge>
				) : null}
			</NavLink>
		</li>
	);
}

export default withRouter(SidebarItem);
