import React from "react";

const FormInputHidden = (props) => {
  return (
    <input
      id={props.name}
      name={props.name}
      type="hidden"
      value={props.value}
    />
  );
};

export default FormInputHidden;
