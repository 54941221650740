import React, { useEffect, useState } from "react";
import FormInput from "../../components/form/FormInput";
import { loadInvite, saveAccount } from "../../client/actions/apiActions";

import logo from "../../assets/img/logo/logo-lng.png";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { ApiURL } from "../../config";

const Invite = (props) => {
  const navigate = useNavigate();
  const { inviteCode } = useParams();
  const [loading, setLoading] = useState(true);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [cellPhone, setCellPhone] = useState("");
  const [workPhone, setWorkPhone] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [message, setMessage] = useState("");

  const onCreateAccount = async () => {
    const res = await axios.post(
      `${ApiURL}/saveAccount`,
      {
        inviteId: inviteCode,
        firstName,
        lastName,
        email,
        password,
        passwordConfirm: confirmPassword,
        cellPhone,
        workPhone,
      },
      {},
    );

    if (res.data.status === true) {
      navigate("/auth/sign-in");
    } else {
      setMessage(props.data.message);
    }
  };

  // initial update only
  useEffect(async () => {
    const res = await axios.post(
      `${ApiURL}/loadInvite`,
      {
        inviteId: inviteCode,
      },
      {},
    );

    if (res.data.status === true) {
      setLoading(false);
      setFirstName(res.data.firstName);
      setLastName(res.data.lastName);
      setEmail(res.data.email);
      setWorkPhone(res.data.workPhone);
    } else {
      setMessage("Error loading invite");
    }
  }, []);

  if (loading) {
    return (
      <div className="conatainer">
        <div className="row">
          <div className="col-12 text-center">
            <img src={logo} className="img-fluid" alt="logo" />
            <h1>Loading Invite</h1>
          </div>
        </div>
        {message && (
          <div className="row">
            <div className="col-12 alert alert-danger p-2 text-dark">
              {message}
            </div>
          </div>
        )}
      </div>
    );
  }

  return (
    <div className="card">
      <div className="card-body">
        <div className="container">
          <div className="row">
            <div className="col-12 text-center">
              <img
                src={logo}
                className="img-fluid"
                alt="two hands shaking representing collaborating"
              />
            </div>
            <div className="col-12 mb-4 text-center">
              <h4>You have been invited to join Simply Collaborate</h4>
            </div>
          </div>
          {message && (
            <div className="row">
              <div className="col-12 alert alert-danger p-2 text-dark">
                {message}
              </div>
            </div>
          )}
          <div className="row">
            <div className="col-12">
              <h2>Personal Information</h2>
            </div>
          </div>
          <div className="row">
            <FormInput
              id="firstName"
              name="firstName"
              label="First Name"
              value={firstName}
              placeholder={"first name"}
              colSize={2}
              onChange={(type, e, field) => setFirstName(e.target.value)}
            />
            <FormInput
              id="lastName"
              name="lastName"
              label="Last Name"
              placeholder={"last name"}
              value={lastName}
              colSize={2}
              onChange={(type, e, field) => setLastName(e.target.value)}
            />
            <FormInput
              id="cellPhone"
              name="cellPhone"
              label="Phone"
              placeholder={"phone"}
              value={cellPhone}
              colSize={2}
              onChange={(type, e, field) => setCellPhone(e.target.value)}
            />
          </div>
          <div className="row">
            <div className="col-12 mt-4">
              <h2>Log In Information</h2>
            </div>
            <div className="col-12">
              <small>
                Make sure to enter in a strong password to keep your account
                secure.
              </small>
            </div>
          </div>
          <div className="row">
            <FormInput
              type="display"
              id="email"
              name="email"
              label="Email"
              placeholder={"email address"}
              value={email}
              colSize={1}
              onChange={(value) => setEmail(value)}
            />
            <FormInput
              id="password"
              type="password"
              name="password"
              label="Password"
              placeholder={"password"}
              value={password}
              colSize={1}
              onChange={(value) => setPassword(value)}
            />
            <FormInput
              id="confirmPassword"
              type="password"
              name="confirmPassword"
              label="Confirm Password"
              placeholder={"confirm password"}
              value={confirmPassword}
              colSize={1}
              onChange={(value) => setConfirmPassword(value)}
            />
          </div>
          <div className="row mt-2">
            <div className="col-6">
              <a
                href="https://www.simplycollaborate.com"
                className="btn btn-warning btn-sm"
              >
                Cancel
              </a>
            </div>
            <div className="col-6 text-end">
              <button
                className="btn btn-sm btn-success"
                onClick={onCreateAccount}
              >
                Create Account
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => ({
  auth: state.auth.user,
  data: state.pages.data,
});
export default connect(mapStateToProps, { loadInvite, saveAccount })(Invite);
