import React from "react";

import Wrapper from "./Wrapper";
import Sidebar from "./Sidebar";
import Main from "./Main";
import Navbar from "./Navbar";
import Content from "./Content";
import Footer from "./Footer";

const Dashboard = ({ children }) => (
  <>
    <Wrapper>
      <Sidebar />
      <Main>
        <Navbar />
        <Content>{children}</Content>
        <Footer />
      </Main>
    </Wrapper>
  </>
);

export default Dashboard;
