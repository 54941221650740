import React from "react";
import { Save as SaveIcon, XCircle as CancelIcon } from "react-feather";
import { Link } from "react-router-dom";

const Buttons = (props) => {
  const {
    dataResults,
    parentRoute,
    route,
    onButtonCancel,
    singleEdit,
    onSave,
    showClone,
    settings,
    tableName,
  } = props;

  return (
    <div className="container my-3">
      {!singleEdit && (
        <div className="row">
          <div className="col-6 px-0">
            <button
              onClick={() => onSave(dataResults["id"], "save", dataResults)}
              className="ms-0 btn btn-primary"
              data-toggle-id="tooltip"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              data-toggle-title="Tooltip on top"
              data-bs-title="Tooltip on top"
            >
              <SaveIcon size={18} /> &nbsp; Save
            </button>
            {props.mode !== "add" &&
              dataResults.id !== 0 &&
              dataResults.id !== "0" && (
                <button
                  onClick={() =>
                    onSave(dataResults["id"], "save and close", dataResults)
                  }
                  className="ms-1 btn btn-primary"
                >
                  <SaveIcon size={18} /> &nbsp; Save & Close
                </button>
              )}
            {showClone !== false &&
              dataResults.id !== 0 &&
              dataResults.id !== "0" &&
              tableName !== "users" &&
              props.mode !== "add" && (
                <button
                  onClick={() => onSave(0, "clone", dataResults)}
                  className="ms-1 btn btn-secondary"
                >
                  <SaveIcon size={18} /> &nbsp; Save As New
                </button>
              )}
          </div>
          <div className="col-6 px-0 text-end">
            {route === parentRoute && (
              <button onClick={onButtonCancel} className="btn btn-warning">
                <CancelIcon size={18} /> Cancel
              </button>
            )}
            {route !== parentRoute && (
              <Link to={parentRoute} className="btn btn-warning">
                <CancelIcon size={18} /> Cancel
              </Link>
            )}
          </div>
        </div>
      )}
      {singleEdit && (
        <div className="row">
          <div className="col-12">
            <button
              onClick={() => onSave(dataResults["id"], "save", dataResults)}
              className="btn btn-primary"
            >
              <SaveIcon size={18} /> Save
            </button>
            {tableName === "config" && (
              <button
                onClick={() => props.testEmail()}
                className="ms-1 btn btn-primary"
              >
                <SaveIcon size={18} /> Test Email Config
              </button>
            )}
            {settings.showClone !== false && (
              <button
                onClick={() => onSave(0, "clone", dataResults)}
                className="ms-1 btn btn-primary"
              >
                <SaveIcon size={18} /> Clone
              </button>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Buttons;
