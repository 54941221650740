import {
  Settings as SettingsIcon,
  MessageSquare as MessageSquareIcon,
  File as DocumentIcon,
  Sliders as SlidersIcon,
  Users as UsersIcon,
} from "react-feather";

// Auth
import SignIn from "../../pages/auth/SignIn";
import SignUp from "../../pages/auth/SignUp";
import SignOut from "../../pages/auth/SignOut";
import ResetPassword from "../../pages/auth/ResetPassword";
import Page404 from "../../pages/errors/Page404";
import Page500 from "../../pages/errors/Page500";

// Notifications
import Notifications from "../../components/ProfileNotifications";

//
import Chat from "../../pages/Chat";
import Organizations from "../../pages/Organizations";
import TeamMembers from "../../pages/Users";
import EmailTemplates from "../../pages/EmailTemplates";

// default pages
import Profile from "../../components/Profile";

// Documentation
import ProfilePassword from "../../components/ProfilePassword";
import Content from "../../pages/Content";
import ContentPage from "../../components/ContentPage";
import Blank from "../../pages/Content";

import Dashboard from "../../pages/Dashboard";
import UserRoles from "../../pages/UserRoles";
import Users from "../../pages/Users";
import Invite from "../../pages/auth/Invite";

import DocumentDashboard from "../../pages/DocumentDashboard";
import DocumentFoldersFiles from "../../pages/DocumentFoldersFiles";
import Config from "../../pages/Config";
import MyTeam from "../../pages/MyTeam";

// Routes
const landingRoutes = {
  path: "/confirm-invitation",
  name: "Confirm Invitation",
  component: SignUp,
  isPrivate: false,
  children: null,
};

const dashboardRoutes = {
  path: "/",
  name: "Dashboard",
  langName: "dashboard",
  component: Dashboard,
  icon: SlidersIcon,
  containsHome: true,
  isPrivate: true,
  children: null,
};
const chatRoutes = {
  path: "/chat",
  name: "Chat",
  langName: "chat",
  component: Chat,
  isPrivate: true,
  icon: MessageSquareIcon,
  containsHome: true,
  children: null,
  // badgeColor: "primary",
  // badgeText: "5",
};
const documentsRoutes = {
  path: "/documents",
  name: "Client Documents",
  langName: "documents",
  icon: DocumentIcon,
  children: [
    {
      isPrivate: true,
      path: "/documents/dashboard",
      name: "Documents",
      langName: "documentDashboard",
      component: DocumentDashboard,
    },
    {
      isPrivate: true,
      path: "/documents/requests",
      name: "Document Requests",
      langName: "documentRequests",
      component: Blank,
    },

    {
      path: "/my-documents",
      name: "My Documents",
      langName: "myDocuments",
      component: DocumentFoldersFiles,
      isPrivate: true,
      icon: DocumentIcon,
      containsHome: true,
      children: null,
    },
    {
      path: "/my-team",
      name: "My Team",
      langName: "myTeam",
      component: MyTeam,
      isPrivate: true,
      icon: DocumentIcon,
      containsHome: true,
      children: null,
    },
    {
      path: "/my-team/:id",
      name: "My Team",
      langName: "myTeam",
      component: MyTeam,
      isPrivate: true,
      icon: DocumentIcon,
      containsHome: true,
      children: null,
    },

    {
      isPrivate: true,
      path: "/settings/customers",
      name: "Customers",
      langName: "organizations",
      component: Organizations,
    },
  ],
};

// SETTINGS LINK
const settingsRoutes = {
  path: "/settings",
  name: "Settings",
  langName: "settings",
  icon: SettingsIcon,
  children: [
    {
      isPrivate: true,
      path: "/settings/customers",
      name: "Customers",
      langName: "organizations",
      component: Organizations,
    },
    {
      isPrivate: true,
      path: "/settings/customers/:id",
      name: "Customers",
      langName: "organizations",
      component: Organizations,
    },
    {
      isPrivate: true,
      path: "/settings/email-templates",
      name: "Email Templates",
      langName: "emailTemplates",
      component: EmailTemplates,
    },
    {
      isPrivate: true,
      path: "/settings/email-templates/:id",
      name: "Email Templates",
      langName: "emailTemplates",
      component: EmailTemplates,
    },
    {
      isPrivate: true,
      path: "/settings/content",
      name: "Content",
      langName: "content",
      component: Content,
    },
    {
      isPrivate: true,
      path: "/settings/content/:id",
      name: "Content",
      langName: "content",
      component: Content,
    },
    {
      isPrivate: true,
      path: "/settings/userRoles",
      name: "User Roles",
      langName: "userRoles",
      component: UserRoles,
    },
    {
      isPrivate: true,
      path: "/settings/userRoles/:id",
      name: "User Roles",
      langName: "userRoles",
      component: UserRoles,
    },
    {
      isPrivate: true,
      path: "/settings/config",
      name: "Config",
      langName: "config",
      component: Config,
    },
    {
      isPrivate: true,
      path: "/settings/config/:id",
      name: "Config",
      langName: "config",
      component: Config,
    },
  ],
};

const authRoutes = {
  path: "/auth",
  name: "Auth",
  icon: UsersIcon,
  isPrivate: false,
  badgeColor: "secondary",
  badgeText: "Special",
  children: [
    {
      path: "/auth/sign-in",
      name: "Sign In",
      isPrivate: false,
      component: SignIn,
    },
    {
      path: "/auth/sign-out",
      name: "Sign Out",
      isPrivate: false,
      component: SignOut,
    },
    {
      path: "/auth/reset-password",
      name: "Reset Password",
      isPrivate: false,
      component: ResetPassword,
    },
    {
      path: "/invite/:inviteCode",
      name: "Invite",
      isPrivate: false,
      component: Invite,
    },
  ],
};

// ROUTES NOT VISIBLE IN THE SIDEBAR
const privateRoutes = {
  path: "/privacy",
  name: "Privacy",
  children: [
    {
      isPrivate: true,
      path: "/settings/customers/:pageMode",
      name: "Customers",
      langName: "organizations",
      component: Organizations,
    },
    {
      isPrivate: true,
      path: "/organizations/users/:organizationId",
      name: "Organization Users",
      component: TeamMembers,
    },
    {
      isPrivate: true,
      path: "/organizations/users/:organizationId/:id",
      name: "Organization Users",
      component: TeamMembers,
    },
    {
      isPrivate: true,
      path: "/chat/:chatRoom",
      name: "Chat Room",
      component: Chat,
    },
    {
      isPrivate: true,
      path: "/privacy",
      name: "Privacy",
      component: ContentPage,
    },
    {
      isPrivate: true,
      path: "/support-center",
      name: "Support",
      component: ContentPage,
    },
    {
      isPrivate: true,
      path: "/help-center",
      name: "Help Center",
      component: ContentPage,
    },
    {
      isPrivate: true,
      path: "/terms-of-service",
      name: "Terms of Service",
      component: ContentPage,
    },
    {
      isPrivate: true,
      path: "/profile",
      name: "Profile",
      component: Profile,
    },
    {
      isPrivate: true,
      path: "/profile-password",
      name: "Password",
      component: ProfilePassword,
    },
    {
      isPrivate: true,
      path: "/profile-notifications",
      name: "Notifications",
      component: Notifications,
    },
    {
      isPrivate: true,
      path: "/notifications",
      name: "Notifications",
      component: Notifications,
    },
    {
      isPrivate: false,
      path: "/404",
      name: "404 Page",
      component: Page404,
    },
    {
      isPrivate: false,
      path: "/500",
      name: "500 Page",
      component: Page500,
    },
    {
      isPrivate: true,
      path: "/auth/sign-out",
      name: "sign out",
      component: SignOut,
    },
    {
      isPrivate: true,
      path: "/documents",
      name: "Dashboard",
      langName: "documentDashboard",
      component: DocumentDashboard,
    },
    {
      isPrivate: true,
      path: "/documents/:clientId",
      name: "Dashboard",
      langName: "documentDashboard",
      component: DocumentFoldersFiles,
    },
    {
      isPrivate: true,
      path: "/documents/:clientId/:folderId",
      name: "Dashboard",
      langName: "documentDashboard",
      component: DocumentFoldersFiles,
    },
    {
      isPrivate: true,
      path: "/documents/:clientId/:folderId/:folderName1",
      name: "Dashboard",
      langName: "documentDashboard",
      component: DocumentFoldersFiles,
    },
    {
      isPrivate: true,
      path: "/documents/:clientId/:folderId/:folderName1/:folderName2",
      name: "Dashboard",
      langName: "documentDashboard",
      component: DocumentFoldersFiles,
    },
    {
      isPrivate: true,
      path: "/documents/:clientId/:folderId/:folderName1/:folderName2/:folderName3",
      name: "Dashboard",
      langName: "documentDashboard",
      component: DocumentFoldersFiles,
    },
    {
      isPrivate: true,
      path: "/documents/:clientId/:folderId/:folderName1/:folderName2/:folderName3/:folderName4",
      name: "Dashboard",
      langName: "documentDashboard",
      component: DocumentFoldersFiles,
    },
    {
      isPrivate: true,
      path: "/documents/:clientId/:folderId/:folderName1/:folderName2/:folderName3/:folderName4/:folderName5",
      name: "Dashboard",
      langName: "documentDashboard",
      component: DocumentFoldersFiles,
    },
    {
      isPrivate: true,
      path: "/documents/:clientId/:folderId/:folderName1/:folderName2/:folderName3/:folderName4/:folderName5/:folderName6",
      name: "Dashboard",
      langName: "documentDashboard",
      component: DocumentFoldersFiles,
    },
    {
      isPrivate: true,
      path: "/documents/:clientId/:folderId/:folderName1/:folderName2/:folderName3/:folderName4/:folderName5/:folderName6/:folderName7",
      name: "Dashboard",
      langName: "documentDashboard",
      component: DocumentFoldersFiles,
    },
    {
      isPrivate: true,
      path: "/documents/:clientId/:folderId/:folderName1/:folderName2/:folderName3/:folderName4/:folderName5/:folderName6/:folderName7/:folderName8",
      name: "Dashboard",
      langName: "documentDashboard",
      component: DocumentFoldersFiles,
    },
    {
      isPrivate: true,
      path: "/documents/:clientId/:folderId/:folderName1/:folderName2/:folderName3/:folderName4/:folderName5/:folderName6/:folderName7/:folderName8/:folderName9",
      name: "Dashboard",
      langName: "documentDashboard",
      component: DocumentFoldersFiles,
    },
    {
      isPrivate: true,
      path: "/documents/:clientId/:folderId/:folderName1/:folderName2/:folderName3/:folderName4/:folderName5/:folderName6/:folderName7/:folderName8/:folderName9/:folderName10",
      name: "Dashboard",
      langName: "documentDashboard",
      component: DocumentFoldersFiles,
    },
    {
      isPrivate: true,
      path: "/documents/:clientId/:folderId/:folderName1/:folderName2/:folderName3/:folderName4/:folderName5/:folderName6/:folderName7/:folderName8/:folderName9/:folderName10/:folderName11",
      name: "Dashboard",
      langName: "documentDashboard",
      component: DocumentFoldersFiles,
    },
    {
      isPrivate: true,
      path: "/documents/:clientId/:folderId/:folderName1/:folderName2/:folderName3/:folderName4/:folderName5/:folderName6/:folderName7/:folderName8/:folderName9/:folderName10/:folderName11/:folderName12",
      name: "Dashboard",
      langName: "documentDashboard",
      component: DocumentFoldersFiles,
    },
  ],
};

export const dashboard = [
  dashboardRoutes,
  documentsRoutes,
  chatRoutes,
  settingsRoutes,
  privateRoutes,
];

// Landing specific routes
export const landing = [landingRoutes];

// Auth specific routes
export const page = [authRoutes];

// ROUTES TO SHOW IN NAVBAR
export const adminLinks = [
  // {
  //   path: "/",
  //   name: "Dashboard",
  //   langName: "dashboard",
  //   component: Dashboard,
  //   icon: SlidersIcon,
  //   containsHome: true,
  //   isPrivate: true,
  //   children: null,
  // },
  {
    isPrivate: true,
    icon: DocumentIcon,
    //header: "Documents",
    path: "/documents/dashboard",
    name: "Client Documents",
    langName: "documentDashboard",
    component: DocumentDashboard,
    children: null,
  },
  // {
  //   isPrivate: true,
  //   icon: DocumentIcon,
  //   path: "/documents/requests",
  //   name: "Document Requests",
  //   langName: "documentRequests",
  //   component: DocumentDashboard,
  //   children: null
  // },
  {
    path: "/my-documents",
    name: "My Documents",
    langName: "myDocuments",
    component: DocumentDashboard,
    isPrivate: true,
    icon: DocumentIcon,
    containsHome: true,
    children: null,
  },
  {
    path: "/my-team",
    name: "My Team",
    langName: "myTeam",
    component: Users,
    isPrivate: true,
    icon: DocumentIcon,
    containsHome: true,
    children: null,
  },
  {
    path: "/settings/customers",
    name: "Customers",
    langName: "organizations",
    component: Organizations,
    isPrivate: true,
    icon: DocumentIcon,
    containsHome: true,
    children: null,
  },

  // {
  //   path: "/signatures",
  //   header: "signatures",
  //   langName: "requestSignature",
  //   icon: SettingsIcon,
  //   children: null
  // },
  // {
  //   path: "/signatures",
  //   langName: "signatureDocuments",
  //   icon: SettingsIcon,
  //   children: null
  // },

  {
    path: "/settings",
    name: "Settings",
    langName: "settings",
    //header: "Settings",
    icon: SettingsIcon,
    children: [
      {
        isPrivate: true,
        path: "/settings/email-templates",
        name: "Email Templates",
        langName: "emailTemplates",
        component: EmailTemplates,
      },
      {
        isPrivate: true,
        path: "/settings/content",
        name: "Content",
        langName: "content",
        component: Content,
      },
      {
        isPrivate: true,
        path: "/settings/userRoles",
        name: "User Roles",
        langName: "userRoles",
        component: UserRoles,
      },
      {
        isPrivate: true,
        path: "/settings/config/1",
        name: "Config",
        langName: "config",
        component: Config,
      },
    ],
  },
];
