import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { withRouter } from "../../client/utils/withRouter";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/free-solid-svg-icons";

import { adminLinks } from "../../client/routes/index";
import { languageText } from "../../config";
import logo from "../../assets/img/logo/logo-lng.png";
import SidebarCategory from "./sidebar/SidebarCategory";
import SidebarItem from "./sidebar/SidebarItem";
import SideBarLink from "./sidebar/SideBarLink";
import Loader from "./Loader";

const Sidebar = (props) => {
  const { sidebar, userPermissions, layout } = props;

  const [toggleSettings, setToggle] = useState([]);
  const [userInfo, setUserInfo] = useState([]);
  const [loading, setLoading] = useState(true);
  const [reload, setReload] = useState(false);

  const toggle = (index) => {
    // Collapse all elements
    Object.keys(toggleSettings).forEach((item) => {
      item = parseInt(item);
      if (index !== item) {
        toggleSettings[item] = false;
      }
    });
    toggleSettings[index] = !toggleSettings[index];
    setToggle(toggleSettings);
    setReload(!reload);
  };

  useEffect(() => {
    if (typeof props.auth !== "undefined" && props.auth !== null) {
      if (typeof props.auth.user !== "undefined" && props.auth.user !== null) {
        setUserInfo(props.auth.user.user);
        setLoading(false);
      }
    }
  }, [props]);

  useEffect(() => {}, [toggleSettings]);

  if (loading) {
    return <Loader />;
  }

  return (
    <nav
      className={
        "sidebar" +
        (!sidebar.isOpen ? " toggled" : "") +
        (sidebar.isSticky ? " sidebar-sticky" : "")
      }
    >
      <div className="sidebar-content">
        <div className="w-100 text-center">
          <Link className="sidebar-brand" to="/">
            <img
              src={logo}
              alt="Simply Collaborate"
              className="img-fluid"
              width="250"
              height="100"
            />
          </Link>
          <small className="text-white">{languageText["en"].AppTitle}</small>
          <hr />
        </div>
        <ul className="sidebar-nav">
          {adminLinks.map((category, index) => {
            let permissionTable = category.langName;
            let permissionFound =
              userPermissions?.userRole.tablesCanAccess?.search(
                "," + permissionTable + ",",
              ) ?? null;
            let subLinkAccess = false;
            if (category.children !== null) {
              category.children.map((route) => {
                let stringFound;
                stringFound = userPermissions?.userRole.tablesCanAccess?.search(
                  "," + route.langName + ",",
                );
                if (stringFound !== -1) {
                  subLinkAccess = true;
                }
                return null;
              });
            }
            return (
              <Fragment key={index}>
                {category.header ? (
                  <li className="sidebar-header">{category.header}</li>
                ) : null}
                {category.children && subLinkAccess !== false && (
                  <SidebarCategory
                    name={languageText["en"].sideBar[category.langName]}
                    badgeColor={category.badgeColor}
                    badgeText={category.badgeText}
                    icon={category.icon}
                    to={category.path}
                    isOpen={toggleSettings[index]}
                    onClick={() => toggle(index)}
                  >
                    {category.children.map((route, index) => {
                      let stringFound =
                        userPermissions?.userRole.tablesCanAccess?.search(
                          "," + route.langName + ",",
                        );
                      if (stringFound !== -1) {
                        return (
                          <SidebarItem
                            key={index}
                            name={languageText["en"].sideBar[route.langName]}
                            to={route.path}
                            badgeColor={route.badgeColor}
                            badgeText={route.badgeText}
                          />
                        );
                      }
                      return null;
                    })}
                  </SidebarCategory>
                )}
                {(typeof category.children === "undefined" ||
                  category.children === null) && (
                  <SideBarLink
                    permissionTable={permissionTable}
                    permissionFound={permissionFound}
                    userPermissions={userPermissions}
                    name={languageText["en"].sideBar[category.langName]}
                    to={category.path}
                    icon={category.icon}
                    badgeColor={category.badgeColor}
                    badgeText={category.badgeText}
                  />
                )}
              </Fragment>
            );
          })}
        </ul>

        {!layout.isBoxed && !sidebar.isSticky ? (
          <div className="sidebar-bottom d-none d-lg-block">
            <div className="d-flex">
              <div className="flex-shrink-0">
                {userInfo.image && (
                  <img
                    src={userInfo.image}
                    className="rounded-circle me-3"
                    alt={`${userInfo.firstName} ${userInfo.lastName}`}
                    width="40"
                    height="40"
                  />
                )}
              </div>
              <div className="flex-grow-1 ms-3">
                <h5 className="mb-1">
                  {userInfo.firstName} {userInfo.lastName}
                </h5>
                <div>
                  <FontAwesomeIcon icon={faCircle} className="text-success" />{" "}
                  {languageText["en"].sideBar.online}
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </nav>
  );
};

export default withRouter(
  connect((store) => ({
    sidebar: store.sidebar,
    layout: store.layout,
    auth: store.auth,
    userPermissions: store.auth.user,
  }))(Sidebar),
);
