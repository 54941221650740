import React from "react";
import classnames from "classnames";
import InputError from "./InputError";
import TimePicker from "react-time-picker";
import "react-time-picker/dist/TimePicker.css";
import "react-clock/dist/Clock.css";
import InputHelpText from "./InputHelpText";

const FormInputTime = (props) => {
  const onChangeTime = (time) => {
    props.onChange(time);
  };

  return (
    <div className={`col-12 align-bottom col-sm-${props.divColSize}`}>
      <div className="container p-0">
        <div className="form-group row border-bottom pb-2">
          <label
            htmlFor={props.name}
            className={classnames("col-sm-12 m-0 pt-2 pb-2", {
              "is-invalid": props.error,
            })}
          >
            {props.label}
            {(props?.required || props?.field?.required) && (
              <small className="text-danger">&nbsp; *</small>
            )}
          </label>
          <div className="col-sm-12">
            <TimePicker
              value={props.value}
              onChange={onChangeTime}
              format={"hh:mm a"}
              className={classnames(props.inputSizeClass, {
                "is-invalid": props.error,
              })}
            />
          </div>

          <InputError error={props.error} />
          <InputHelpText helpText={props.helpText} />
        </div>
      </div>
    </div>
  );
};

export default FormInputTime;
