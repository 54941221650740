import React, { useEffect, useState } from "react";
import { Container } from "reactstrap";
import { useNavigate, useParams } from "react-router-dom";
import DataList from "./list/Overview";
import DataEdit from "./item/EditForm";
import {
  archiveRecord,
  deleteRecord,
  exportOverview,
  inviteUser,
  resendInvite,
  saveRecord,
  testEmail,
} from "../../client/actions/apiActions";
import { connect } from "react-redux";
import Loader from "../layouts/Loader";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Modal from "./list/Modal";
import FormInput from "../../components/form/FormInput";
import { ApiURL } from "../../config";
import { tokenConfig } from "../../client/actions/auth";
import axios from "axios";

const Wrapper = (props) => {
  const navigate = useNavigate();
  const params = useParams();
  const [pageMode, setPageMode] = useState("loading");
  const [savePageMode, setSavePageMode] = useState(props.savePageMode ?? "");
  const [pageTitle] = useState("");
  const [singleEdit] = useState(props.singleEdit ?? false);
  const [addTitle] = useState(props.addTitle ?? "");
  const [listTitle] = useState(props.listTitle ?? "");
  const [listSubTitle] = useState(props.listSubTitle ?? "");
  const [tableColumns] = useState(props.tableColumns ?? []);
  const [fieldData] = useState(props.fieldData ?? []);
  const [fieldDataErrors, setFieldDataErrors] = useState([]);
  const [tableName] = useState(props.tableName);
  const [totalPages, setTotalPages] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [searchText, setSearchText] = useState(props.searchText ?? "");
  const [searchColumns, setSearchColumns] = useState(props.searchColumns ?? []);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(
    props.recordsPerPage ?? 25,
  );
  const [sortDirection, setSortDirection] = useState(
    props.sortDirection ?? "ASC",
  );
  const [sortFieldName, setSortFieldName] = useState(props.sortFieldName ?? "");
  const [settings, setSettings] = useState(props.settings ?? {});
  const [organizationId, setOrganizationId] = useState(
    props.organizationId ?? "",
  );
  const [showFilters, setShowFilters] = useState(false);
  const [filterText, setFilterText] = useState("");
  const [filters, setFilters] = useState([]);
  const [id, setId] = useState(null);
  const [fieldDataResults, setFieldDataResults] = useState(
    props.fieldDataResults ?? {},
  );
  const [tableData, setTableData] = useState([]);

  // MODAL
  const [showModal, setShowModal] = useState(false);
  const [modalType, setModalType] = useState("");
  const [modalTitle, setModalTitle] = useState("");
  const [modalError, setModalError] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [inviteEmailAddress, setInviteEmailAddress] = useState("");
  const [userRole, setUserRole] = useState("");
  const [pageData, setPageData] = useState([]);

  useEffect(() => {
    if (
      props.pageMode === "list" &&
      pageMode !== "list" &&
      pageMode !== "loading"
    ) {
      setPageMode("list");
      refreshFunction();
    } else {
      if (
        props.data.dataType === "export" &&
        props.data.status === 1 &&
        props.data.code !== "" &&
        typeof props.data.code !== "undefined"
      ) {
        window.open(ApiURL + "/export/" + props.data.code);
      } else if (props.data.dataType === "deleteRecord") {
        refreshFunction();
      } else if (props.data.dataType === "saveRecord") {
        if (props.data.status === 1) {
          toast.success("Record was successfully saved.", {
            theme: "colored",
          });
        } else {
          toast.error("Error Saving : " + props.data.message, {
            theme: "colored",
          });
          setPageMode("edit");
        }
        if (!singleEdit && savePageMode === "close") {
          refreshFunction();
          navigate(props.parentRoute);
        } else {
          getRecord(tableName, id);
        }
      } else if (props.data.dataType === "archiveRecord") {
        refreshFunction();
      }
    }
    if (props.data.dataType === "sendUserInvite") {
      if (props.data.status === true) {
        onCloseModal();
        refreshFunction();
        toast.success("Invite was sent successfully!", {
          theme: "colored",
        });
      }
      if (props.data.status === 0) {
        setModalError(props.data.message);
      }
    }
    if (props.data.dataType === "resendInvite") {
      if (props.data.status === true) {
        toast.success("Invite was sent successfully!", {
          theme: "colored",
        });
      } else {
        toast.warn("Error sending invite.", {
          theme: "colored",
        });
      }
    }
  }, [props]);

  const refreshFunction = (
    clickedCurrentPage = 0,
    clickedRecordsPerPage = "",
    clickedSortFieldName = "",
    clickedSortDirection = "",
    clickedSearchText = "",
    clickedSearchColumns = "",
    clickedFilters = "",
  ) => {
    loadData(
      clickedCurrentPage,
      clickedRecordsPerPage,
      clickedSortFieldName,
      clickedSortDirection,
      clickedSearchText,
      clickedSearchColumns,
      clickedFilters,
    );
  };

  const loadData = (
    clickedCurrentPage = 0,
    clickedRecordsPerPage = "",
    clickedSortFieldName = "",
    clickedSortDirection = "",
    clickedSearchText = "",
    clickedSearchColumns = "",
    clickedFilters = "",
  ) => {
    getTable(
      tableName,
      clickedCurrentPage ? clickedCurrentPage : currentPage,
      clickedRecordsPerPage ? clickedRecordsPerPage : recordsPerPage,
      clickedSortFieldName ? clickedSortFieldName : sortFieldName,
      clickedSortDirection ? clickedSortDirection : sortDirection,
      clickedSearchText ? clickedSearchText : searchText,
      organizationId,
      clickedSearchColumns ? clickedSearchColumns : searchColumns,
      clickedFilters ? clickedFilters : filters,
    );
  };

  const getTable = async (
    tableName,
    currentPage,
    recordsPerPage,
    sortFieldName,
    sortDirection,
    searchText,
    organizationId,
    clickedSearchColumns,
    clickedFilters,
  ) => {
    setPageMode("loading");
    setPageData([]);
    let res = await axios.post(
      `${ApiURL}/getTable`,
      {
        tableName,
        currentPage,
        recordsPerPage,
        sortFieldName,
        sortDirection,
        searchText,
        organizationId,
        searchColumns: clickedSearchColumns,
        clickedFilters,
      },
      tokenConfig(),
    );

    setTotalPages(res.data.totalPages);
    setTotalRecords(res.data.totalRecords);
    setPageData(res.data.dataValues);
    setPageMode("list");
  };

  const getRecord = async (tableName, tableId) => {
    setPageMode("loading");
    let res = await axios.post(
      `${ApiURL}/getRecord`,
      { tableName, tableId: tableId === "add" ? 0 : tableId },
      tokenConfig(),
    );

    setPageData(res.data.dataValues);
    if (tableId === "add") {
      setPageMode("add");
    } else {
      setPageMode("edit");
    }
  };

  const addFunction = () => {
    getRecord(tableName, "add");
  };

  const editFunction = (clickedId) => {
    getRecord(tableName, clickedId);
  };

  const deleteFunction = async (id) => {
    setPageMode("loading");
    let res = await axios.post(
      `${ApiURL}/deleteRecord`,
      { tableName, tableId: id },
      tokenConfig(),
    );
    if (res.data.success) {
      setPageMode("list");
      setPageData([]);
      refreshFunction();
    }
  };

  const archiveRecord = async (id) => {
    setPageMode("loading");
    await axios.post(
      `${ApiURL}/archiveRecord`,
      { tableName, id },
      tokenConfig(),
    );
    refreshFunction();
  };

  const updatePage = (clickedCurrentPage) => {
    setCurrentPage(clickedCurrentPage);
    refreshFunction(clickedCurrentPage, recordsPerPage);
  };

  const deleteConfirmFunction = (id) => {
    confirmAlert({
      title: "Confirm Deletion",
      message: "Are you sure you wish to delete?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            deleteFunction(id);
            setPageMode("loading");
          },
        },
        {
          label: "No",
        },
      ],
    });
  };

  const testEmail = () => {
    props.testEmail();
  };

  const saveFunction = async (saveId, saveMethod, data) => {
    let prevPageMode = pageMode;
    setPageMode("loading");

    let errors = 0;

    let fieldDataErrors = [];
    if (organizationId && tableName === "users") {
      data["organizationId"] = organizationId;
    }
    fieldData.map((field) => {
      if (
        field.required === true &&
        (data[field.fieldName] === "" ||
          typeof data[field.fieldName] === "undefined")
      ) {
        errors = 1;
        let errorMessage = field.requiredMessage;
        fieldDataErrors[field.fieldName] = errorMessage;
        toast.error(errorMessage, {
          theme: "colored",
        });
      }
      return null;
    });

    if (errors === 1) {
      setPageMode(prevPageMode);
      setFieldDataErrors(fieldDataErrors);
    } else {
      if (saveMethod === "clone") {
        saveId = 0;
        delete data.id;
      }
      let res = await axios.post(
        `${ApiURL}/saveRecord`,
        { tableName, tableId: saveId, data },
        tokenConfig(),
      );
      console.log("saveMethod", saveMethod);

      if (res.data.status === 1) {
        if (!singleEdit && saveMethod === "save and close") {
          navigate(props.parentRoute);
        } else if (!singleEdit && saveMethod === "clone") {
          navigate(props.parentRoute);
        } else if (!singleEdit && saveMethod === "save") {
          if (saveId !== 0 && saveId !== "0") {
            editFunction(res.data.recordId);
          } else {
            refreshFunction();
          }
        } else if (singleEdit) {
          editFunction(res.data.recordId);
        }

        setFieldDataErrors([]);
      } else {
        setPageMode(prevPageMode);
        // setFieldDataErrors(res.data.errors);
      }
    }
  };

  const exportFunction = () => {
    props.exportOverview(tableName);
  };

  const resetSearch = () => {
    setSearchText("");
    refreshFunction(
      currentPage,
      recordsPerPage,
      sortFieldName,
      sortDirection,
      searchText,
    );
  };

  const searchOverview = () => {
    refreshFunction();
  };

  const searchKeyPress = (e) => {
    if (e.key === "Enter") {
      searchOverview();
    }
  };

  const closeFunction = () => {
    setFieldDataErrors([]);
    refreshFunction();
  };

  const onViewsUsers = (organizationId) => {
    navigate("/organizations/users/" + organizationId);
  };

  const changeSort = (clickedSortFieldName, clickedSortDirection) => {
    if (clickedSortDirection === "DESC") {
      clickedSortDirection = "ASC";
    } else {
      clickedSortDirection = "DESC";
    }

    setSortFieldName(clickedSortFieldName);
    setSortDirection(clickedSortDirection);

    refreshFunction(
      currentPage,
      recordsPerPage,
      clickedSortFieldName,
      clickedSortDirection,
    );
  };

  const toggleFilters = () => {
    setShowFilters(!showFilters);
  };

  const updateFilter = (newFilter) => {
    setFilters(newFilter);
    refreshFunction(
      currentPage,
      recordsPerPage,
      sortFieldName,
      sortDirection,
      searchText,
      searchColumns,
      newFilter,
    );
  };

  const changeRecordsPerPage = (e) => {
    setCurrentPage(1);
    // this.setState({ [e.target.name]: e.target.value, currentPage: 1 });
    refreshFunction(1, e.target.value);
  };
  const onResendInvite = (userId) => {
    props.resendInvite(userId);
  };

  const onSaveModal = () => {
    if (modalType === "inviteUser") {
      if (
        validateEmailAddress(inviteEmailAddress) &&
        firstName &&
        lastName &&
        userRole
      ) {
        props.inviteUser(
          organizationId,
          inviteEmailAddress,
          userRole,
          firstName,
          lastName,
        );
      } else {
        setModalError("All fields are required, and a valid email address");
      }
    }
  };

  const onCloseModal = () => {
    setShowModal(false);
    setModalType("");
    setModalTitle("");
    setInviteEmailAddress("");
    setFirstName("");
    setLastName("");
    setModalError("");
    setUserRole("");
  };

  const validateEmailAddress = (emailAddress) => {
    let validRegex =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (emailAddress.match(validRegex)) {
      return true;
    } else {
      return false;
    }
  };

  const inviteFunction = () => {
    setShowModal(true);
    setModalType("inviteUser");
    setModalTitle("Invite User to Simply Collaborate");
  };

  useEffect(() => {
    let tmpId = null;
    if (props.id && !params.id) {
      tmpId = props.id;
      setId(props.id);
    } else if (params.id) {
      tmpId = params.id;
      setId(tmpId);
    }

    if (tmpId !== "add" && tmpId !== null) {
      editFunction(tmpId);
      return null;
    }

    if (tmpId === "add") {
      addFunction("add");
      return null;
    } else if (!singleEdit) {
      refreshFunction();
      return null;
    }
    editFunction(tmpId);
  }, [params]);

  return (
    <Container fluid className="p-0">
      {pageTitle && <h1 className="h3 mb-3">{pageTitle}</h1>}
      {pageMode === "loading" && <Loader />}
      {pageMode === "list" && (
        <>
          {pageData ? (
            <DataList
              auth={props.auth}
              userPermissions={props.userPermissions}
              route={props.route}
              parentRoute={props.parentRoute}
              showOrganizationsLink={props.showOrganizationsLink}
              title={listTitle}
              addTitle={addTitle}
              slogan={listSubTitle}
              columns={tableColumns}
              tableData={pageData}
              tableName={tableName}
              sortFieldName={sortFieldName}
              sortDirection={sortDirection}
              searchText={searchText}
              settings={settings}
              currentPage={currentPage}
              totalPages={totalPages}
              totalRecords={totalRecords}
              recordsPerPage={recordsPerPage}
              organizationId={organizationId}
              showFilters={showFilters}
              filters={filters}
              lists={props.lists}
              hideInvite={props.hideInvite}
              filterText={filterText}
              updateFilter={updateFilter}
              resetSearch={resetSearch}
              onResendInvite={onResendInvite}
              onViewsUsers={onViewsUsers}
              searchOverview={searchOverview}
              searchKeyPress={searchKeyPress}
              onUpdatePage={updatePage}
              onAdd={addFunction}
              onInvite={inviteFunction}
              onExport={exportFunction}
              toggleFilters={toggleFilters}
              onChangeRecordsPerPage={changeRecordsPerPage}
              changeSort={changeSort}
              onEdit={editFunction}
              onDelete={deleteConfirmFunction}
              onArchive={archiveRecord}
            />
          ) : (
            <Loader />
          )}
        </>
      )}
      {(pageMode === "add" || pageMode === "view" || pageMode === "edit") && (
        <DataEdit
          route={props.route}
          parentRoute={props.parentRoute}
          mode={pageMode}
          singleEdit={singleEdit}
          title={props[pageMode + "Title"]}
          slogan={props[pageMode + "SubTitle"]}
          tableName={tableName}
          organizationId={organizationId}
          settings={settings}
          dataFields={fieldData}
          dataResults={pageData ?? []}
          dataErrors={fieldDataErrors}
          onArchive={archiveRecord}
          onSave={saveFunction}
          onCancel={closeFunction}
          testEmail={testEmail}
        />
      )}
      <ToastContainer />

      {showModal && (
        <Modal
          handleClose={onCloseModal}
          show={showModal}
          title={modalTitle}
          onSave={onSaveModal}
          modalType={modalType}
        >
          {modalType === "inviteUser" && (
            <div className="container">
              {modalError && (
                <div className="row">
                  <div className="col-12">
                    <div className="alert alert-danger text-dark py-1 px-4">
                      {modalError}
                    </div>
                  </div>
                </div>
              )}

              <FormInput
                colSize={1}
                label="First Name"
                placeholder="First Name"
                type="text"
                name="firstName"
                required={true}
                value={firstName}
                onChange={(value) => setFirstName(value)}
              />
              <FormInput
                colSize={1}
                label="Last Name"
                placeholder="Last Name"
                type="text"
                name="lastName"
                required={true}
                value={lastName}
                onChange={(value) => setLastName(value)}
              />
              <FormInput
                colSize={1}
                label="Email Address"
                placeholder="Email Address"
                type="text"
                name="inviteEmailAddress"
                required={true}
                value={inviteEmailAddress}
                onChange={(value) => setInviteEmailAddress(value)}
              />
              {organizationId === props.auth.user.organizationId && (
                <FormInput
                  colSize={1}
                  label="User Role"
                  type="dropdown"
                  required={true}
                  options={[
                    {
                      id: 1,
                      name: "(App Owner) App Administrator",
                    },
                    {
                      id: 3,
                      name: "(App Owner) Account Manager",
                    },
                  ]}
                  name="userRole"
                  value={userRole}
                  onChange={(value) => setUserRole(value)}
                />
              )}
              {organizationId !== props.auth.user.organizationId && (
                <FormInput
                  colSize={1}
                  label="User Role"
                  type="dropdown"
                  required={true}
                  options={[
                    {
                      id: 2,
                      name: "(Client) Organization Administrator",
                    },
                    {
                      id: 4,
                      name: "(Client) Team Member",
                    },
                  ]}
                  name="userRole"
                  value={userRole}
                  onChange={(value) => setUserRole(value)}
                />
              )}
            </div>
          )}
        </Modal>
      )}
    </Container>
  );
};
const mapStateToProps = (state) => ({
  userPermissions: state.auth.user.userRole,
  auth: state.auth.user,
  data: state.pages.data,
});

export default connect(mapStateToProps, {
  saveRecord,
  archiveRecord,
  testEmail,
  inviteUser,
  deleteRecord,
  resendInvite,
  exportOverview,
})(Wrapper);
