import React, { useEffect, useState } from "react";
import { languageText } from "../config";
import DataOverview from "../components/data/Wrapper";
import Loader from "../components/layouts/Loader";
import { useLocation, useMatch, useParams } from "react-router-dom";

const tableName = "userRoles";
const editPathName = "/settings/userRoles/:id";
const tableColumns = [
  {
    dataField: "editable",
    type: "bool",
    sort: false,
  },
  {
    dataField: "roleName",
    type: "text",
    sort: true,
  },
  {
    dataField: "roleType",
    sort: true,
  },
  {
    dataField: "tools",
    sort: false,
  },
];
const fieldData = [
  {
    displayName: "Editable",
    fieldName: "editable",
    fieldType: "toggle",
    colSize: 3,
    required: true,
  },
  {
    displayName: "Role Name",
    fieldName: "roleName",
    fieldType: "text",
    colSize: 3,
    required: true,
  },
  {
    displayName: "Role Type",
    fieldName: "roleType",
    fieldType: "radio",
    colSize: 3,
    required: true,
    valueOptions: [
      {
        id: 1,
        value: 1,
        name: "Owner",
      },
      {
        id: 2,
        value: 2,
        name: "Customer",
      },
    ],
  },

  {
    displayName: "Customers / Organizations",
    fieldName: "organizationsTitle",
    fieldType: "title",
    colSize: 1,
  },
  {
    displayName:
      "Permissions that User Role can access for Customers / Organizations",
    fieldName: "organizationsTitleText",
    fieldType: "display",
    colSize: 1,
  },
  {
    displayName: "Can View",
    fieldName: "organizationsCanView",
    fieldType: "toggle",
    colSize: 5,
    required: true,
  },
  {
    displayName: "Can Add",
    fieldName: "organizationsCanAdd",
    fieldType: "toggle",
    colSize: 5,
    required: true,
  },
  {
    displayName: "Can Edit",
    fieldName: "organizationsCanEdit",
    fieldType: "toggle",
    colSize: 5,
    required: true,
  },
  {
    displayName: "Can Delete",
    fieldName: "organizationsCanDelete",
    fieldType: "toggle",
    colSize: 5,
    required: true,
  },
  {
    displayName: "Can Export",
    fieldName: "organizationsCanExport",
    fieldType: "toggle",
    colSize: 5,
    required: true,
  },

  {
    displayName: "Users",
    fieldName: "usersTitle",
    fieldType: "title",
    colSize: 1,
  },
  {
    displayName:
      "Permissions that User Role can access for Users / Team Members",
    fieldName: "usersTitleText",
    fieldType: "display",
    colSize: 1,
  },
  {
    displayName: "Can View",
    fieldName: "usersCanView",
    fieldType: "toggle",
    colSize: 5,
    required: true,
  },
  {
    displayName: "Can Add",
    fieldName: "usersCanAdd",
    fieldType: "toggle",
    colSize: 5,
    required: true,
  },
  {
    displayName: "Can Edit",
    fieldName: "usersCanEdit",
    fieldType: "toggle",
    colSize: 5,
    required: true,
  },
  {
    displayName: "Can Delete",
    fieldName: "usersCanDelete",
    fieldType: "toggle",
    colSize: 5,
    required: true,
  },
  {
    displayName: "Can Export",
    fieldName: "usersCanExport",
    fieldType: "toggle",
    colSize: 5,
    required: true,
  },

  {
    displayName: "Folder Permissions",
    fieldName: "foldersTitle",
    fieldType: "title",
    colSize: 1,
  },
  {
    displayName:
      "Permissions that control what this user role can do with a folder",
    fieldName: "foldersTitleText",
    fieldType: "display",
    colSize: 1,
  },
  {
    displayName: "Can Add Folder",
    fieldName: "foldersAdd",
    fieldType: "toggle",
    colSize: 5,
    required: true,
  },
  {
    displayName: "Can Edit Folder Name",
    fieldName: "foldersEdit",
    fieldType: "toggle",
    colSize: 5,
    required: true,
  },
  {
    displayName: "Can Delete Folder",
    fieldName: "foldersDelete",
    fieldType: "toggle",
    colSize: 5,
    required: true,
  },

  {
    displayName: "File Permissions",
    fieldName: "filesTitle",
    fieldType: "title",
    colSize: 1,
  },
  {
    displayName:
      "Permissions that control what this user role can do with a file",
    fieldName: "filesTitleText",
    fieldType: "display",
    colSize: 1,
  },
  {
    displayName: "Can Upload Files",
    fieldName: "filesCanUpload",
    fieldType: "toggle",
    colSize: 5,
    required: true,
  },
  {
    displayName: "Can Download Files",
    fieldName: "filesCanDownload",
    fieldType: "toggle",
    colSize: 5,
    required: true,
  },
  {
    displayName: "Can Delete Files",
    fieldName: "filesCanDelete",
    fieldType: "toggle",
    colSize: 5,
    required: true,
  },
  {
    displayName: "Can Move Files",
    fieldName: "filesCanMove",
    fieldType: "toggle",
    colSize: 5,
    required: true,
  },
  {
    displayName: "Can Edit File Names",
    fieldName: "filesCanEdit",
    fieldType: "toggle",
    colSize: 5,
    required: true,
  },

  {
    displayName: "Tables",
    fieldName: "tablesTitle",
    fieldType: "title",
    colSize: 1,
  },
  {
    displayName: "Permissions that User Role can access for Tables",
    fieldName: "tablesTitleText",
    fieldType: "display",
    colSize: 1,
  },
  {
    displayName: "Can View",
    fieldName: "tablesCanView",
    fieldType: "toggle",
    colSize: 5,
    required: true,
  },
  {
    displayName: "Can Add",
    fieldName: "tablesCanAdd",
    fieldType: "toggle",
    colSize: 5,
    required: true,
  },
  {
    displayName: "Can Edit",
    fieldName: "tablesCanEdit",
    fieldType: "toggle",
    colSize: 5,
    required: true,
  },
  {
    displayName: "Can Archive",
    fieldName: "tablesCanArchive",
    fieldType: "toggle",
    colSize: 5,
    required: true,
  },
  {
    displayName: "Can Delete",
    fieldName: "tablesCanDelete",
    fieldType: "toggle",
    colSize: 5,
    required: true,
  },
  {
    displayName: "Can Export",
    fieldName: "tablesCanExport",
    fieldType: "toggle",
    colSize: 5,
    required: true,
  },

  {
    displayName: "Can Access",
    fieldName: "tablesCanAccess",
    fieldType: "checkbox",
    colSize: 1,
    options: [
      {
        name: "Dashboard",
        value: "dashboard",
        id: "dashboard",
      },

      {
        name: "Chat Screen",
        value: "chat",
        id: "chat",
      },
      {
        name: "Organizations",
        value: "organizations",
        id: "organizations",
      },
      {
        name: "Team Members",
        value: "users",
        id: "users",
      },
      {
        name: "My Team",
        value: "myTeam",
        id: "myTeam",
      },

      {
        name: "Documents",
        value: "documentDashboard",
        id: "documentDashboard",
      },
      {
        name: "Document Requests",
        value: "documentRequests",
        id: "documentRequests",
      },

      {
        name: "My Documents",
        value: "myDocuments",
        id: "myDocuments",
      },

      {
        name: "Content",
        value: "content",
        id: "content",
      },
      {
        name: "Email Templates",
        value: "emailTemplates",
        id: "emailTemplates",
      },
      {
        name: "Languages",
        value: "languages",
        id: "languages",
      },

      {
        name: "User Roles",
        value: "userRoles",
        id: "userRoles",
      },

      {
        name: "Request A Signature",
        value: "requestSignature",
        id: "requestSignature",
      },

      {
        name: "Signature Documents",
        value: "signatureDocuments",
        id: "signatureDocuments",
      },
      {
        name: "Settings",
        value: "settings",
        id: "settings",
      },
      {
        name: "Config",
        value: "config",
        id: "config",
      },
    ],
    required: true,
  },
];
const fieldDataDefault = {};

const UserRoles = () => {
  const { id } = useParams();
  const { pathname } = useLocation();
  const match = useMatch(editPathName);
  const [loading, setLoading] = useState(true);
  let parentRoute = pathname;
  useEffect(() => {
    setLoading(false);
  }, []);

  if (match) {
    parentRoute = match.pattern.path.replace("/:id", "");
  }

  if (loading) return <Loader />;
  return (
    <DataOverview
      id={id ?? ""}
      tableName={tableName}
      pageMode={id ? "edit" : "list"}
      route={pathname}
      parentRoute={parentRoute}
      pageTitle={languageText["en"].components[tableName].pageTitle}
      listTitle={languageText["en"].components[tableName].listTitle}
      listSubTitle={languageText["en"].components[tableName].listSubTitle}
      addTitle={languageText["en"].components[tableName].addTitle}
      addSubTitle={languageText["en"].components[tableName].addSubTitle}
      editTitle={languageText["en"].components[tableName].editTitle}
      editSubTitle={languageText["en"].components[tableName].editSubTitle}
      tableColumns={tableColumns}
      fieldData={fieldData}
      fieldDataDefault={fieldDataDefault}
    />
  );
};

export default UserRoles;
