import React from "react";
import classnames from "classnames";
import InputError from "./InputError";
import InputHelpText from "./InputHelpText";

const FormInputTextArea = (props) => {
  return (
    <div className={`col-12 align-bottom col-sm-${props.divColSize}`}>
      <div className="container p-0">
        <div className="form-group row border-bottom pb-2">
          <label
            htmlFor={props.name}
            className={classnames("col-sm-12 m-0 pt-2 pb-2", {
              "is-invalid": props.error,
            })}
          >
            {props.label}
            {(props?.required || props?.field?.required) && (
              <small className="text-danger">&nbsp; *</small>
            )}
          </label>

          <div className="col-sm-12">
            <textarea
              ref={props.ref}
              name={props.name}
              id={props.name}
              rows="5"
              className={classnames(props.inputSizeClass, {
                "is-invalid": props.error,
              })}
              onChange={(e) => props.onChange(e.target.value)}
              aria-describedby={props.name + "Help"}
              placeholder={props.placeholder}
              value={props.value}
            />
          </div>
          <InputError error={props.error} />
          <InputHelpText helpText={props.helpText} />
        </div>
      </div>
    </div>
  );
};

export default FormInputTextArea;
