// Import the functions you need from the SDKs you need
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { onBackgroundMessage } from "firebase/messaging/sw";
import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyCBLSjM4l00z3VQRYIraSfQGcwTSeehXaY",
  authDomain: "simplycollaborate-com.firebaseapp.com",
  projectId: "simplycollaborate-com",
  storageBucket: "simplycollaborate-com.appspot.com",
  messagingSenderId: "1020484521405",
  appId: "1:1020484521405:web:cc67b318de23d40cb116d4",
  measurementId: "G-KFQZ8RGV77",
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);
export const getMessagingToken = (setToken) => {
  return getToken(messaging, {
    vapidKey:
      "BJDErY6MqtQ9HaeiF7vBUaBvu4U_6djIEjCh_tnL261_Gv-xvr8dYeU9wCO5qXS66dTBm-iuMNiM-zXemTcYwd0",
  })
    .then((currentToken) => {
      if (currentToken) {
        setToken(currentToken);
      } else {
        console.log(
          "No registration token available. Request permission to generate one."
        );
        setToken(null);
        // shows on the UI that permission is required
      }
    })
    .catch((err) => {
      console.log("An error occurred while retrieving token. ", err);
      // catch error while creating client token
    });
};
export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });
