import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import {
  archiveFile,
  deleteFile,
  favoriteFile,
  moveFile,
  unFavoriteFile,
} from "../../client/actions/apiActions";

import { Button } from "reactstrap";
import { MoreHorizontal as MoreIcon } from "react-feather";
import { ControlledMenu, MenuItem } from "@szhsin/react-menu";
import { confirmAlert } from "react-confirm-alert";
import store from "../../client/store";
import { ApiURL } from "../../config";
import FolderModal from "./FolderModal";
import { toast } from "react-toastify";

const DocumentFolders = (props) => {
  const [selectedMenu, setSelectedMenu] = useState([]);
  const [reload, setReload] = useState(false);
  const [folders, setFolders] = useState(props.folders ?? []);
  const [clientId, setClientId] = useState(props.clientId);
  const [editFolderId, setEditFolderId] = useState("");

  const [originalFolderName, setOriginalFolderName] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [modalType, setModalType] = useState("");

  const toggleMenu = (menuId) => {
    if (typeof selectedMenu[menuId] === "undefined") {
      selectedMenu[menuId] = true;
    } else {
      selectedMenu[menuId] = selectedMenu[menuId] !== true;
    }
    setReload(!reload);
    setSelectedMenu(selectedMenu);
  };

  const editFolder = (folderInfo) => {
    setOriginalFolderName(folderInfo.name);
    setEditFolderId(folderInfo.id);
    setShowModal(true);
    setModalType("editFolder");
  };

  const closeFolderModal = () => {
    setShowModal(false);
    setModalType("");
  };

  const deleteFolder = (clickedFolderId) => {
    confirmAlert({
      title: "Confirm Delete",
      message:
        "Are you sure you want to delete this folder. You can not restore later on, and all files in this folder will be deleted.",
      buttons: [
        {
          label: "Yes, Delete",
          onClick: () => onDeleteFolder(clickedFolderId),
        },
        {
          label: "No, Do Not Delete",
        },
      ],
    });
  };

  const onDeleteFolder = (clickedFolderId) => {
    const token = store.getState().auth.token;
    const formData = new FormData();
    // this.setState({ uploadWarning: '', saveMessage: "", saveMessageType: ""});
    formData.append("clientId", clientId);
    formData.append("folderId", clickedFolderId);

    fetch(`${ApiURL}/documentCenter/deleteFolder`, {
      method: "POST",
      headers: { Authorization: token },
      body: formData,
    })
      .then((res) => res.json())
      .then((output) => {
        if (output.status === 1) {
          toast.success("Folder Deleted Successfully!");
          props.getFiles();
        } else {
          toast.error("Delete Folder Failed!");
          // this.setState({ saveMessage: "Delete Folder Failed!", saveMessageType: 0 });
        }
      });
  };

  const confirmSaveFolder = (newFolderName, folderType) => {
    setModalType("");
    if (modalType === "addFolder") {
      props.confirmSaveFolder(newFolderName, folderType);
    } else if (modalType === "editFolder") {
      props.confirmEditFolder(newFolderName, editFolderId);
    }
  };

  if (!props.folderTypes) {
    return null;
  }

  return (
    <>
      {folders.map((folder, index) => {
        return (
          <tr key={index} className="p-1 m-0">
            {props.hasFiles && <td className="text-center">&nbsp;</td>}
            <td>
              {folder.deleteable === "1" && (
                <>
                  <Button
                    className="btn btn-sm btn-secondary mx-1 my-0 p-1"
                    onClick={() => toggleMenu("folder" + folder.id)}
                  >
                    <MoreIcon size={15} />
                  </Button>
                  <ControlledMenu
                    style={{ marginLeft: "-50px", display: "block" }}
                    onClose={() => toggleMenu("folder" + folder.id)}
                    state={
                      selectedMenu["folder" + folder.id] ? "open" : "closed"
                    }
                  >
                    <MenuItem onClick={() => editFolder(folder)}>
                      Edit Folder
                    </MenuItem>
                    <MenuItem onClick={() => deleteFolder(folder.id)}>
                      Delete Folder
                    </MenuItem>
                  </ControlledMenu>
                </>
              )}
              {!folder.deleteable && <>&nbsp;</>}
            </td>
            <td valign="middle" className="align-middle text-center">
              {folder.folderType === 1 && (
                <i className="fas fa-globe-americas" />
              )}
              {folder.folderType === 2 && <i className="fas fa-user-friends" />}
              {folder.folderType === 3 && <i className="fas fa-user-lock" />}
            </td>
            <td>&nbsp;</td>
            <td colSpan="3" valign="middle" className="align-middle">
              <Link
                className="text-dark"
                to={`/documents/${clientId}/${folder.id}${folder.fullPath}`}
              >
                <i className="fas fa-folder" /> &nbsp; {folder.name}
              </Link>
            </td>
          </tr>
        );
      })}

      {showModal && (
        <FolderModal
          clientId={clientId}
          folderId={editFolderId}
          getFiles={props.getFiles}
          folderTypes={props.dataList.folderTypes}
          showModal={showModal}
          closeFolderModal={closeFolderModal}
          modalType={modalType}
          originalFolderName={originalFolderName}
          confirmSaveFolder={confirmSaveFolder}
          confirmEditFolder={confirmSaveFolder}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  documentList: state.pages.documentList,
  dataList: state.pages.dataList,
  data: state.pages.data,
});
export default connect(mapStateToProps, {
  deleteFile,
  moveFile,
  favoriteFile,
  unFavoriteFile,
  archiveFile,
})(DocumentFolders);
