import React, { useEffect, useState } from "react";
import DataOverview from "../components/data/Wrapper";
import { languages, languageText } from "../config";
import { useLocation, useMatch } from "react-router-dom";
import Loader from "../components/layouts/Loader";
import { getList } from "../client/actions/apiActions";
import { connect } from "react-redux";

const tableName = "config";
const editPathName = "/settings/config/:id";
const tableColumns = [
  {
    dataField: "id",
    sort: true,
  },
];
const defaultFieldData = [
  {
    fieldName: "titleSMTPSettings",
    fieldType: "title",
    colSize: 1,
    required: false,
    requiredMessage: "",
  },
  {
    fieldName: "smtpEmailHost",
    fieldType: "text",
    colSize: 3,
    required: false,
    requiredMessage: "",
  },
  {
    fieldName: "smtpFromEmail",
    fieldType: "text",
    colSize: 3,
    required: false,
    requiredMessage: "",
  },
  {
    fieldName: "smtpFromPassword",
    fieldType: "password",
    colSize: 3,
    required: false,
    requiredMessage: "",
  },
  {
    fieldName: "smtpFromName",
    fieldType: "text",
    colSize: 2,
    required: false,
    requiredMessage: "",
  },
  {
    fieldName: "smtpPort",
    fieldType: "text",
    colSize: 2,
    required: false,
    requiredMessage: "",
  },
  {
    fieldName: "testTemplate",
    fieldType: "dropdown",
    colSize: 2,
    required: false,
    requiredMessage: "Page Title",
  },
  {
    fieldName: "defaultLang",
    fieldType: "text",
    colSize: 2,
    required: true,
    requiredMessage: "Page URL",
  },
  {
    fieldName: "titleMessages",
    fieldType: "title",
    colSize: 1,
    required: false,
    requiredMessage: "",
  },
  {
    fieldName: "loginDisclaimerMessage",
    fieldType: "content",
    colSize: 1,
    required: true,
    requiredMessage: "Page Title",
  },
  {
    fieldName: "dashboardContent",
    fieldType: "content",
    colSize: 1,
    required: true,
    requiredMessage: "Language is required",
    dropdownData: languages,
  },
  {
    fieldName: "archivedBusinessMessage",
    fieldType: "content",
    colSize: 1,
    required: false,
    requiredMessage: "Page Title",
  },
];
const fieldDataDefault = {
  languageCode: "en",
  pageUrl: "",
  pageTitle: "",
  pageHTML: "",
};

const Config = (props) => {
  const location = useLocation();
  const match = useMatch(editPathName);
  const [loading, setLoading] = useState(true);
  const [fieldData, setFieldData] = useState(defaultFieldData);
  let parentRoute = location.pathname;

  useEffect(() => {
    props.getList("emailTemplates");
    setLoading(false);
  }, []);

  useEffect(() => {
    if (props.data.tableName === "emailTemplates") {
      let tmpData = fieldData;
      tmpData.map((data, index) => {
        if (data.fieldName === "testTemplate") {
          tmpData[index].dropdownData = props.data.emailTemplates;
        }
      });
      setFieldData(tmpData);
    }
  }, [props.data]);

  if (match) {
    parentRoute = match.pattern.path.replace("/:id", "");
  }

  if (loading) return <Loader />;
  return (
    <DataOverview
      id={1}
      tableName={tableName}
      pageMode={"edit"}
      singleEdit={true}
      settings={{ showClone: false }}
      route={location.pathname}
      parentRoute={parentRoute}
      pageTitle={languageText["en"].components[tableName].pageTitle}
      listTitle={languageText["en"].components[tableName].listTitle}
      listSubTitle={languageText["en"].components[tableName].listSubTitle}
      addTitle={languageText["en"].components[tableName].addTitle}
      addSubTitle={languageText["en"].components[tableName].addSubTitle}
      editTitle={languageText["en"].components[tableName].editTitle}
      editSubTitle={languageText["en"].components[tableName].editSubTitle}
      tableColumns={tableColumns}
      fieldData={fieldData}
      fieldDataDefault={fieldDataDefault}
    />
  );
};

const mapStateToProps = (state) => ({
  data: state.pages.data,
});

export default connect(mapStateToProps, { getList })(Config);
