import React, { useEffect, useState } from "react";
import { Button } from "reactstrap";
import { Link } from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import {
  getMyDocuments,
  getDocuments,
  deleteFile,
  archiveFile,
  moveFile,
  favoriteFile,
  unFavoriteFile,
  getDocumentClients,
  getClientFolders,
  getClientFiles,
  deleteFolder,
  updateDocumentClients,
  tokenConfig,
} from "../client/actions/apiActions";
import "react-confirm-alert/src/react-confirm-alert.css";
import Loading from "../components/layouts/Loader";
import { MenuItem, ControlledMenu } from "@szhsin/react-menu";
import "@szhsin/react-menu/dist/index.css";
import { MoreHorizontal as MoreIcon, Plus as AddIcon } from "react-feather";

import DashboardFavoriteFiles from "./dashboard/DashboardFavoriteFiles";
import DashboardUploads from "./dashboard/DashboardUploads";
import DocumentBreadCrumb from "./documents/DocumentBreadCrumb";
import axios from "axios";
import { ApiURL } from "../config";

const DocumentDashboard = (props) => {
  const [reload, setReload] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  const [recentFiles, setRecentFiles] = useState([]);
  const [favoriteFiles, setFavoriteFiles] = useState([]);
  const [clientList, setClientList] = useState([]);
  const [selectedMenu, setSelectedMenu] = useState([]);

  useEffect(() => {
    getDocumentClients();
  }, []);

  const getDocumentClients = async () => {
    try {
      const res = await axios.get(
        `${ApiURL}/documentCenter/getDocumentClients`,
        tokenConfig(),
      );
      if (res.data) {
        setClientList(res.data.clients);
        setFavoriteFiles(res.data.favoriteFiles);
        setRecentFiles(res.data.recentFiles);
        setIsLoaded(true);
      }
    } catch (e) {
      console.log("e", e);
    }
  };

  const toggleMenu = (menuId) => {
    if (typeof selectedMenu[menuId] === "undefined") {
      selectedMenu[menuId] = true;
    } else {
      selectedMenu[menuId] = selectedMenu[menuId] !== true;
    }
    setReload(!reload);
    setSelectedMenu(selectedMenu);
  };

  if (!isLoaded) {
    return <Loading />;
  }

  return (
    <div className="container">
      <div className="row">
        <div className="col-12">
          <h1 className="text-center mb-2">Document Dashboard</h1>
          {successMessage && (
            <div className="col-12">
              <div className="alert alert-success">{successMessage}</div>
            </div>
          )}
        </div>
        <div className="col-12">
          <DocumentBreadCrumb
            breadcrumb={[]}
            clientId={0}
            parentFolderPath={"/"}
            companyName={""}
          />
        </div>
        {/*<div className="col-12 col-md-6">*/}
        {/*  <DashboardUploads uploads={recentFiles} isLoaded={isLoaded} />*/}
        {/*</div>*/}
        {/*<div className="col-12 col-md-6">*/}
        {/*  <DashboardFavoriteFiles*/}
        {/*    favoriteFiles={favoriteFiles}*/}
        {/*    isLoaded={isLoaded}*/}
        {/*  />*/}
        {/*</div>*/}
        <div className="col-12">
          <div className="card border-dark border">
            <div className="card-header bg-dark">
              <h5 className="card-title text-white my-4 mx-2">
                Client / Customer List
              </h5>
              <p className="text-white">
                Select the client/customer to view or upload files to.
              </p>
              <Link className="btn btn-primary mb-2" to="/settings/customers/0">
                <AddIcon size={20} /> Create New Customer
              </Link>
            </div>
            <div className="card-body">
              <table className="table table-hover">
                <thead>
                  <tr className="table-primary">
                    <th>&nbsp;</th>
                    <th>Name</th>
                    <th>Modified</th>
                    <th>Files</th>
                    <th>Size</th>
                  </tr>
                </thead>
                <tbody>
                  {clientList &&
                    clientList.length > 0 &&
                    clientList.map((client, index) => {
                      return (
                        <tr key={index}>
                          <td width="50">
                            <Button
                              className="btn btn-sm btn-secondary"
                              onClick={() => toggleMenu(client.id)}
                            >
                              <MoreIcon size={15} />
                            </Button>
                            <ControlledMenu
                              onClose={() => toggleMenu(client.id)}
                              state={
                                selectedMenu[client.id] ? "open" : "closed"
                              }
                              direction="left"
                            >
                              <MenuItem>
                                <Link to={`/settings/customers/${client.id}`}>
                                  Edit Client
                                </Link>
                              </MenuItem>
                            </ControlledMenu>
                          </td>
                          <td>
                            <Link
                              to={`/documents/${client.id}`}
                              className="text-dark"
                            >
                              <i className="fas fa-folder" /> {client.name}
                            </Link>
                          </td>
                          <td>{client.modified}</td>
                          <td>{client.files}</td>
                          <td>{client.fileSize}</td>
                        </tr>
                      );
                    })}
                  {clientList && clientList.length === 0 && (
                    <tr>
                      <td colSpan="5">Add a client / customer</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  documentList: state.pages.documentList,
  dataList: state.pages.dataList,
  clientList: state.pages.clientList,
  clients: state.pages.clients,
});
export default connect(mapStateToProps, {
  getMyDocuments,
  updateDocumentClients,
  getDocuments,
  deleteFile,
  moveFile,
  favoriteFile,
  unFavoriteFile,
  archiveFile,
  getDocumentClients,
  getClientFolders,
  getClientFiles,
  deleteFolder,
})(DocumentDashboard);
