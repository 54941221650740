import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import {
  getMyDocuments,
  getDocuments,
  deleteFile,
  archiveFile,
  moveFile,
  favoriteFile,
  unFavoriteFile,
  getDocumentClients,
  getClientFolders,
  getClientFiles,
  deleteFolder,
  tokenConfig,
} from "../client/actions/apiActions";
import "react-confirm-alert/src/react-confirm-alert.css";
import Loading from "../components/layouts/Loader";
import FormInput from "../components/form/FormInput";
import "@szhsin/react-menu/dist/index.css";
import DocumentFiles from "./documents/DocumentFiles";
import DocumentFolders from "./documents/DocumentFolder";
import DocumentBreadCrumb from "./documents/DocumentBreadCrumb";
import FolderModal from "./documents/FolderModal";
import axios from "axios";
import { ApiURL } from "../config";

const DocumentFoldersFiles = (props) => {
  let {
    clientId,
    folderId,
    folderName1,
    folderName2,
    folderName3,
    folderName4,
    folderName5,
    folderName6,
    folderName7,
    folderName8,
    folderName9,
    folderName10,
    folderName11,
    folderName12,
  } = useParams();

  const [isLoaded, setIsLoaded] = useState(false);
  const [showSearchResults, setShowSearchResults] = useState(false);
  const [parentFolderPath, setParentFolderPath] = useState("/");
  const [breadcrumb, setBreadcrumb] = useState("");
  const [sortDir, setSortDir] = useState("");
  const [sortField, setSortField] = useState("");

  const [userRole, setUserRole] = useState([]);
  const [files, setFiles] = useState([]);
  const [folders, setFolders] = useState([]);
  const [clientFolders, setClientFolders] = useState([]);
  const [favorites, setFavorites] = useState([]);
  const [companyName, setCompanyName] = useState("");
  const [folderTypes, setFolderTypes] = useState([]);

  const [showModal, setShowModal] = useState(false);
  const [modalType, setModalType] = useState("");
  const [selectAll, setSelectAll] = useState(false);

  const getFiles = async () => {
    setIsLoaded(false);
    let fullPathT = "";
    if (typeof folderName1 !== "undefined") fullPathT += "/" + folderName1;
    if (typeof folderName2 !== "undefined") fullPathT += "/" + folderName2;
    if (typeof folderName3 !== "undefined") fullPathT += "/" + folderName3;
    if (typeof folderName4 !== "undefined") fullPathT += "/" + folderName4;
    if (typeof folderName5 !== "undefined") fullPathT += "/" + folderName5;
    if (typeof folderName6 !== "undefined") fullPathT += "/" + folderName6;
    if (typeof folderName7 !== "undefined") fullPathT += "/" + folderName7;
    if (typeof folderName8 !== "undefined") fullPathT += "/" + folderName8;
    if (typeof folderName9 !== "undefined") fullPathT += "/" + folderName9;
    if (typeof folderName10 !== "undefined") fullPathT += "/" + folderName10;
    if (typeof folderName11 !== "undefined") fullPathT += "/" + folderName11;
    if (typeof folderName12 !== "undefined") fullPathT += "/" + folderName12;

    if (!folderId) {
      const res = await axios.post(
        `${ApiURL}/documentCenter/getClientFolders`,
        {
          clientId: clientId ? clientId : props.auth.user.user.organizationId,
          sortDir,
          sortField,
        },
        tokenConfig(),
      );
      updateData(res.data);
    } else {
      const res = await axios.post(
        `${ApiURL}/documentCenter/getClientFiles`,
        {
          clientId: clientId ? clientId : props.auth.user.user.organizationId,
          fullPath: fullPathT,
          sortDir,
          sortField,
          folderId,
        },
        tokenConfig(),
      );
      updateData(res.data);
    }
  };

  const getFilesNoReload = async () => {
    let fullPathT = "";
    if (typeof folderName1 !== "undefined") fullPathT += "/" + folderName1;
    if (typeof folderName2 !== "undefined") fullPathT += "/" + folderName2;
    if (typeof folderName3 !== "undefined") fullPathT += "/" + folderName3;
    if (typeof folderName4 !== "undefined") fullPathT += "/" + folderName4;
    if (typeof folderName5 !== "undefined") fullPathT += "/" + folderName5;
    if (typeof folderName6 !== "undefined") fullPathT += "/" + folderName6;
    if (typeof folderName7 !== "undefined") fullPathT += "/" + folderName7;
    if (typeof folderName8 !== "undefined") fullPathT += "/" + folderName8;
    if (typeof folderName9 !== "undefined") fullPathT += "/" + folderName9;
    if (typeof folderName10 !== "undefined") fullPathT += "/" + folderName10;
    if (typeof folderName11 !== "undefined") fullPathT += "/" + folderName11;
    if (typeof folderName12 !== "undefined") fullPathT += "/" + folderName12;

    if (!folderId) {
      const res = await axios.post(
        `${ApiURL}/documentCenter/getClientFolders`,
        {
          clientId: clientId ? clientId : props.auth.user.user.organizationId,
          sortDir,
          sortField,
        },
        tokenConfig(),
      );
      setFiles(res.data.files);
    } else {
      const res = await axios.post(
        `${ApiURL}/documentCenter/getClientFiles`,
        {
          clientId: clientId ? clientId : props.auth.user.user.organizationId,
          fullPath: fullPathT,
          sortDir,
          sortField,
          folderId,
        },
        tokenConfig(),
      );
      setFiles(res.data.files);
    }
  };

  const updateData = (res) => {
    if (res?.companyName) {
      setClientFolders(res.clientFolders);
      setFolders(res.folders);
      setFiles(res.files);
      setFavorites(res.favorites);
      setCompanyName(res.companyName ?? "");
      setParentFolderPath(res.parentFolderPath ?? "/");
      setBreadcrumb(res.breadcrumb ?? []);
      setIsLoaded(true);
      setUserRole(res.userRole);
      setFolderTypes(res.folderTypes);
    }
  };
  const updateSort = (clickedSortField) => {
    let clickedSortDir = "DESC";
    if (clickedSortField === sortField) {
      if (sortDir === "DESC") {
        clickedSortDir = "ASC";
      } else {
        clickedSortDir = "DESC";
      }
    }
    setSortField(clickedSortField);
    setSortDir(clickedSortDir);
  };

  const createFolder = () => {
    setShowModal(true);
    setModalType("addFolder");
  };

  const closeFolderModal = () => {
    setShowModal(false);
    setModalType("");
  };

  useEffect(() => {
    getFiles();
  }, [
    clientId,
    folderId,
    sortDir,
    sortField,
    folderName1,
    folderName2,
    folderName3,
    folderName4,
  ]);

  const processSelectAll = (newSelectAll) => {
    if (newSelectAll) {
      if (files) {
        files.map((file) => {
          file.selected = true;
        });
      }
    } else {
      if (files) {
        files.map((file) => {
          file.selected = false;
        });
      }
    }
    setSelectAll(newSelectAll);
  };

  if (!isLoaded) {
    return <Loading />;
  }

  return (
    <div className="container">
      <div className="row">
        <div className="col-12">
          {clientId !== 0 && showSearchResults === false && (
            <DocumentBreadCrumb
              userPermissions={props.auth.user.userRole}
              breadcrumb={breadcrumb}
              clientId={
                clientId ? clientId : props.auth.user.user.organizationId
              }
              parentFolderPath={parentFolderPath}
              companyName={companyName}
            />
          )}
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="card border-dark border">
            <div className="card-header bg-dark">
              <h3 className="card-title text-white m-4">{companyName}</h3>
            </div>
            <div className="card-body">
              {(userRole?.userType === "A" ||
                (userRole?.foldersAdd === "1" && userRole?.roleType === "1")) &&
                parentFolderPath !== "/uploads" && (
                  <button
                    className="btn btn-secondary btn-sm mb-2"
                    onClick={createFolder}
                  >
                    <i className="fas fa-folder-plus" /> Create Folder
                  </button>
                )}

              <div className="container m-0 p-0">
                <div className="row">
                  <div className="col-12">
                    <table className="table table-hover">
                      <thead>
                        <tr className="table-primary">
                          {files?.length > 0 && (
                            <th
                              width="50"
                              nowrap="true"
                              className="text-center"
                            >
                              <input
                                name="selectAll"
                                type="checkbox"
                                value={1}
                                onChange={(e) => {
                                  processSelectAll(!selectAll);
                                }}
                                checked={selectAll}
                              />
                            </th>
                          )}
                          <th width="50" nowrap="true">
                            &nbsp;
                          </th>
                          <th width="50" className="text-center" nowrap="true">
                            Type
                          </th>
                          <th nowrap="true">&nbsp;</th>
                          <th
                            nowrap="true"
                            className="cursorClick"
                            onClick={() => {
                              updateSort("Name");
                            }}
                          >
                            Name
                            {(sortField === "Name" ||
                              sortField === "Filename") && (
                              <>
                                {" "}
                                {sortDir === "ASC" && (
                                  <i className="fas fa-sort-alpha-down" />
                                )}
                                {sortDir === "DESC" && (
                                  <i className="fas fa-sort-alpha-up" />
                                )}
                              </>
                            )}
                          </th>
                          <th
                            nowrap="true"
                            className="cursorClick"
                            onClick={() => {
                              updateSort("Date");
                            }}
                          >
                            Modified
                            {sortField === "Date" && (
                              <>
                                {" "}
                                {sortDir === "ASC" && (
                                  <i className="fas fa-sort-alpha-down" />
                                )}
                                {sortDir === "DESC" && (
                                  <i className="fas fa-sort-alpha-up" />
                                )}
                              </>
                            )}
                          </th>
                          <th
                            nowrap="true"
                            className="cursorClick"
                            onClick={() => {
                              updateSort("Size");
                            }}
                          >
                            Filesize
                            {sortField === "Size" && (
                              <>
                                {" "}
                                {sortDir === "ASC" && (
                                  <i className="fas fa-sort-alpha-down" />
                                )}
                                {sortDir === "DESC" && (
                                  <i className="fas fa-sort-alpha-up" />
                                )}
                              </>
                            )}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {folders?.length > 0 && (
                          <DocumentFolders
                            hasFiles={files?.length > 0}
                            clientId={
                              clientId
                                ? clientId
                                : props.auth.user.user.organizationId
                            }
                            folders={folders}
                            folderTypes={folderTypes}
                            getFiles={getFiles}
                            closeFolderModal={closeFolderModal}
                          />
                        )}
                        {files?.length > 0 && (
                          <DocumentFiles
                            clientId={
                              clientId
                                ? clientId
                                : props.auth.user.user.organizationId
                            }
                            files={files}
                            favorites={favorites}
                            clientFolders={clientFolders}
                            folderTypes={folderTypes}
                            getFiles={getFiles}
                            selectAll={selectAll}
                            closeFolderModal={closeFolderModal}
                          />
                        )}
                        {folders?.length === 0 && files?.length === 0 && (
                          <tr>
                            <td colSpan="6" className="text-center">
                              No files or folders found
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>

                {(userRole?.userType === "A" ||
                  (userRole?.filesCanUpload === "1" &&
                    parentFolderPath === "/uploads")) &&
                  breadcrumb?.length > 0 && (
                    <div className="row">
                      <div className="col-12">
                        <FormInput
                          showOneColumn="true"
                          type="inline-upload"
                          className="form-control form-control-sm"
                          clientId={
                            clientId
                              ? clientId
                              : props.auth.user.user.organizationId
                          }
                          folderId={folderId ?? ""}
                          parentFolderPath={parentFolderPath}
                          getFiles={getFiles}
                          getFilesNoReload={getFilesNoReload}
                          placeholder=""
                          name="inlineUpload"
                        />
                      </div>
                    </div>
                  )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {showModal && (
        <FolderModal
          clientId={clientId}
          folderTypes={folderTypes}
          parentFolderId={folderId}
          showModal={showModal}
          closeFolderModal={closeFolderModal}
          modalType={modalType}
          getFiles={getFiles}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  documentList: state.pages.documentList,
  dataList: state.pages.dataList,
  clientList: state.pages.clientList,
  clients: state.pages.clients,
});

export default connect(mapStateToProps, {
  getMyDocuments,
  getDocuments,
  deleteFile,
  moveFile,
  favoriteFile,
  unFavoriteFile,
  archiveFile,
  getDocumentClients,
  getClientFolders,
  getClientFiles,
  deleteFolder,
})(DocumentFoldersFiles);
