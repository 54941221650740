import React from "react";
import classnames from "classnames";
import InputError from "./InputError";
import InputHelpText from "./InputHelpText";

const FormInputImage = (props) => {
  return (
    <div className={`col-12 align-bottom col-sm-${props.divColSize}`}>
      <div className="container p-0">
        <div className="form-group row border-bottom py-2">
          {props.value && (
            <>
              <label className="col-4" htmlFor={props.name}>
                {props.label}
                {(props?.required || props?.field?.required) && (
                  <small className="text-danger">&nbsp; *</small>
                )}
              </label>
              <div className="col-4 text-center">
                <img
                  src={props.value}
                  border="2"
                  width="100"
                  className="img-fluid mb-2"
                  alt=""
                />
                <br />
                <a
                  href={props.value}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="btn btn-success"
                >
                  View Image
                </a>{" "}
                <button className="btn btn-danger" onClick={props.onDeleteFile}>
                  Delete Image
                </button>
              </div>
              <div className="col-4">
                <input
                  id={props.name}
                  name={props.name}
                  type="file"
                  className={classnames({ "is-invalid": props.error })}
                  onChange={props.onChange}
                  aria-describedby={props.name + "Help"}
                  placeholder={props.placeholder}
                />
              </div>
            </>
          )}
          {!props.value && (
            <>
              <label className="col-4" htmlFor={props.name}>
                {props.label}
                {(props?.required || props?.field?.required) && (
                  <small className="text-danger">&nbsp; *</small>
                )}
              </label>
              <div className="col-8">
                <input
                  id={props.name}
                  name={props.name}
                  type="file"
                  className={classnames({ "is-invalid": props.error })}
                  onChange={props.onChange}
                  aria-describedby={props.name + "Help"}
                  placeholder={props.placeholder}
                />
              </div>
            </>
          )}

          <InputError error={props.error} />
          <InputHelpText helpText={props.helpText} />
        </div>
      </div>
    </div>
  );
};

export default FormInputImage;
