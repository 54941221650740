import React, { useEffect, useState } from "react";
import { timezones, agencyTypes, languageText } from "../config";
import DataOverview from "../components/data/Wrapper";
import Loader from "../components/layouts/Loader";
import { useLocation, useMatch, useParams } from "react-router-dom";

const tableName = "organizations";
const editPathName = "/settings/customers/:id";
const tableColumns = [
  {
    displayName: "Status",
    dataField: "status",
    type: "bool",
    sort: false,
  },
  {
    displayName: "Customer Name",
    dataField: "name",
    sort: true,
  },
  {
    displayName: "Contact Name",
    dataField: "contactName",
    sort: true,
  },
  {
    displayName: "Notification Email",
    dataField: "notificationEmail",
    sort: true,
  },
  {
    displayName: "Work Phone",
    dataField: "workPhone",
    sort: true,
  },
  {
    displayName: "Tools",
    dataField: "tools",
    sort: false,
  },
];
const fieldData = [
  {
    displayName: "Business / Organization Name",
    fieldName: "name",
    fieldType: "text",
    colSize: 3,
    required: true,
    requiredMessage: "Organization Name is required!",
    placeholder: "business / organization name",
  },
  {
    displayName: "Work Phone",
    fieldName: "workPhone",
    fieldType: "phone",
    colSize: 3,
  },
  {
    displayName: "Status",
    fieldName: "status",
    fieldType: "toggle",
    colSize: 3,
  },
  {
    displayName: "Contact Name",
    fieldName: "contactName",
    fieldType: "text",
    colSize: 3,
    required: true,
    requiredMessage: "Contact Name is required!",
    placeholder: "contact name",
  },
  {
    displayName: "Contact Phone",
    fieldName: "cellPhone",
    fieldType: "phone",
    colSize: 3,
  },
  {
    displayName: "Contact Email",
    fieldName: "email",
    fieldType: "email",
    colSize: 3,
  },
  {
    displayName: "Website",
    fieldName: "website",
    fieldType: "text",
    colSize: 3,
  },
  {
    displayName: "Notification Email",
    fieldName: "notificationEmail",
    fieldType: "text",
    colSize: 3,
  },
  {
    displayName: "Client Notes",
    fieldName: "notes",
    fieldType: "textarea",
    colSize: 1,
  },
];
const fieldDataDefault = {
  name: "",
  status: true,
  cellPhone: "",
  workPhone: "",
  faxPhone: "",
  email: "",
  website: "",
  notes: "",
};

const Organizations = () => {
  const { id } = useParams();
  const { pathname } = useLocation();
  const match = useMatch(editPathName);
  const [loading, setLoading] = useState(true);
  let parentRoute = pathname;

  useEffect(() => {
    setLoading(false);
  }, []);

  if (match) {
    parentRoute = match.pattern.path.replace("/:id", "");
  }

  if (loading) return <Loader />;
  return (
    <DataOverview
      id={id ?? null}
      tableName={tableName}
      pageMode={id ? "edit" : "list"}
      route={pathname}
      sortFieldName="name"
      parentRoute={parentRoute}
      pageTitle={languageText["en"].components[tableName].pageTitle}
      listTitle={languageText["en"].components[tableName].listTitle}
      listSubTitle={languageText["en"].components[tableName].listSubTitle}
      addTitle={languageText["en"].components[tableName].addTitle}
      addSubTitle={languageText["en"].components[tableName].addSubTitle}
      editTitle={languageText["en"].components[tableName].editTitle}
      editSubTitle={languageText["en"].components[tableName].editSubTitle}
      tableColumns={tableColumns}
      fieldData={fieldData}
      fieldDataDefault={fieldDataDefault}
    />
  );
};

export default Organizations;
