import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";

import React from "react";
import ReactDOM from "react-dom";

import App from "./App";
import "./assets/app.css";
import { registerServiceWorker } from "./serviceWorker";

import { isSafari, isIOS } from "react-device-detect";
ReactDOM.render(<App />, document.getElementById("root"));
if (!isSafari && !isIOS) registerServiceWorker();
