import React from "react";
import { onMessageListener } from "../firebase";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const FirebaseControls = () => {
  onMessageListener()
    .then((payload) => {
      toast.success(
        payload.notification.title + "\n" + payload.notification.body
      );
    })
    .catch((err) => console.log("failed: ", err));

  return <ToastContainer />;
};

export default FirebaseControls;
