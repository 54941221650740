import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  loadDashboard,
  updateFirebaseToken,
  sendFirebaseNotification,
  unFavoriteFile,
  tokenConfig,
} from "../client/actions/apiActions";
import DashboardNotifications from "./dashboard/DashboardNotifications";
import DashboardUploads from "./dashboard/DashboardUploads";
import DashboardFavoriteFiles from "./dashboard/DashboardFavoriteFiles";
import DashboardNewUsers from "./dashboard/DashboardNewUsers";
import { getMessagingToken } from "../firebase";

import { isSafari, isIOS } from "react-device-detect";
import axios from "axios";
import { ApiURL } from "../config";
import Loading from "../components/form/Loading";
import { useNavigate } from "react-router-dom";

const Dashboard = (props) => {
  const [favoriteFiles, setFavorites] = useState([]);
  const [recentUploads, setRecentUploads] = useState([]);
  const [newUsers, setNewUsers] = useState([]);
  const [notifications, setNotifications] = useState([]);
  const [userRole, setUserRole] = useState([]);
  const [browserNotifications, setBrowserNotifications] = useState(false);
  const [hideNotificationButton, setHideNotificationButton] = useState(false);
  const [firebaseToken, setToken] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (firebaseToken && props.auth?.notificationServices?.length === 0) {
      props.updateFirebaseToken(firebaseToken);
    } else if (firebaseToken) {
      checkTokens(props.auth.notificationServices, firebaseToken);
    }
  }, [firebaseToken]);

  const checkTokens = (tokens, currentToken) => {
    const tokenFound = tokens.filter((token) => token.code === currentToken);
    if (tokenFound.length === 0) {
      props.updateFirebaseToken(firebaseToken);
    }
  };

  const enableNotifications = () => {
    if (!isSafari && !isIOS) {
      Notification.requestPermission().then(function (res) {
        if (res === "denied") {
          setBrowserNotifications(false);
        } else {
          setBrowserNotifications(true);
        }
      });
    }
  };

  const loadDashboard = async () => {
    const res = await axios.get(`${ApiURL}/loadDashboard`, tokenConfig());
    const userRole = JSON.parse(localStorage.getItem("userRole"));

    setUserRole(parseInt(userRole.userRole));
    setFavorites(res.data.favoriteFiles);
    setRecentUploads(res.data.recentFiles);
    setNewUsers(res.data.newUsers);
    setNotifications(res.data.dashboardNotifications);
    setIsLoaded(true);
  };

  useEffect(() => {
    const userRole = JSON.parse(localStorage.getItem("userRole"));
    if (userRole?.roleType === "1") {
      navigate("/documents/dashboard");
    } else {
      navigate("/my-documents");
    }
    return;

    loadDashboard();

    if (!isSafari && !isIOS) {
      if (Notification.permission === "granted") {
        setBrowserNotifications(true);
        getMessagingToken(setToken);
      }
    } else {
      setHideNotificationButton(true);
    }
  }, []);

  return <Loading />;

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-12 col-sm-6">
            <DashboardNotifications
              notifications={notifications}
              isLoaded={isLoaded}
            />
          </div>
          {(userRole === 1 || userRole === 3) && (
            <div className="col-12 col-sm-6">
              <DashboardUploads uploads={recentUploads} isLoaded={isLoaded} />
            </div>
          )}
          <div className="col-12 col-sm-6">
            <DashboardFavoriteFiles
              favoriteFiles={favoriteFiles}
              isLoaded={isLoaded}
            />
          </div>
          {(userRole === 1 || userRole === 3) && (
            <div className="col-12 col-sm-6">
              <DashboardNewUsers newUsers={newUsers} isLoaded={isLoaded} />
            </div>
          )}
        </div>
        <div className="row">
          <div className="col-12">
            {!browserNotifications && !hideNotificationButton && (
              <>
                <button
                  className="btn btn-warning"
                  onClick={enableNotifications}
                >
                  Enable Browser Notifications
                </button>
                <br />
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth.user,
  data: state.pages.data,
});

export default connect(mapStateToProps, {
  unFavoriteFile,
  updateFirebaseToken,
  sendFirebaseNotification,
})(Dashboard);
