import React, { useEffect, useState } from "react";

import { languageText, timezones } from "../config";
import DataOverview from "./data/Wrapper";
import { connect } from "react-redux";
import { getProfileInfo } from "../client/actions/apiActions";
import Loader from "./layouts/Loader";
import ProfileLinks from "./ProfileLinks";
import { useLocation } from "react-router-dom";

const tableName = "users";

const fieldData = [
  {
    fieldName: "firstName",
    fieldType: "text",
    colSize: 3,
    required: true,
  },
  {
    fieldName: "lastName",
    fieldType: "text",
    colSize: 3,
    required: true,
  },
  {
    fieldName: "email",
    fieldType: "text",
    colSize: 3,
    required: true,
  },
  {
    fieldName: "cellPhone",
    fieldType: "text",
    colSize: 3,
  },
  {
    fieldName: "workPhone",
    fieldType: "text",
    colSize: 3,
  },
  {
    fieldName: "timezone",
    fieldType: "dropdown",
    dropdownData: timezones,
    colSize: 3,
  },
  {
    fieldName: "image",
    fieldType: "image",
    colSize: 1,
  },
];

const Profile = (props) => {
  const [viewMode, setViewMode] = useState("loading");
  const [fieldDataResults, setFieldDataResults] = useState(props.auth.user);
  const { pathname } = useLocation();
  const parentRoute = pathname;

  useEffect(() => {
    props.getProfileInfo();
  }, []);

  useEffect(() => {
    if (props.data.userProfile) {
      setFieldDataResults(props.data.userProfile);
      setViewMode("data");
    }
  }, [props.data.userProfile]);

  useEffect(() => {
    if (props.auth.user) {
      setFieldDataResults(props.auth.user);
      setViewMode("data");
    }
  }, [props.auth.user]);

  if (viewMode === "loading") {
    return <Loader />;
  }

  return (
    <div className="container p-0">
      <div className="row">
        <div className="col-md-3 col-2">
          <ProfileLinks linkActive="/profile" />
        </div>
        <div className="col-md-9 col-10">
          <DataOverview
            id={fieldDataResults.id}
            tableName={tableName}
            pageMode={"edit"}
            singleEdit={true}
            settings={{ showClone: false }}
            route={pathname}
            parentRoute={parentRoute}
            fieldData={fieldData}
            fieldDataResults={fieldDataResults}
            languageTable={tableName + "Profile"}
            pageTitle={
              languageText["en"].components[tableName + "Profile"].pageTitle
            }
            listTitle={
              languageText["en"].components[tableName + "Profile"].listTitle
            }
            listSubTitle={
              languageText["en"].components[tableName + "Profile"].listSubTitle
            }
            addTitle={
              languageText["en"].components[tableName + "Profile"].addTitle
            }
            addSubTitle={
              languageText["en"].components[tableName + "Profile"].addSubTitle
            }
            editTitle={
              languageText["en"].components[tableName + "Profile"].editTitle
            }
            editSubTitle={
              languageText["en"].components[tableName + "Profile"].editSubTitle
            }
          />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth.user,
  data: state.pages.data,
});

export default connect(mapStateToProps, { getProfileInfo })(Profile);
