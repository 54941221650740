import React, { useEffect, useState } from "react";
import { languageText } from "../config";
import DataOverview from "../components/data/Wrapper";
import Loader from "../components/layouts/Loader";
import { useLocation, useMatch, useParams } from "react-router-dom";

const tableName = "users";
const editPathName = "/organizations/users/:organizationId/:id";
const tableColumns = [
  {
    displayName: "Active",
    dataField: "active",
    type: "bool",
    sort: false,
  },
  {
    displayName: "Image",
    dataField: "image",
    type: "image",
    sort: true,
  },
  {
    displayName: "First Name",
    dataField: "firstName",
    sort: true,
  },
  {
    displayName: "Last Name",
    dataField: "lastName",
    sort: true,
  },
  {
    displayName: "Email",
    dataField: "email",
    sort: true,
  },
  {
    displayName: "Role",
    dataField: "role",
    sort: true,
  },
  {
    displayName: "Tools",
    dataField: "tools",
    sort: false,
  },
];
const fieldData = [
  {
    displayName: "User Info",
    fieldName: "titleUsersName",
    fieldType: "title",
    colSize: 1,
  },
  {
    displayName: "First Name",
    fieldName: "firstName",
    fieldType: "text",
    colSize: 3,
    required: true,
  },
  {
    displayName: "Last Name",
    fieldName: "lastName",
    fieldType: "text",
    colSize: 3,
    required: true,
  },
  {
    displayName: "Active",
    fieldName: "active",
    fieldType: "toggle",
    colSize: 3,
  },
  {
    displayName: "Email",
    fieldName: "email",
    fieldType: "email",
    colSize: 2,
    required: true,
  },
  {
    displayName: "Phone",
    fieldName: "cellPhone",
    fieldType: "phone",
    colSize: 2,
  },
  {
    displayName: "Notes",
    fieldName: "notes",
    fieldType: "textarea",
    colSize: 1,
  },

  {
    displayName: "Role Info",
    fieldName: "titleRoleInfo",
    fieldType: "title",
    colSize: 1,
  },
  {
    displayName: "Role",
    fieldName: "userRole",
    fieldType: "radio",
    colSize: 1,
    required: true,
  },
  {
    fieldName: "organizationId",
    fieldType: "hidden",
    colSize: 0,
  },
];
const fieldDataDefault = {};

const Users = () => {
  const { id } = useParams();
  let { organizationId } = useParams();
  const { pathname } = useLocation();
  const match = useMatch(editPathName);
  const [loading, setLoading] = useState(true);
  let parentRoute = pathname;

  useEffect(() => {
    setLoading(false);
  }, []);

  if (match) {
    parentRoute = match.pattern.path.replace("/:id", "");
    parentRoute = parentRoute.replace("/:organizationId", "/" + organizationId);
  }
  if (loading) return <Loader />;

  return (
    <DataOverview
      id={id ?? ""}
      organizationId={organizationId ?? ""}
      tableName={tableName}
      pageMode={id ? "edit" : "list"}
      route={pathname}
      showOrganizationsLink={true}
      parentRoute={parentRoute}
      pageTitle={languageText["en"].components[tableName].pageTitle}
      listTitle={languageText["en"].components[tableName].listTitle}
      listSubTitle={languageText["en"].components[tableName].listSubTitle}
      addTitle={languageText["en"].components[tableName].addTitle}
      addSubTitle={languageText["en"].components[tableName].addSubTitle}
      editTitle={languageText["en"].components[tableName].editTitle}
      editSubTitle={languageText["en"].components[tableName].editSubTitle}
      tableColumns={tableColumns}
      fieldData={fieldData}
      fieldDataDefault={fieldDataDefault}
    />
  );
};
export default Users;
