import React from "react";
import CheckPassword from "zxcvbn";
import "./passwordStrengthMeter.css";

const PasswordStrengthMeter = ({ password }) => {
  if (!password) return null;
  const testedResult = CheckPassword(password).score;
  const createPasswordLabel = (result) => {
    switch (result) {
      case 0:
        return "Weak";
      case 1:
        return "Weak";
      case 2:
        return "Fair";
      case 3:
        return "Good";
      case 4:
        return "Strong";
      default:
        return "Weak";
    }
  };
  return (
    <div className="password-strength-meter">
      <progress
        className={`password-strength-meter-progress strength-${testedResult}`}
        value={testedResult}
        max="4"
      />
      <br />
      <label className="password-strength-meter-label">
        {password && (
          <>
            <strong>Password strength:</strong>{" "}
            {createPasswordLabel(testedResult)}
          </>
        )}
      </label>
    </div>
  );
};

export default PasswordStrengthMeter;
