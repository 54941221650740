import { Pagination } from "./Pagination";
import { TableHeaderRow } from "./TableHeaderRow";
import { TableDataRow } from "./TableDataRow";
import React from "react";

export const Table = (props) => {
  const {
    columns,
    currentPage,
    totalPages,
    onUpdatePage,
    recordsPerPage,
    sortDirection,
    sortFieldName,
    hasStatus,
    tableData,
    tableName,
    onEdit,
    onDelete,
    onArchive,
    changeSort,
    onChangeRecordsPerPage,
    totalRecords,
    onViewsUsers,
    userPermissions,
    route,
    parentRoute,
    onResendInvite,
  } = props;
  return (
    <table className="table table-hover table-striped m-0 bg-white">
      <thead>
        <Pagination
          columns={columns}
          currentPage={currentPage}
          totalPages={totalPages}
          onUpdatePage={onUpdatePage}
          recordsPerPage={recordsPerPage}
          onChangeRecordsPerPage={onChangeRecordsPerPage}
        />
      </thead>
      <thead>
        {columns && (
          <TableHeaderRow
            columns={columns}
            sortDirection={sortDirection}
            sortFieldName={sortFieldName}
            changeSort={changeSort}
            hasStatus={hasStatus}
            tableName={tableName}
          />
        )}
      </thead>
      <tbody>
        {tableData && (
          <TableDataRow
            route={route}
            lists={props.lists}
            parentRoute={parentRoute}
            tableData={tableData}
            columns={columns}
            tableName={tableName}
            onViewsUsers={onViewsUsers}
            onResendInvite={onResendInvite}
            onEdit={onEdit}
            onDelete={onDelete}
            hasStatus={hasStatus}
            onArchive={onArchive}
            userPermissions={userPermissions}
          />
        )}
      </tbody>
      <tfoot>
        <Pagination
          columns={columns}
          currentPage={currentPage}
          totalPages={totalPages}
          onUpdatePage={onUpdatePage}
          recordsPerPage={recordsPerPage}
          onChangeRecordsPerPage={onChangeRecordsPerPage}
        />
        <tr>
          <td colSpan={columns.length}>
            Total Pages : {totalPages} - Total Records : {totalRecords}
          </td>
        </tr>
      </tfoot>
    </table>
  );
};
