import React from "react";
import classnames from "classnames";

const FormInputDisplay = (props) => {
  return (
    <div className={`col-12 align-bottom col-sm-${props.divColSize}`}>
      <div className="container p-0">
        <div className="form-group row border-bottom pb-2">
          <label
            htmlFor={props.name}
            className={classnames("col-sm-12 m-0 pt-2 pb-2", {
              "is-invalid": props.error,
            })}
          >
            {props.label}
          </label>
        </div>
      </div>
    </div>
  );
};

export default FormInputDisplay;
