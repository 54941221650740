import React from "react";

export const Pagination = ({
  columns,
  recordsPerPage,
  onChangeRecordsPerPage,
  currentPage,
  totalPages,
  onUpdatePage,
}) => {
  const pages = [];

  // GO BACK 1 PAGE
  if (parseInt(currentPage) === 1) {
    pages.push(
      <li key="0" className="page-item disabled">
        <span className="page-link">&laquo;</span>
      </li>
    );
  } else {
    pages.push(
      <li key="-1" className="page-item">
        <button className="page-link" onClick={() => onUpdatePage(1)}>
          First
        </button>
      </li>
    );
    pages.push(
      <li key="0" className="page-item">
        <button
          className="page-link"
          onClick={() => onUpdatePage(currentPage - 1)}
        >
          &laquo;
        </button>
      </li>
    );
  }

  if (parseInt(totalPages) > 15) {
    let startPage = parseInt(currentPage) - 6;
    let endPage = parseInt(currentPage) + 6;
    if (startPage < 1) startPage = 1;
    if (endPage > totalPages) endPage = totalPages;
    if (endPage < 15 && totalPages > 20) endPage = 15;
    for (let i = startPage; i <= endPage; i++) {
      if (i === parseInt(currentPage)) {
        pages.push(
          <li key={i} className="page-item active">
            <span className="page-link">
              {i}
              <span className="sr-only">(current)</span>
            </span>
          </li>
        );
      } else {
        pages.push(
          <li key={i} className="page-item">
            <button className="page-link" onClick={() => onUpdatePage(i)}>
              {i}
            </button>
          </li>
        );
      }
    }
  } else {
    for (let i = 1; i <= parseInt(totalPages); i++) {
      if (i === parseInt(currentPage)) {
        pages.push(
          <li key={i} className="page-item active">
            <span className="page-link">
              {i}
              <span className="sr-only">(current)</span>
            </span>
          </li>
        );
      } else {
        pages.push(
          <li key={i} className="page-item">
            <button className="page-link" onClick={() => onUpdatePage(i)}>
              {i}
            </button>
          </li>
        );
      }
    }
  }

  // GO FORWARD ONE PAGE
  if (parseInt(totalPages) === parseInt(currentPage)) {
    pages.push(
      <li key={totalPages + 1} className="page-item disabled">
        <span className="page-link">&raquo;</span>
      </li>
    );
  } else {
    pages.push(
      <li key={totalPages + 1} className="page-item">
        <button
          className="page-link"
          onClick={() => onUpdatePage(currentPage + 1)}
        >
          &raquo;
        </button>
      </li>
    );
    pages.push(
      <li key={totalPages + 2} className="page-item">
        <button className="page-link" onClick={() => onUpdatePage(totalPages)}>
          Last
        </button>
      </li>
    );
  }

  return (
    <tr>
      <td colSpan={columns.length - 1} className="pt-4">
        <nav aria-label="Page List">
          <ul className="pagination">{pages}</ul>
        </nav>
      </td>
      <td className="text-end">
        <select
          className="form-control"
          id="recordsPerPage"
          name="recordsPerPage"
          value={recordsPerPage}
          //defaultValue={recordsPerPage}
          onChange={(e) => onChangeRecordsPerPage(e)}
          style={{ maxWidth: "120px", display: "inline" }}
        >
          <option value="">-- SELECT --</option>
          <option value="5">5</option>
          <option value="25">25</option>
          <option value="50">50</option>
          <option value="100">100</option>
          <option value="10000">all</option>
        </select>
      </td>
    </tr>
  );
};
