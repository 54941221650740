import React, { useEffect, useState } from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import FormInput from "../../components/form/FormInput";
import store from "../../client/store";
import { ApiURL } from "../../config";
import { toast } from "react-toastify";

const FolderModal = (props) => {
  const {
    showModal,
    modalType,
    originalFolderName,
    originalFileName,
    clientFolders,
    folderTypes,
    fileId,
  } = props;
  const [folderName, setFolderName] = useState("");
  const [fileName, setFileName] = useState("");
  const [folderLocation, setFolderLocation] = useState("");
  const [folderType, setFolderType] = useState("");
  const [reload, setReload] = useState(false);

  const onEditFolder = () => {
    const token = store.getState().auth.token;
    const formData = new FormData();
    formData.append(
      "clientId",
      props.clientId ? props.clientId : props.auth.user.user.organizationId,
    );
    let apiURL = `${ApiURL}/documentCenter/folderEdit`;
    formData.append("folderId", props.folderId);
    formData.append("folderName", folderName);

    fetch(apiURL, {
      method: "POST",
      headers: { Authorization: token },
      body: formData,
    })
      .then((res) => res.json())
      .then((output) => {
        if (output.status === 1) {
          props.getFiles();
          props.closeFolderModal();
        }
      });
  };

  const onCreateFolder = () => {
    const token = store.getState().auth.token;
    const formData = new FormData();
    formData.append(
      "clientId",
      props.clientId ? props.clientId : props.auth.user.user.organizationId,
    );
    let apiURL = `${ApiURL}/documentCenter/folderCreate`;
    formData.append("parentFolderId", props.parentFolderId ?? "0");
    formData.append("folderType", folderType ?? "");
    formData.append("folderName", folderName);

    fetch(apiURL, {
      method: "POST",
      headers: { Authorization: token },
      body: formData,
    })
      .then((res) => res.json())
      .then((output) => {
        if (output.status === true) {
          props.getFiles();
          props.closeFolderModal();
          toast.success("Folder created successfully");
        } else {
          toast.error(output.message);
        }
      });
  };

  const onSaveFile = () => {
    props.confirmSaveFile(fileName);
  };

  const onMoveFile = () => {
    props.confirmMoveFile(fileId, folderLocation);
  };

  const onMoveFiles = () => {
    props.confirmMoveFiles(fileId, folderLocation);
  };

  useEffect(() => {
    setReload(!reload);
    setFolderName(originalFolderName);
    setFileName(originalFileName);
  }, []);

  if (modalType === "addFolder" && !folderTypes) {
    toast.error("No folder types available");
    return null;
  }
  if (
    (modalType === "moveFile" || modalType === "moveFiles") &&
    !clientFolders
  ) {
    toast.error("No folders to move file to");
    return null;
  }

  return (
    <Modal
      isOpen={showModal}
      toggle={() => props.closeFolderModal()}
      className={props.className}
    >
      <ModalHeader toggle={props.closeFolderModal}>
        {modalType === "addFolder" ? "ADD A NEW FOLDER" : ""}
        {modalType === "editFolder" ? "EDIT FOLDER NAME" : ""}
        {modalType === "editFile" ? "EDIT FILENAME" : ""}
        {modalType === "moveFile" || modalType === "moveFiles"
          ? "MOVE FILE"
          : ""}
      </ModalHeader>
      <ModalBody>
        <div className="container">
          <div className="row">
            {modalType === "addFolder" && (
              <>
                <FormInput
                  showOneColumn="true"
                  type="dropdown"
                  className="form-control form-control-sm"
                  id="newFolderType"
                  name="newFolderType"
                  label="Folder Type"
                  required={true}
                  options={folderTypes}
                  value={folderType === "" || !folderType ? 2 : folderType}
                  onChange={(value) => setFolderType(value)}
                  placeholder="Folder Type"
                />
                <FormInput
                  showOneColumn="true"
                  type="text"
                  required={true}
                  className="form-control form-control-sm"
                  id="newFolderName"
                  name="newFolderName"
                  label="New Folder Name"
                  value={folderName}
                  onChange={(value) => setFolderName(value)}
                  placeholder="New Folder Name"
                />
              </>
            )}
            {modalType === "editFolder" && (
              <>
                Original Name: {originalFolderName}
                <br />
                <FormInput
                  showOneColumn="true"
                  type="text"
                  required={true}
                  className="form-control form-control-sm"
                  id="newFolderName"
                  name="newFolderName"
                  label="New Folder Name"
                  value={folderName}
                  onChange={(value) => setFolderName(value)}
                  placeholder="New Folder Name"
                />
              </>
            )}
            {modalType === "editFile" && (
              <>
                <div className="row">
                  <div className="col-12">
                    Original Name: {originalFileName}
                  </div>
                </div>
                <FormInput
                  showOneColumn="true"
                  type="text"
                  className="form-control form-control-sm"
                  id="newFileName"
                  name="newFileName"
                  required={true}
                  label="New File Name"
                  autoFocus
                  value={fileName}
                  onChange={(value) => setFileName(value)}
                  placeholder="New File Name"
                />
              </>
            )}

            {modalType === "moveFile" && (
              <>
                <p>Where do you want to move this file?</p>
                <FormInput
                  showOneColumn="true"
                  type="dropdown"
                  required={true}
                  className="form-control form-control-sm"
                  id="folderLocation"
                  name="folderLocation"
                  label="New Location"
                  onChange={(value) => setFolderLocation(value)}
                  placeholder=""
                  options={clientFolders}
                />
              </>
            )}

            {modalType === "moveFiles" && (
              <>
                <p>Where do you want to move these files?</p>
                <FormInput
                  showOneColumn="true"
                  required={true}
                  type="dropdown"
                  className="form-control form-control-sm"
                  id="folderLocation"
                  name="folderLocation"
                  label="New Location"
                  onChange={(value) => setFolderLocation(value)}
                  placeholder=""
                  options={clientFolders}
                />
              </>
            )}
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <button
          className="btn btn-sm btn-outline-primary"
          onClick={() => props.closeFolderModal()}
        >
          Cancel
        </button>
        {modalType === "addFolder" && (
          <button className="btn btn-sm btn-primary" onClick={onCreateFolder}>
            Save
          </button>
        )}
        {modalType === "editFolder" && (
          <button className="btn btn-sm btn-primary" onClick={onEditFolder}>
            Save
          </button>
        )}
        {modalType === "editFile" && (
          <button className="btn btn-sm btn-primary" onClick={onSaveFile}>
            Save
          </button>
        )}
        {modalType === "moveFile" && (
          <button className="btn btn-sm btn-primary" onClick={onMoveFile}>
            Move
          </button>
        )}
        {modalType === "moveFiles" && (
          <button className="btn btn-sm btn-primary" onClick={onMoveFiles}>
            Move
          </button>
        )}
      </ModalFooter>
    </Modal>
  );
};
export default FolderModal;
