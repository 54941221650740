import React from "react";
import classnames from "classnames";
import FormInputFancyCheckboxElement from "./FormInputFancyCheckboxElement";
import InputError from "./InputError";
import InputHelpText from "./InputHelpText";

const FormInputFancyCheckbox = (props) => {
  const { colSize } = props;

  let colLength1;
  let colLength2;

  if (colSize === 0) {
    colLength1 = "col-sm-4";
    colLength2 = "col-sm-8";
  }
  if (colSize === 1) {
    colLength1 = "col-sm-12";
    colLength2 = "col-sm-12";
  }
  if (colSize === 2) {
    colLength1 = "col-sm-4";
    colLength2 = "col-sm-8";
  }
  if (colSize === 3) {
    colLength1 = "col-sm-4";
    colLength2 = "col-sm-8";
  }
  if (colSize === 4) {
    colLength1 = "col-sm-4";
    colLength2 = "col-sm-8";
  }

  return (
    <div className={`col-${props.divColSize}`}>
      <div className="container p-0">
        <div className="form-group row border-bottom pb-2">
          <label htmlFor={props.name} className={colLength1}>
            {props.label}
            {(props?.required || props?.field?.required) && (
              <small className="text-danger">&nbsp; *</small>
            )}
          </label>
          <div className={colLength2}>
            {props.checkboxData
              ? props.checkboxData.map((options, index) => {
                  return (
                    <div key={index} className="form-check form-check-inline">
                      <label
                        className="form-check-label text-left btn btn-outline-primary mb-2"
                        htmlFor={props.name + index}
                      >
                        <FormInputFancyCheckboxElement
                          className={classnames("form-check-input", {
                            "is-invalid": props.error,
                          })}
                          type="checkbox"
                          checked={
                            props.value.indexOf(
                              "," + options.id.toString() + ",",
                            ) !== -1
                              ? "checked"
                              : null
                          }
                          name={props.name}
                          id={props.name + index}
                          value={options.id}
                          selectedCheckboxes={props.selectedCheckboxes}
                          handleCheckboxChange={props.handleCheckboxChange}
                        />
                        {options.name}
                      </label>
                    </div>
                  );
                })
              : null}
          </div>

          <InputError error={props.error} />
          <InputHelpText helpText={props.helpText} />
        </div>
      </div>
    </div>
  );
};
export default FormInputFancyCheckbox;
