import React, {Component} from 'react';
import { connect } from 'react-redux';
import { logout } from "../../client/actions/auth";
import { Navigate } from "react-router-dom";
import Loader from "../../components/layouts/Loader";

class SignIn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isAuthenticated: ''
    }
  };

  componentDidMount() {
    this.props.logout();
  }

  render() {
    const {isAuthenticated } = this.state;
    if (isAuthenticated === false) {
      return (<Navigate to="/auth/sign-in" />);
    }
    else {
      return (<Loader />);
    }
  }
}

const mapStateToProps = state => ({
  isAuthenticated : state.auth.isAuthenticated,
  auth: state.auth.user
});

export default connect(mapStateToProps, {logout} )(SignIn);
