import React, { useEffect, useState } from "react";
import { languageText } from "../config";
import DataOverview from "./data/Wrapper";
import { connect } from "react-redux";
import { getProfileInfo } from "../client/actions/apiActions";
import ProfileLinks from "./ProfileLinks";
import { useLocation, useNavigate } from "react-router-dom";

const tableName = "usersPassword";
const fieldData = [
  {
    fieldName: "currentPassword",
    fieldType: "password",
    colSize: 1,
    required: true,
  },
  {
    fieldName: "password",
    fieldType: "password",
    colSize: 1,
    required: true,
  },
  {
    fieldName: "passwordConfirm",
    fieldType: "password",
    colSize: 1,
    required: true,
  },
];
const fieldDataResults = {
  currentPassword: "",
  password: "",
  passwordConfirm: "",
};
const fieldDataDefault = [];

const Profile = (props) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const parentRoute = pathname;
  const [viewMode, setViewMode] = useState("loading");
  const [fieldDataResults, setFieldDataResults] = useState(props.auth.user);

  useEffect(() => {
    if (props.data.dataType === "saveRecord" && props.data.status === 1) {
      navigate("/auth/sign-out");
    }
  }, [props.data.status]);

  useEffect(() => {
    if (props.data.userProfile) {
      setFieldDataResults(props.data.userProfile);
      setViewMode("data");
    }
  }, [props.data.userProfile]);

  useEffect(() => {
    if (props.auth.user) {
      setFieldDataResults(props.auth.user);
      setViewMode("data");
    }
  }, [props.auth.user]);

  return (
    <div className="container p-0">
      <div className="row">
        <div className="col-md-3 col-2">
          <ProfileLinks linkActive="/profile-password" />
        </div>
        <div className="col-md-9 col-10">
          <DataOverview
            id={fieldDataResults.id}
            tableName={tableName}
            pageMode={"edit"}
            singleEdit={true}
            settings={{ showClone: false }}
            route={pathname}
            parentRoute={parentRoute}
            fieldData={fieldData}
            fieldDataResults={fieldDataResults}
            languageTable={tableName}
            pageTitle={languageText["en"].components[tableName].pageTitle}
            listTitle={languageText["en"].components[tableName].listTitle}
            listSubTitle={languageText["en"].components[tableName].listSubTitle}
            addTitle={languageText["en"].components[tableName].addTitle}
            addSubTitle={languageText["en"].components[tableName].addSubTitle}
            editTitle={languageText["en"].components[tableName].editTitle}
            editSubTitle={languageText["en"].components[tableName].editSubTitle}
            fieldDataDefault={fieldDataDefault}
          />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth.user,
  data: state.pages.data,
});

export default connect(mapStateToProps, { getProfileInfo })(Profile);
