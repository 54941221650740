import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "reactstrap";
import { MoreHorizontal as MoreIcon } from "react-feather";
import { ControlledMenu, MenuItem } from "@szhsin/react-menu";
import { connect } from "react-redux";
import { unFavoriteFile } from "../../client/actions/apiActions";
import { downloadFile } from "../../components/fileHelpers";
import Loading from "../../components/layouts/Loader";

const DashboardFavoriteFiles = (props) => {
  const navigate = useNavigate();
  const [favoriteFiles, setFavoriteFiles] = useState([]);
  const [selectedMenu, setSelectedMenu] = useState([]);
  const [reload, setReload] = useState(false);

  useEffect(() => {
    if (props.data.favoriteFiles) {
      setFavoriteFiles(props.data.favoriteFiles);
    }
  }, [props.data.favoriteFiles]);

  useEffect(() => {
    if (props.favoriteFiles) {
      setFavoriteFiles(props.favoriteFiles);
    }
  }, [props.favoriteFiles]);

  useEffect(() => {
    if (props.favoriteFiles) {
      setFavoriteFiles(props.favoriteFiles);
    }
  }, []);

  const toggleMenu = (menuId) => {
    if (typeof selectedMenu[menuId] === "undefined") {
      selectedMenu[menuId] = true;
    } else {
      selectedMenu[menuId] = selectedMenu[menuId] !== true;
    }
    setReload(!reload);
    setSelectedMenu(selectedMenu);
  };

  const moveFile = (file) => {
    alert("not implemented");
  };
  const openFolder = (file) => {
    navigate(`/documents/${file.clientId}/${file.folderId}${file.filePath}`);
  };

  const unFavoriteFile = (fileId) => {
    const tmpFiles = favoriteFiles.filter((file) => file.fileId !== fileId);
    setFavoriteFiles(tmpFiles);
    props.unFavoriteFile(fileId);
  };

  return (
    <div className="card border-dark border">
      <div className="card-header bg-dark">
        <h3 className="text-white m-2">Favorite Files</h3>
      </div>
      <div className="card-body p-0 m-0">
        {!props.isLoaded && <Loading />}
        {props.isLoaded && favoriteFiles && (
          <>
            <table className="table table-hover table-striped">
              <thead>
                <tr>
                  <th width={40}>&nbsp;</th>
                  <th>File Details</th>
                  <th width={40}>&nbsp;</th>
                </tr>
              </thead>
              <tbody>
                {favoriteFiles.length !== 0 &&
                  favoriteFiles.map((file, index) => {
                    return (
                      <tr key={index}>
                        <td>
                          <button className="col-1 text-center px-0 btn btn-link">
                            <i
                              onClick={() => unFavoriteFile(file.fileId)}
                              className="fas fa-star text-warning cursor-pointer"
                            />
                          </button>
                        </td>
                        <td>
                          <small>
                            <i>
                              {file.clientName}
                              {file.filePath}/{file.documentName}
                            </i>
                            <br />
                            Added {file.dateModified}
                          </small>
                        </td>
                        <td className="px-0 pt-2">
                          <Button
                            className="btn btn-sm btn-secondary"
                            onClick={() => toggleMenu(file.favoriteId)}
                          >
                            <MoreIcon size={15} />
                          </Button>
                          <ControlledMenu
                            onClose={() => toggleMenu(file.favoriteId)}
                            state={
                              selectedMenu[file.favoriteId] ? "open" : "closed"
                            }
                            direction="left"
                          >
                            <MenuItem onClick={() => downloadFile(file)}>
                              Download File
                            </MenuItem>
                            <MenuItem onClick={() => moveFile(file)}>
                              Move File
                            </MenuItem>
                            <MenuItem onClick={() => openFolder(file)}>
                              Open Folder
                            </MenuItem>
                          </ControlledMenu>
                        </td>
                      </tr>
                    );
                  })}
                {props.isLoaded &&
                  favoriteFiles &&
                  favoriteFiles.length === 0 && (
                    <tr>
                      <td colSpan={3} className="text-center py-2">
                        No favorites found
                      </td>
                    </tr>
                  )}
              </tbody>
            </table>
          </>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth.user,
  data: state.pages.data,
});

export default connect(mapStateToProps, { unFavoriteFile })(
  DashboardFavoriteFiles,
);
