import React, { useEffect, useState } from "react";
import { languageText } from "../config";
import DataOverview from "../components/data/Wrapper";
import Loader from "../components/layouts/Loader";
import { useLocation, useMatch, useParams } from "react-router-dom";
import { getList } from "../client/actions/apiActions";
import { connect } from "react-redux";

const tableName = "users";
const editPathName = "/my-team/:id";
const tableColumns = [
  {
    displayName: "Active",
    dataField: "active",
    type: "bool",
    sort: false,
  },
  {
    displayName: "Image",
    dataField: "image",
    type: "image",
    sort: true,
  },
  {
    displayName: "First Name",
    dataField: "firstName",
    sort: true,
  },
  {
    displayName: "Last Name",
    dataField: "lastName",
    sort: true,
  },
  {
    displayName: "Email",
    dataField: "email",
    sort: true,
  },
  {
    displayName: "Role",
    dataField: "role",
    sort: true,
  },
  {
    displayName: "Tools",
    dataField: "tools",
    sort: false,
  },
];
const defaultFieldData = [
  {
    displayName: "User Info",
    fieldName: "titleUsersName",
    fieldType: "title",
    colSize: 1,
  },
  {
    displayName: "First Name",
    fieldName: "firstName",
    fieldType: "text",
    colSize: 3,
    required: true,
  },
  {
    displayName: "Last Name",
    fieldName: "lastName",
    fieldType: "text",
    colSize: 3,
    required: true,
  },
  {
    displayName: "Active",
    fieldName: "active",
    fieldType: "toggle",
    colSize: 3,
  },
  {
    displayName: "Email",
    fieldName: "email",
    fieldType: "email",
    colSize: 2,
    required: true,
  },
  {
    displayName: "Phone",
    fieldName: "cellPhone",
    fieldType: "phone",
    colSize: 2,
  },
  {
    displayName: "Notes",
    fieldName: "notes",
    fieldType: "textarea",
    colSize: 1,
  },

  {
    displayName: "Role Info",
    fieldName: "titleRoleInfo",
    fieldType: "title",
    colSize: 1,
  },
  {
    displayName: "Role",
    fieldName: "userRole",
    fieldType: "radio",
    colSize: 1,
    required: true,
  },
  {
    fieldName: "organizationId",
    fieldType: "hidden",
    colSize: 0,
  },
];
const fieldDataDefault = {};

const MyTeam = (props) => {
  const [userRoles, setUserRoles] = useState([]);
  const [organizationId, setOrganizationId] = useState("");
  const [fieldData, setFieldData] = useState(defaultFieldData);
  const { id } = useParams();

  const { pathname } = useLocation();
  const match = useMatch(editPathName);
  const [loading, setLoading] = useState(true);
  let parentRoute = pathname;

  useEffect(() => {
    setLoading(false);
  }, []);
  useEffect(() => {
    props.getList("userRoles");
  }, []);

  useEffect(() => {
    if (props.data.tableName === "userRoles") {
      let tmpData = fieldData;
      tmpData.map((data, index) => {
        if (data.fieldName === "userRole") {
          tmpData[index].options = props.data.userRoles;
          setUserRoles(props.data.userRoles);
        }
      });
      setFieldData(tmpData);
      setLoading(false);
    }
  }, [props.data]);

  useEffect(() => {
    if (props.auth.user.organizationId) {
      setOrganizationId(props.auth.user.organizationId);
    }
  }, [props.auth]);

  if (match) {
    parentRoute = match.pattern.path.replace("/:id", "");
  }
  if (loading) return <Loader />;
  return (
    <DataOverview
      id={id ?? ""}
      organizationId={organizationId ?? ""}
      tableName={tableName}
      pageMode={id ? "edit" : "list"}
      route={pathname}
      lists={{
        userRole: userRoles,
      }}
      showOrganizationsLink={true}
      parentRoute={parentRoute}
      pageTitle={languageText["en"].components[tableName].pageTitle}
      listTitle={languageText["en"].components[tableName].listTitle}
      listSubTitle={languageText["en"].components[tableName].listSubTitle}
      addTitle={languageText["en"].components[tableName].addTitle}
      addSubTitle={languageText["en"].components[tableName].addSubTitle}
      editTitle={languageText["en"].components[tableName].editTitle}
      editSubTitle={languageText["en"].components[tableName].editSubTitle}
      tableColumns={tableColumns}
      fieldData={fieldData}
      fieldDataDefault={fieldDataDefault}
    />
  );
};
const mapStateToProps = (state) => ({
  auth: state.auth.user,
  data: state.pages.data,
});

export default connect(mapStateToProps, { getList })(MyTeam);
