import React, { useState, useEffect } from "react";
import Loader from "../../layouts/Loader";
import Buttons from "./Buttons";
import FormInput from "../../form/FormInput";

const EditForm = (props) => {
  const [dataFields, setDataFields] = useState(props.dataFields);
  const [dataResults, setDataResults] = useState(props.dataResults);
  const [loading, setLoading] = useState(true);
  const selectedCheckboxes = new Map();

  const { tableName, dataErrors, title, onCancel } = props;

  const onChange = (fieldType, fieldName, fieldValue) => {
    let fieldData = {};
    if (fieldType === "text") {
      fieldData[fieldName] = fieldValue;
    } else if (fieldType === "phone") {
      fieldData[fieldName] = fieldValue;
    } else if (fieldType === "email") {
      fieldData[fieldName] = fieldValue;
    } else if (fieldType === "textarea") {
      fieldData[fieldName] = fieldValue;
    } else if (fieldType === "toggle") {
      if (fieldValue === true || fieldValue === 1 || fieldValue === "1") {
        fieldData[fieldName] = "1";
      } else {
        fieldData[fieldName] = "0";
      }
    } else if (fieldType === "image") {
      fieldData[fieldName] = fieldValue;
    } else if (fieldType === "editor") {
      fieldData[fieldName] = fieldValue;
    } else if (fieldType === "select" || fieldType === "dropdown") {
      fieldData[fieldName] = fieldValue;
    } else if (fieldType === "password") {
      fieldData[fieldName] = fieldName;
    } else if (fieldType === "checkbox") {
      if (fieldData[fieldName]) {
        if (fieldData[fieldName].indexOf("," + fieldName + ",") !== -1) {
          fieldData[fieldName] = fieldData[fieldName].replace(
            "," + fieldName + ",",
            ",",
          );
        } else {
          fieldData[fieldName] = fieldData[fieldName] + fieldName + ",";
        }
      } else {
        fieldData[fieldName] = "," + fieldValue + ",";
      }
    } else if (fieldType === "custom") {
      fieldData[fieldName] = fieldValue;
    } else if (fieldType === "radio") {
      fieldData[fieldName] = fieldValue;
    } else {
      fieldData[fieldName] = fieldValue;
    }
    setDataResults({ ...dataResults, [fieldName]: fieldValue });
  };

  const onButtonCancel = () => {
    onCancel();
    selectedCheckboxes.clear();
  };

  const handleCheckboxChange = (name, label) => {
    let updatedCheckboxes = new Map(selectedCheckboxes);
    if (updatedCheckboxes.has(name) && updatedCheckboxes.get(name).has(label)) {
      updatedCheckboxes.get(name).delete(label);
    } else {
      if (!updatedCheckboxes.has(name)) updatedCheckboxes.set(name, new Set());
      updatedCheckboxes.get(name).add(label);
    }

    let checkedBoxes = Array.from(updatedCheckboxes.get(name)).join(",");
    setDataResults({ ...dataResults, [name]: checkedBoxes });
  };

  const changeValue = (name, value) => {
    dataResults[name] = value;
    setDataResults(dataResults);
  };

  useEffect(() => {
    setDataResults(props.dataResults);
    setLoading(false);
    setDataFields(props.dataFields);
  }, [props.dataFields, props.dataResults, tableName]);

  if (loading) {
    return <Loader />;
  } else {
    return (
      <div className="card border-dark border">
        <div className="card-header bg-dark">
          {title && (
            <h5 className="card-title text-white my-4 mx-2">{title}</h5>
          )}
        </div>
        <div className="card-body">
          <div className="container">
            <div className="row align-items-end pt-4">
              {dataFields.map((field, index) => {
                return (
                  <FormInput
                    key={`dataFields${index}`}
                    id={dataResults.id ?? ""}
                    field={field}
                    type={field.fieldType}
                    name={field.fieldName}
                    defaultValue={field.defaultValue}
                    options={field.options}
                    colSize={field.colSize}
                    value={dataResults[field.fieldName]}
                    changeValue={changeValue}
                    tableName={tableName}
                    onChange={(value) =>
                      onChange(field.fieldType, field.fieldName, value)
                    }
                    error={dataErrors[field.fieldName]}
                    label={field.displayName}
                    handleCheckboxChange={handleCheckboxChange}
                    placeholder={field.placeholder}
                  />
                );
              })}
            </div>
          </div>
        </div>
        <div className="card-footer">
          <Buttons
            {...props}
            dataResults={dataResults}
            onButtonCancel={onButtonCancel}
            mode={props.mode}
          />
        </div>
      </div>
    );
  }
};

export default EditForm;
