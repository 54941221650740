import React from "react";
import { Link } from "react-router-dom";
import { languageText } from "../../config";
import { connect } from "react-redux";

class Footer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    let languageCode = "";
    if (this.props.auth) {
      if (this.props.auth.user) {
        if (this.props.auth.user.user) {
          if (this.props.auth.user.user.languageCode)
            languageCode = this.props.auth.user.user.languageCode;
        }
      }
    }
    if (languageCode === "") languageCode = "en";

    return (
      <footer className="footer">
        <div className="container">
          <div className="row text-muted">
            <div className="col-6 text-start">
              <ul className="list-inline">
                <li className="list-inline-item">
                  <Link className="text-muted" to="/support-center">
                    {languageText["en"].footer.support}
                  </Link>
                </li>
                <li className="list-inline-item">
                  <Link className="text-muted" to="/help-center">
                    {languageText["en"].footer.helpCenter}
                  </Link>
                </li>
                <li className="list-inline-item">
                  <Link className="text-muted" to="/privacy">
                    {languageText["en"].footer.privacy}
                  </Link>
                </li>
                <li className="list-inline-item">
                  <Link className="text-muted" to="/terms-of-service">
                    {languageText["en"].footer.termsOfService}
                  </Link>
                </li>
              </ul>
            </div>
            <div className="col-6 text-end">
              <p className="mb-0">
                &copy; {new Date().getFullYear()} -{" "}
                <span className="text-muted">Simply Collaborate, LLC</span>
              </p>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

export default connect((store) => ({
  app: store.app,
  auth: store.auth,
}))(Footer);
