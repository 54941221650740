import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";

const DocumentBreadCrumb = (props) => {
  const { pathname } = useLocation();
  const {
    parentFolderPath,
    breadcrumb,
    clientId,
    companyName,
    userPermissions,
  } = props;
  // useEffect(() => {}, [props]);
  let folderCount = 0;

  return (
    <nav aria-label="breadcrumb">
      <ol className="breadcrumb p-2 rounded">
        {pathname !== "/documents/dashboard" &&
          (userPermissions?.userRole === 1 ||
            userPermissions?.userRole === 3) && (
            <BreadCrumbItem link={`/documents/dashboard`} name={"Businesses"} />
          )}
        {pathname === "/documents/dashboard" && (
          <BreadCrumbItem name={"Customers"} />
        )}
        {clientId !== 0 &&
          clientId !== null &&
          typeof clientId !== "undefined" &&
          parentFolderPath === "/" && <BreadCrumbItem name={companyName} />}
        {clientId !== 0 &&
          clientId !== null &&
          typeof clientId !== "undefined" &&
          parentFolderPath !== "/" && (
            <BreadCrumbItem
              link={`/documents/${clientId}`}
              name={companyName}
            />
          )}
        {parentFolderPath && (
          <>
            {typeof breadcrumb !== "undefined" && (
              <>
                {breadcrumb.map((folder, index) => {
                  folderCount++;
                  if (folder === "") {
                    return null;
                  }
                  if (folder.length !== folderCount) {
                    let path = `/documents/${clientId}/${folder.folderId}${folder.path}`;
                    let urlPath = encodeURI(path);
                    if (urlPath !== pathname) {
                      return (
                        <BreadCrumbItem
                          key={`breadcrumb-${index}`}
                          link={path}
                          name={folder.name}
                        />
                      );
                    } else {
                      return (
                        <BreadCrumbItem
                          key={`breadcrumb-${index}`}
                          link={""}
                          name={folder.name}
                        />
                      );
                    }
                  }
                  return null;
                })}
              </>
            )}
          </>
        )}
      </ol>
    </nav>
  );
};

const BreadCrumbItem = ({ name, link = "" }) => {
  if (link) {
    return (
      <li className="breadcrumb-item btn-link cursorClick">
        <Link to={link}>{name}</Link>
      </li>
    );
  } else {
    return <li className="breadcrumb-item">{name}</li>;
  }
};
export default DocumentBreadCrumb;
