import React, { useState, useRef, useEffect } from "react";
import { Button } from "reactstrap";

const FormInputFileDragAndDrop = ({ handleDrop, totalDocuments, children }) => {
  const [drag, setDrag] = useState(false);
  const dropRef = useRef(null);
  const hiddenFileInput = useRef(null);

  const handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDragIn = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDrag(true);
  };

  const handleDragOut = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDrag(false);
  };

  const handleDropEvent = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDrag(false);
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      handleDrop(e.dataTransfer.files);
      e.dataTransfer.clearData();
    }
  };

  useEffect(() => {
    const div = dropRef.current;
    div.addEventListener("dragenter", handleDragIn);
    div.addEventListener("dragleave", handleDragOut);
    div.addEventListener("dragover", handleDrag);
    div.addEventListener("drop", handleDropEvent);

    return () => {
      div.removeEventListener("dragenter", handleDragIn);
      div.removeEventListener("dragleave", handleDragOut);
      div.removeEventListener("dragover", handleDrag);
      div.removeEventListener("drop", handleDropEvent);
    };
  }, []);

  const handleClick = () => {
    hiddenFileInput.current.click();
  };

  const handleChange = (e) => {
    const fileUploaded = e.target.files;
    setDrag(false);
    if (fileUploaded) handleDrop(fileUploaded);
  };

  return (
    <div className="DragDropArea" ref={dropRef}>
      <input
        type="file"
        multiple
        id="uploadButton"
        name="uploadButton"
        ref={hiddenFileInput}
        onChange={handleChange}
        style={{ display: "none" }}
      />
      {totalDocuments === 0 && (
        <>
          {drag === false && (
            <div className="text-center text-dark py-1 mb-0 rounded border border-warning border-dashed border-4">
              Drag one or more files here to upload ...
              <br />
              - OR -<br />
              <Button color="warning" onClick={handleClick}>
                Click Here to Upload a File
              </Button>
            </div>
          )}
          {drag === true && (
            <div className="text-center bg-success text-dark py-5 mb-4 rounded border">
              Drop files to upload them ...
            </div>
          )}
        </>
      )}
      {children}
    </div>
  );
};

export default FormInputFileDragAndDrop;
