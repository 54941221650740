import {GET_CLIENTS, GET_LIST, GET_DATA, GET_CHAT_MESSAGES, GET_DOCUMENTS} from "../actions/types";

const initialState = {
    data: {},
    dataList: {},
    clientList: {},
};

export default function pages(state = initialState, action) {
    switch (action.type) {
        case GET_CLIENTS:
            return { ...state, clientList: action.payload };

        case GET_DATA:
            return {
                ...state,
                data: action.payload
            };

        case GET_LIST:
        case GET_DOCUMENTS:
            return {
                ...state,
                dataList: action.payload
            };


        case GET_CHAT_MESSAGES:
            return {
                ...state,
                data: action.payload
            };

        default:
            return state;
    }
}
