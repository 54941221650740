import { withRouter } from "../../../client/utils/withRouter";
import { Badge, Collapse } from "reactstrap";
import React from "react";

const SidebarCategory = ({
  name,
  badgeColor,
  badgeText,
  icon: Icon,
  isOpen,
  children,
  onClick,
  location,
  to,
}) => {
  const getSidebarItemClass = (path) => {
    return location?.pathname.indexOf(path) !== -1 ||
      (location.pathname === "/" && path === "/dashboard")
      ? "active"
      : "";
  };

  return (
    <li className={"sidebar-item " + getSidebarItemClass(to)}>
      <span
        data-toggle="collapse"
        className={"sidebar-link " + (!isOpen ? "collapsed" : "")}
        onClick={onClick}
        aria-expanded={isOpen ? "true" : "false"}
      >
        <Icon size={18} className="align-middle me-3" />
        <span className="align-middle">{name}</span>
        {badgeColor && badgeText ? (
          <Badge color={badgeColor} size={18} className="sidebar-badge">
            {badgeText}
          </Badge>
        ) : null}
      </span>
      <Collapse isOpen={isOpen}>
        <ul id="item" className={"sidebar-dropdown list-unstyled"}>
          {children}
        </ul>
      </Collapse>
    </li>
  );
};

export default withRouter(SidebarCategory);
