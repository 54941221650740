import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {Loader} from "react-feather";

const PrivateRoute = ({auth}) => {
    if (auth.isLoading) {
        return <Loader />
    }
    return auth.isAuthenticated ? <Outlet /> : <Navigate to="/auth/sign-in" />;
}

PrivateRoute.propTypes = {
    auth: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
    auth: state.auth
});

export default connect(mapStateToProps)(PrivateRoute);
