import React, { useEffect, useState } from "react";
import FormInput from "./FormInput";
import store from "../../client/store";
import { ApiURL } from "../../config";
import FormInputFileDragAndDrop from "./FormInputFileDragAndDrop";
import { Spinner } from "reactstrap";

const FormInputInlineUpload = (props) => {
  const [totalDocuments, setTotalDocuments] = useState(0);

  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [pendingFiles, setPendingFiles] = useState([]);
  const [errorFiles, setErrorFiles] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [fileIds, setFileIds] = useState([]);
  const [fileNames, setFileNames] = useState([]);
  const [reload, setReload] = useState(true);
  const [messageType, setMessageType] = useState("");
  const [message, setMessage] = useState("");
  const [uploadWarning, setUploadWarning] = useState("");

  const checkUploadSize = (files) => {
    let size = 0;
    files.forEach((file) => {
      size += file.size;
    });
    return size;
  };

  const uploadFiles = (uploadingFiles) => {
    if (typeof uploadingFiles !== "undefined") {
      setMessageType("");
      setMessage("");

      const uploadedFilesArray = Array.from(uploadingFiles);
      const size = checkUploadSize(uploadedFilesArray);
      if (size > 100000000) {
        return setMessage("File size is too large (100MB max)");
      }

      const formData = new FormData();
      const token = store.getState().auth.token;

      let uploadError = 0;
      formData.append("clientId", props.clientId);
      formData.append("folderId", props.folderId);
      formData.append("fullPath", props.parentFolderPath);

      if (uploadError !== 1) {
        uploadedFilesArray.forEach((file, i) => {
          if (fileList.includes(file.name)) {
            setMessage(uploadWarning + " " + file.name);
            setMessageType(1);
          } else {
            setPendingFiles([...pendingFiles, file.name]);
            formData.append(i, file);
          }
        });

        // SET UPLOADING TO TRUE
        fetch(`${ApiURL}/documentCenter/fileUpload`, {
          method: "POST",
          headers: { Authorization: token },
          body: formData,
        })
          .then((res) => res.json())
          .then((response) => {
            if (typeof response.documents === "undefined") {
              setPendingFiles([]);
              setErrorFiles([]);
              setUploadedFiles([]);
            }
            if (typeof response.documents !== "undefined") {
              response.documents.map((file, index) => {
                if (file.status === 1) {
                  let tmpUploadedFiles = uploadedFiles;
                  tmpUploadedFiles.push(file);
                  setUploadedFiles(tmpUploadedFiles);

                  let tmpFileIds = fileIds;
                  tmpFileIds.push(file.id);
                  setFileIds(tmpFileIds);

                  let tmpFileNames = fileNames;
                  tmpFileNames.push(file.fileName);
                  setFileNames(tmpFileNames);
                } else {
                  setMessage(file.message);
                  setMessageType(1);
                  setErrorFiles([
                    ...errorFiles,
                    {
                      error: file.statusReason,
                      name: file.fileName,
                    },
                  ]);
                }
                setFileList([...fileList, file.fileName]);
                setPendingFiles(
                  pendingFiles.filter((filename) => filename !== file.fileName),
                );
                return true;
              });
            }
            setTotalDocuments(uploadedFiles.length);
          });
      }
    }
  };

  const saveUploadedFileName = (fileId, fileName) => {
    const token = store.getState().auth.token;
    const formData = new FormData();

    formData.append("clientId", props.clientId);
    formData.append("fileId", fileId);
    formData.append("fileName", fileName);
    fetch(`${ApiURL}/documentCenter/fileUpdate`, {
      method: "POST",
      headers: { Authorization: token },
      body: formData,
    })
      .then((res) => res.json())
      .then((output) => {
        if (output.status === 1) {
          // Remove the file from uploadedFiles by filtering out the file with the matching fileId
          const updatedUploadedFiles = uploadedFiles.filter(
            (file) => file.id !== fileId,
          );
          setUploadedFiles(updatedUploadedFiles);

          const updatedFileIds = fileIds.filter((id) => id !== fileId);
          setFileIds(updatedFileIds);

          const updatedFileNames = fileNames.filter(
            (_, index) => fileIds[index] !== fileId,
          );
          setFileNames(updatedFileNames);

          if (updatedUploadedFiles.length === 0) {
            props.getFiles();
          } else {
            props.getFilesNoReload();
          }
        } else {
          setMessageType("danger");
          setMessage("Update Failed!");
        }
      });
  };

  const onChange = (name, value) => {
    fileNames[name] = value;
    setFileNames(fileNames);
    setReload(!reload);
  };

  return (
    <>
      <FormInputFileDragAndDrop handleDrop={uploadFiles} totalDocuments={0}>
        {totalDocuments !== 0 && (
          <div className="col-12 text-end">Total Files: {totalDocuments}</div>
        )}
        {messageType && (
          <div
            className={`text-center alert py-2 mb-2 rounded ${
              messageType === "danger" ? " alert-danger" : ""
            } ${messageType === "success" ? " alert-success" : ""}`}
          >
            {message}
          </div>
        )}
        <div className="DragDropFiles">
          {uploadWarning && (
            <div className="alert alert-danger py-2 mb-2 rounded">
              A file with the same name has already been uploaded, if the file
              you are uploading is a different file then rename it and try
              again. : {uploadWarning}
            </div>
          )}
          {uploadedFiles &&
            uploadedFiles.map((file, index) => {
              return (
                <div key={index} className="container">
                  <div className="form-group row pb-2 bg-dark text-light">
                    <label
                      htmlFor="fileName0"
                      className="col-sm-12 m-0 pt-2 pb-2"
                    >
                      File Name: {file.fileName}
                    </label>
                  </div>
                  <FormInput
                    type="hidden"
                    name={`fileIds[${index}]`}
                    value={fileIds[index]}
                  />
                  <FormInput
                    type="text"
                    name={`fileNames[${index}]`}
                    value={fileNames[index]}
                    label="File Name"
                    placeholder="name ..."
                    helpText="Write short title of what the file is, example: '2018 personal tax return'"
                    onChange={(value) => onChange(index, value)}
                  >
                    <button
                      className="btn btn-success"
                      onClick={() =>
                        saveUploadedFileName(fileIds[index], fileNames[index])
                      }
                    >
                      Save
                    </button>
                  </FormInput>
                </div>
              );
            })}
          {uploadedFiles && uploadedFiles.length > 0 && (
            <button className="btn btn-warning" onClick={props.getFiles}>
              Skip Renaming All Files
            </button>
          )}
          {pendingFiles &&
            pendingFiles.map((file, index) => {
              return (
                <div key={index} className="container my-1">
                  <div className="row bg-dark text-light py-2">
                    <div className="col-auto">
                      <Spinner color="primary" />
                    </div>
                    <div className="col py-2">File Name: {file}</div>
                  </div>
                </div>
              );
            })}
          {errorFiles &&
            errorFiles.map((file, index) => {
              return (
                <div key={index} className="container">
                  <div className="form-group row pb-2 bg-danger text-light">
                    <label
                      htmlFor="fileName0"
                      className="col-sm-12 m-0 pt-3 pb-1"
                    >
                      {file.error}: {file.name}
                    </label>
                  </div>
                </div>
              );
            })}
        </div>
      </FormInputFileDragAndDrop>
    </>
  );
};

export default FormInputInlineUpload;
