import {
  Button,
  Modal as ModalWindow,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import React from "react";

const Modal = ({
  handleClose,
  show,
  children,
  title,
  onSave,
  className,
  modalType,
}) => {
  return (
    <ModalWindow
      isOpen={show}
      toggle={() => handleClose()}
      className={className}
    >
      <ModalHeader toggle={() => handleClose()}>{title}</ModalHeader>
      <ModalBody>{children}</ModalBody>
      <ModalFooter>
        {modalType === "inviteUser" && (
          <Button color="primary" onClick={() => onSave()}>
            {modalType === "inviteUser" && <>Send Invite</>}
          </Button>
        )}{" "}
        <Button color="secondary" onClick={() => handleClose()}>
          Cancel
        </Button>
      </ModalFooter>
    </ModalWindow>
  );
};
export default Modal;
