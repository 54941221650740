import React, { useState, Fragment } from "react";
import classnames from "classnames";
import ShowInputError from "./ShowInputError";
import store from "../../client/store";
import { ApiURL } from "../../config";

const FormInputFileDrag = (props) => {
  const { colSize, name, label, error, helpText, field, required } = props;
  const [filesToUpload, setFilesToUpload] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [pendingFiles, setPendingFiles] = useState([]);
  const [errorFiles, setErrorFiles] = useState([]);
  const [totalDocuments, setTotalDocuments] = useState(0);
  const [uploadWarning, setUploadWarning] = useState("");

  const uploadFiles = (uploadingFiles) => {
    if (typeof uploadingFiles !== "undefined") {
      const uploadedFilesArray = Array.from(uploadingFiles);
      const formData = new FormData();
      const token = store.getState().auth.token;
      setUploadWarning("");
      let uploadError = 0;
      formData.append("clientId", ""); // Update accordingly
      formData.append("folderId", ""); // Update accordingly
      formData.append("fullPath", ""); // Update accordingly
      if (uploadError !== 1) {
        uploadedFilesArray.forEach((file, i) => {
          if (fileList.includes(file.name)) {
            setUploadWarning((prevWarning) => prevWarning + " " + file.name);
          } else {
            setPendingFiles((prevPendingFiles) => [
              ...prevPendingFiles,
              file.name,
            ]);
            formData.append(i, file);
          }
        });

        fetch(`${ApiURL}/fileUpload`, {
          method: "POST",
          headers: { Authorization: token },
          body: formData,
        })
          .then((res) => res.json())
          .then((filesUploaded) => {
            if (typeof filesUploaded.documents !== "undefined") {
              filesUploaded.documents.forEach((file, index) => {
                if (file.status === 1) {
                  setUploadedFiles((prevUploadedFiles) => [
                    ...prevUploadedFiles,
                    file,
                  ]);
                  setFileList((prevFileList) => [
                    ...prevFileList,
                    file.fileName,
                  ]);
                  setPendingFiles((prevPendingFiles) =>
                    prevPendingFiles.filter(
                      (filename) => filename !== file.fileName,
                    ),
                  );
                } else {
                  setErrorFiles((prevErrorFiles) => [
                    ...prevErrorFiles,
                    {
                      error: file.statusReason,
                      name: file.fileName,
                    },
                  ]);
                  setFileList((prevFileList) => [
                    ...prevFileList,
                    file.fileName,
                  ]);
                  setPendingFiles((prevPendingFiles) =>
                    prevPendingFiles.filter(
                      (filename) => filename !== file.fileName,
                    ),
                  );
                }
              });
            }
            setTotalDocuments(uploadedFiles.length);
          });
      }
    }
  };

  const saveUploadFileNames = () => {
    // Implementation remains similar, adjust to use useState hooks
  };

  const cancelUpload = () => {
    setUploadedFiles([]);
    setUploadWarning("");
    setPendingFiles([]);
    setFileList([]);
    setErrorFiles([]);
    setTotalDocuments(0);
  };

  let divColSize;
  if (colSize === 0) divColSize = 0;
  else if (colSize === 1) divColSize = 12;
  else if (colSize === 2) divColSize = 6;
  else if (colSize === 3) divColSize = 4;
  else if (colSize === 4) divColSize = 8;

  return (
    <div className={`col-12 align-bottom col-sm-${divColSize}`}>
      <div className="container p-0">
        <div className="form-group row pb-2">
          <label
            htmlFor={name}
            className={classnames("col-sm-12 m-0 pt-2 pb-2", {
              "is-invalid": error,
            })}
          >
            {label}
            {(props?.required || props?.field?.required) && (
              <small className="text-danger">&nbsp; *</small>
            )}
          </label>
          {helpText && (
            <>
              <div className="col-4">&nbsp;</div>
              <small id={name + "Help"} className="col-8 form-text text-muted">
                {helpText}
              </small>
            </>
          )}
          {error && <ShowInputError error={error} />}
        </div>
      </div>
    </div>
  );
};

export default FormInputFileDrag;
