import { Link } from "react-router-dom";
import { Eye as ViewIcon, Mail as EmailIcon } from "react-feather";
import React, { useEffect } from "react";
import { resendInvite } from "../../client/actions/apiActions";
import { connect } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import Loading from "../../components/layouts/Loader";

const DashboardNewUsers = (props) => {
  const { newUsers } = props;
  useEffect(() => {
    if (props.data.dataType === "resendInvite") {
      if (props.data.status === true) {
        toast.success("Invite was sent successfully!", {
          theme: "colored",
        });
      } else {
        toast.warn("Error sending invite.", {
          theme: "colored",
        });
      }
    }
  }, [props]);
  return (
    <div className="card border-dark border">
      <div className="card-header bg-dark">
        <h3 className="text-white m-2">New Users</h3>
      </div>
      <div className="card-body p-0 m-0">
        {!props.isLoaded && <Loading />}
        {props.isLoaded && newUsers && (
          <>
            <div className="py-3 px-2">
              <p>These users have not yet accepted their invites</p>
            </div>
            <table className="table table-hover table-striped">
              <thead>
                <tr>
                  <th width={80}>Resend</th>
                  <th>Name</th>
                  <th>&nbsp;</th>
                </tr>
              </thead>
              <tbody>
                {newUsers.length > 0 &&
                  newUsers.map((user, index) => {
                    return (
                      <tr key={index}>
                        <td className="col-9">
                          {user.firstName} &nbsp;
                          {user.lastName} &nbsp; - &nbsp;
                          {user.companyName} &nbsp;
                          {user.email}
                        </td>
                        <td>
                          <button
                            className="btn btn-sm btn-success me-1"
                            onClick={() => props.resendInvite(user.id)}
                          >
                            <EmailIcon size={18} />
                          </button>
                          <Link
                            to={`/organizations/users/${user.organizationId}`}
                            className="btn btn-sm btn-primary"
                          >
                            <ViewIcon size={18} />
                          </Link>
                        </td>
                      </tr>
                    );
                  })}
                {newUsers && newUsers.length === 0 && (
                  <tr>
                    <td className="text-center py-2" colSpan={3}>
                      No new users found
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </>
        )}
      </div>
      <ToastContainer />
    </div>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth.user,
  data: state.pages.data,
});

export default connect(mapStateToProps, { resendInvite })(DashboardNewUsers);
