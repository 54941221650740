import React, { useEffect, useState } from "react";
import { Provider } from "react-redux";
import store from "./client/store";
import Routes from "./client/routes/Routes";
import { loadUser } from "./client/actions/auth";

import FirebaseControls from "./components/FirebaseControls";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const App = () => {
  useEffect(() => {
    store.dispatch(loadUser());
  }, []);

  return (
    <Provider store={store}>
      <FirebaseControls />
      <Routes />
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </Provider>
  );
};

export default App;
