import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import React from "react";

const ConfirmAlert = (props) => {
  return (
    <Modal isOpen={true} toggle={props.closeModal}>
      <ModalHeader toggle={props.closeModal}>{props?.title}</ModalHeader>
      <ModalBody>{props?.children}</ModalBody>
      <ModalFooter>
        <button
          className="btn btn-sm btn-outline-primary"
          onClick={props.closeModal}
        >
          Cancel
        </button>
        {props?.button?.primary && (
          <button
            className={`btn btn-sm btn-${
              props.button.primary.color ?? "primary"
            }`}
            onClick={props.button.primary.action}
          >
            {props.button.primary.text}
          </button>
        )}
      </ModalFooter>
    </Modal>
  );
};
export default ConfirmAlert;
