import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { archiveSubDataRecord } from "../../../client/actions/apiActions";
import { Table } from "./Table";
import { SearchFunction } from "./SearchFunction";
import { SearchFilters } from "./SearchFilters";
import { TableAddButtons } from "./TableAddButtons";

const Overview = (props) => {
  const { tableName, title, slogan, organizationId } = props;

  return (
    <div className="card border-dark border">
      <div className="card-header bg-dark">
        <div className="row mb-2">
          <div className="col-9">
            {title && (
              <h5 className="card-title text-white my-4 mx-2">{title}</h5>
            )}
            {slogan && (
              <h6 className="card-subtitle text-white mx-2">{slogan}</h6>
            )}
          </div>
          {tableName === "users" &&
            props.showOrganizationsLink &&
            organizationId !== "" &&
            props.auth.userRole.organizationsCanAdd === "1" &&
            typeof organizationId !== "undefined" && (
              <div className="col-3 text-end">
                <Link
                  className="btn btn-warning btn-sm mt-2"
                  to="/settings/customers"
                >
                  Back to Organizations
                </Link>
              </div>
            )}
        </div>
        <div className="row align-items-center">
          <TableAddButtons {...props} />
          {!props.showFilters && <SearchFunction {...props} />}
          {props.showFilters && <SearchFilters {...props} />}
        </div>
      </div>
      <div className="card-body p-0 m-0">
        <Table
          {...props}
          onArchive={(id) => props.archiveSubDataRecord(tableName, id)}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, { archiveSubDataRecord })(Overview);
