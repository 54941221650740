import {
  CheckCircle as ActiveIcon,
  Edit as EditIcon,
  Eye as UnArchiveIcon,
  Eye as ViewIcon,
  EyeOff as ArchiveIcon,
  Mail as EmailIcon,
  Trash2 as DeleteIcon,
  Users as UsersIcons,
  XCircle as InActiveIcon,
} from "react-feather";
import React from "react";
import { Button } from "reactstrap";
import { Link } from "react-router-dom";

export const TableDataRow = ({
  tableData,
  columns,
  tableName,
  onViewsUsers,
  onResendInvite,
  onDelete,
  onArchive,
  userPermissions,
  parentRoute,
  lists,
}) => {
  const processList = (list, value) => {
    if (!value) return;
    if (value.indexOf(",") === -1) {
      let tmpValue = list.find((x) => x.id === value.toString());
      if (tmpValue) {
        return tmpValue.name;
      }
    }
    let data = value.split(",");
    let values = "";
    data.map((item, index) => {
      if (item) {
        let tmpValue = list.find((x) => x.id === item.toString());
        if (tmpValue) {
          if (values !== "") values += ",";
          values += tmpValue.name;
        }
      }
      return null;
    });
    return values;
  };

  console.log("tableData", tableData);

  if (tableData.length !== 0) {
    return (
      <>
        {tableData.map((dataRow, index) => {
          return (
            <tr key={index}>
              {columns.map((column, index) => {
                let value = dataRow[column.dataField];
                if (column.type === "bool") {
                  return (
                    <td
                      key={index}
                      style={{ width: 75 }}
                      className="text-center"
                    >
                      {(value === 1 ||
                        value === "1" ||
                        value === true ||
                        value === "true") && (
                        <ActiveIcon className="text-success" size={18} />
                      )}
                      {(value === 0 ||
                        value === "0" ||
                        value === false ||
                        value === "false") && (
                        <InActiveIcon className="text-danger" size={18} />
                      )}
                    </td>
                  );
                } else if (column.type === "image") {
                  if (value !== "") {
                    return (
                      <td key={index}>
                        <img
                          src={value}
                          style={{ maxWidth: 60 }}
                          alt="thumbnail"
                          border="0"
                        />
                      </td>
                    );
                  } else {
                    return <td key={index}>&nbsp;</td>;
                  }
                } else if (column.type === "list") {
                  return (
                    <td key={index}>
                      {processList(lists[column.dataField], value)}
                    </td>
                  );
                } else if (column.dataField === "tools") {
                  let viewPermissions;
                  let editPermissions;
                  let deletePermissions;
                  let archivePermissions;

                  if (tableName === "users") {
                    editPermissions = true;
                    archivePermissions = true;
                    deletePermissions = true;
                    viewPermissions = editPermissions === false;
                  } else if (tableName === "organizations") {
                    editPermissions = true;
                    archivePermissions = true;
                    deletePermissions = true;
                    viewPermissions = editPermissions === false;
                  } else {
                    editPermissions = true;
                    deletePermissions = true;
                    archivePermissions = true;
                    viewPermissions = editPermissions === false;
                  }

                  return (
                    <td
                      key={index}
                      nowrap="true"
                      style={{ width: 220 }}
                      className="text-end"
                    >
                      {viewPermissions === true && (
                        <Button
                          className="btn btn-sm btn-primary me-1"
                          onClick={() => onArchive(dataRow.id)}
                        >
                          <ViewIcon size={18} />
                        </Button>
                      )}
                      {tableName === "organizations" && (
                        <Button
                          className="btn btn-sm btn-success me-1"
                          onClick={() => onViewsUsers(dataRow.id)}
                        >
                          <UsersIcons size={18} />
                        </Button>
                      )}
                      {tableName === "users" && dataRow.status === 0 && (
                        <button
                          className="btn btn-sm btn-success me-1"
                          onClick={() => onResendInvite(dataRow.id)}
                        >
                          <EmailIcon size={18} />
                        </button>
                      )}
                      {editPermissions === true &&
                        (dataRow.editButton === "true" ||
                          dataRow.editButton === true) && (
                          <Link
                            className="btn btn-sm btn-primary me-1"
                            to={`${parentRoute}/${dataRow.id}`}
                          >
                            <EditIcon size={18} />
                          </Link>
                        )}
                      {deletePermissions === true &&
                        (dataRow.deleteButton === "true" ||
                          dataRow.deleteButton === true) && (
                          <button
                            className="btn btn-sm btn-danger me-1"
                            onClick={() => onDelete(dataRow.id)}
                          >
                            <DeleteIcon size={18} />
                          </button>
                        )}
                      {archivePermissions === true &&
                        typeof dataRow.active !== "undefined" &&
                        dataRow.active === "0" && (
                          <button
                            className="btn btn-sm btn-success me-1"
                            onClick={() => onArchive(dataRow.id)}
                          >
                            <UnArchiveIcon size={18} />
                          </button>
                        )}
                      {archivePermissions === true &&
                        typeof dataRow.active !== "undefined" &&
                        dataRow.active === "1" && (
                          <button
                            className="btn btn-sm btn-warning me-1"
                            onClick={() => onArchive(dataRow.id)}
                          >
                            <ArchiveIcon size={18} />
                          </button>
                        )}
                    </td>
                  );
                } else {
                  return <td key={index}>{value}</td>;
                }
              })}
            </tr>
          );
        })}
      </>
    );
  }

  if (tableData.length === 0) {
    return (
      <tr>
        <td colSpan={columns.length} className="text-center">
          No results to display
        </td>
      </tr>
    );
  }
};
