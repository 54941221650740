import { Button } from "reactstrap";
import { Search as SearchIcon, XOctagon as ResetIcon } from "react-feather";
import React from "react";

export const SearchFunction = (props) => {
  if (typeof props.settings !== "undefined") {
    if (props.settings.showSearch !== false) {
      return (
        <div className="col-12 col-sm">
          <div className="row align-items-center">
            <div className="col-12">
              <div className="input-group">
                <span className="input-group-text" id="basic-addon3">
                  Search Term:
                </span>
                <input
                  id="searchText"
                  name="searchText"
                  value={props.searchText}
                  className="form-control"
                  onKeyUp={(e) => {
                    props.searchKeyPress(e);
                  }}
                  onChange={(e) => props.setSearchText(e.target.value)}
                />
                <Button color="success" onClick={() => props.searchOverview()}>
                  <SearchIcon size={18} /> Search
                </Button>
                <Button color="secondary" onClick={() => props.resetSearch()}>
                  <ResetIcon size={18} /> Reset
                </Button>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
  return null;
};
