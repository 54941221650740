import React, { useEffect, useState } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import logo from "../../assets/img/logo/logo.png";
import axios from "axios";
import { ApiURL } from "../../config";
import { toast } from "react-toastify";

const ResetPassword = (props) => {
  const [sentPassword, setSentPassword] = useState(false);
  const [username, setUsername] = useState("");
  const [verificationCode, setVerificationCode] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [processComplete, setProcessComplete] = useState(false);
  const [showCodeScreen, setShowCodeScreen] = useState(false);
  const [showResetScreen, setShowResetScreen] = useState(false);
  const [showUserScreen, setShowUserScreen] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    makeFocus();
  }, []);

  const makeFocus = () => {
    try {
      window.username.focus();
    } catch {}
  };

  const onChange = (e) => {
    if (e.target.name === "username") {
      setUsername(e.target.value);
    }
    if (e.target.name === "verificationCode") {
      setVerificationCode(e.target.value);
    }
    if (e.target.name === "password") {
      setPassword(e.target.value);
    }
    if (e.target.name === "passwordConfirm") {
      setPasswordConfirm(e.target.value);
    }
    setErrorMessage("");
    setSuccessMessage("");
  };

  // Step 1 : Send email to user
  const onSubmit = async () => {
    setErrorMessage("");
    setSuccessMessage("");

    const res = await axios.post(
      `${ApiURL}/auth/forgotPass`,
      { username: username },
      {},
    );

    if (res.data.status === true) {
      setErrorMessage("");
      setSuccessMessage(res.data.message);
      setShowCodeScreen(true);
      setShowUserScreen(false);
      setShowResetScreen(false);
    } else {
      setErrorMessage(res.data.message);
      setSuccessMessage("");
    }
  };

  // Step 2 : Check verification code
  const onSubmitVerification = async () => {
    setErrorMessage("");
    setSuccessMessage("");

    const res = await axios.post(
      `${ApiURL}/auth/checkVerificationCode`,
      { verificationCode, username },
      {},
    );

    if (res.data.status === true) {
      setErrorMessage("");
      setSuccessMessage(res.data.message);
      setShowCodeScreen(false);
      setShowUserScreen(false);
      setShowResetScreen(true);
    } else {
      setErrorMessage(res.data.message);
      setSuccessMessage("");
    }
  };

  // Step 3 : Save new password
  const onSubmitPassword = async () => {
    setErrorMessage("");
    setSuccessMessage("");

    const res = await axios.post(
      `${ApiURL}/auth/saveForgotPass`,
      { username, verificationCode, password, passwordConfirm },
      {},
    );

    if (res.data.status === true) {
      onSuccess(res.data.message);
    } else {
      setErrorMessage(res.data.message);
      setSuccessMessage("");
    }
  };

  const onSuccess = (message) => {
    setErrorMessage("");
    setSuccessMessage(message);
    setShowCodeScreen(false);
    setShowUserScreen(false);
    setShowResetScreen(false);
    setProcessComplete(true);
    toast.success("Password reset successfully");
    navigate("/auth/sign-in");
  };

  const onFormSubmit = (e) => {
    e.preventDefault();
  };

  return (
    <>
      <Card>
        <CardBody>
          <div className="col-12 text-center">
            <img
              src={logo}
              alt="Simply Collaborate, Inc"
              className="img-fluid"
              width="250"
              height="250"
            />
          </div>
          <div className="m-sm-12">
            {errorMessage && (
              <div className="alert">
                <div className="alert-danger w-100">
                  <div className="alert-message">
                    <i className="fas fa-exclamation-triangle" /> &nbsp;
                    {errorMessage}
                  </div>
                </div>
              </div>
            )}
            {successMessage && (
              <div className="alert">
                <div className="alert-success w-100">
                  <div className="alert-message">
                    <i className="fas fa-check" /> &nbsp;
                    {successMessage}
                  </div>
                </div>
              </div>
            )}
            {showUserScreen === true && (
              <>
                <div className="text-center">
                  <h1 className="h2">Reset password</h1>
                  <p className="lead">
                    Enter your email to reset your password.
                  </p>
                </div>

                <Form onSubmit={onFormSubmit}>
                  <FormGroup>
                    <Label>Username / Email</Label>
                    <Input
                      bsSize="lg"
                      type="email"
                      id="username"
                      name="username"
                      value={username}
                      placeholder="Enter your username"
                      onChange={onChange}
                    />
                  </FormGroup>
                  <div className="text-center mt-3">
                    <Button color="primary" size="lg" onClick={onSubmit}>
                      Reset password
                    </Button>
                    <br />
                    <br />
                    <Link to="/auth/sign-in">
                      <Button color="secondary" size="sm">
                        Back
                      </Button>
                    </Link>
                  </div>
                </Form>
              </>
            )}

            {showCodeScreen === true && (
              <>
                <div className="text-center">
                  <h1 className="h2">Reset password</h1>
                  <p className="lead">
                    Check your email and enter the code from your email below.
                  </p>
                </div>
                <form onSubmit={onFormSubmit}>
                  <div className="form-group row my-5">
                    <label className="col-sm-4 col-form-label text-end">
                      Verification Code
                    </label>
                    <div className="col-sm-8">
                      <input
                        type="text"
                        name="verificationCode"
                        onChange={onChange}
                        className="form-control"
                        autoComplete="section-red verificationCode"
                        value={verificationCode}
                      />
                    </div>
                  </div>
                  <div className="text-center mt-3">
                    <Button
                      color="primary"
                      size="lg"
                      onClick={onSubmitVerification}
                    >
                      {" "}
                      SUBMIT
                    </Button>
                    <br />
                    <br />
                    <Link to="/auth/sign-in">
                      <Button color="secondary" size="sm">
                        Back
                      </Button>
                    </Link>
                  </div>
                </form>
              </>
            )}

            {showResetScreen === true && (
              <>
                <div className="text-center">
                  <h1 className="h2">Reset password</h1>
                  <p className="lead">Enter your new password</p>
                </div>
                <form onSubmit={onFormSubmit}>
                  <div className="form-group row my-5">
                    <label className="col-sm-4 col-form-label text-end">
                      Password
                    </label>
                    <div className="col-sm-8">
                      <input
                        type="password"
                        name="password"
                        onChange={onChange}
                        className="form-control"
                        autoComplete="section-red new-password"
                        value={password}
                      />
                    </div>
                  </div>
                  <div className="form-group row my-5">
                    <label className="col-sm-4 col-form-label text-end">
                      Confirm Password
                    </label>
                    <div className="col-sm-8">
                      <input
                        type="password"
                        name="passwordConfirm"
                        onChange={onChange}
                        className="form-control"
                        autoComplete="section-red new-password"
                        value={passwordConfirm}
                      />
                    </div>
                  </div>
                  <button
                    className="btn btn-primary btn-block"
                    onClick={onSubmitPassword}
                  >
                    <i className="fas fa-unlock-alt" /> SUBMIT
                  </button>
                </form>
              </>
            )}
          </div>
        </CardBody>
      </Card>
    </>
  );
};

export default ResetPassword;
