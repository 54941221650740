import React, { useEffect, useState } from "react";
import { UncontrolledAlert } from "reactstrap";
import { connect } from "react-redux";
import {
  getNotificationList,
  markNotificationRead,
} from "../client/actions/apiActions";
import {
  AlertCircle,
  Bell,
  Home,
  MessageSquare as MessageIcon,
  UserPlus,
} from "react-feather";
import { languageText } from "../config";
import ProfileLinks from "./ProfileLinks";

const tableName = "users";

const ProfileNotifications = (props) => {
  const [notifications, setNotifications] = useState([]);

  useEffect(() => {
    if (props.data.dataType === "notificationList") {
      setNotifications(props.data.notifications);
    }
  }, [props]);

  const markNotificationRead = (notificationId) => {
    props.markNotificationRead(notificationId);
  };

  return (
    <div className="container p-0">
      <div className="row">
        <div className="col-md-3 col-2">
          <ProfileLinks linkActive="/profile-notifications" />
        </div>
        <div className="col-md-9 col-10">
          <h3 className="py-2 text-center">
            {
              languageText["en"].components[tableName + "Notifications"]
                .pageTitle
            }
          </h3>
          <h6 className="text-muted text-center">
            {
              languageText["en"].components[tableName + "Notifications"]
                .editSubTitle
            }
          </h6>
          <br />

          {notifications.length === 0 && (
            <div className="container">
              <div className="row">
                <div className="col-12">There are no new notifications</div>
              </div>
            </div>
          )}
          {notifications.map((notification, key) => {
            let color = "primary";
            if (notification.notificationType === "important") {
              color = "danger";
            }
            if (notification.notificationType === "message") {
              color = "dark";
            }
            if (notification.notificationType === "login") {
              color = "primary";
            }
            if (notification.notificationType === "request") {
              color = "success";
            }
            return (
              <UncontrolledAlert
                color={color}
                className="alert-outline-coloured"
                key={key}
                toggle={() => {
                  markNotificationRead(notification.id);
                }}
              >
                <div className="alert-icon">
                  {notification.notificationType === "important" && (
                    <AlertCircle size={18} fixedWidth />
                  )}
                  {notification.notificationType === "message" && (
                    <MessageIcon size={18} fixedWidth />
                  )}
                  {notification.notificationType === "login" && (
                    <Home size={18} fixedWidth />
                  )}
                  {notification.notificationType === "request" && (
                    <UserPlus size={18} fixedWidth />
                  )}
                  {notification.notificationType === "notification" && (
                    <Bell size={18} fixedWidth />
                  )}
                </div>
                <div className="alert-message">
                  {notification.time} - {notification.notificationSubject}
                  <br />
                  {notification.notificationMessage}
                </div>
              </UncontrolledAlert>
            );
          })}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth.user,
  data: state.pages.data,
  dataList: state.pages.dataList,
});

export default connect(mapStateToProps, {
  getNotificationList,
  markNotificationRead,
})(ProfileNotifications);
