import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import connect from "react-redux/es/connect/connect";

import { ControlledMenu, MenuItem } from "@szhsin/react-menu";
import {
  archiveFile,
  deleteFile,
  favoriteFile,
  moveFile,
  unFavoriteFile,
} from "../../client/actions/apiActions";
import FormInput from "../../components/form/FormInput";
import { Button } from "reactstrap";
import store from "../../client/store";
import { ApiURL } from "../../config";
import { MoreHorizontal as MoreIcon } from "react-feather";
import FolderModal from "./FolderModal";
import ConfirmAlert from "../../components/ConfirmAlert";
import { toast } from "react-toastify";

const DocumentFiles = (props) => {
  const { files } = props;
  let { clientId } = useParams();
  const [multiSelect, setMultiSelect] = useState("");
  const [selectedMenu, setSelectedMenu] = useState([]);
  const [userRole, setUserRole] = useState({});
  const [userInfo, setUserInfo] = useState({});
  const [reload, setReload] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [modalType, setModalType] = useState("");
  const [originalFileName, setOriginalFileName] = useState("");
  const [editFileId, setEditFileId] = useState("");
  const [folderLocation, setFolderLocation] = useState("");
  const [confirmProps, setConfirmProps] = useState({});

  const [actions, setActions] = useState([]);

  const toggleMenu = (menuId) => {
    if (typeof selectedMenu[menuId] === "undefined") {
      selectedMenu[menuId] = true;
    } else {
      selectedMenu[menuId] = selectedMenu[menuId] !== true;
    }
    setReload(!reload);
    setSelectedMenu(selectedMenu);
  };

  const downloadFile = (fileId) => {
    window.open(ApiURL + "/documentCenter/viewFile/" + fileId);
  };

  const editFile = (fileInfo) => {
    setOriginalFileName(fileInfo.name ? fileInfo.name : fileInfo.documentName);
    setEditFileId(fileInfo.id);
    setShowModal(true);
    setModalType("editFile");
  };

  const processMultiSelect = (action) => {
    let fileIds = [];
    files.map((file) => {
      if (file.selected) {
        fileIds.push(file.id);
      }
      return null;
    });
    switch (action) {
      case "download":
        multiDownloadFiles(fileIds);
        break;
      case "delete":
        multiDeleteFiles(fileIds);
        break;
      case "move":
        multiMoveFiles(fileIds);
        break;
      case "favorite":
        favoriteFiles(fileIds);
        break;
      case "unfavorite":
        unFavoriteFiles(fileIds);
        break;
      default:
        break;
    }
  };

  const moveFile = (fileId) => {
    setModalType("moveFile");
    setEditFileId(fileId);
    setShowModal(true);
    setReload(!reload);
  };

  const confirmMoveFile = (fileId, folder) => {
    const token = store.getState().auth.token;
    const formData = new FormData();
    formData.append("clientId", clientId);
    formData.append("fileId", fileId);
    formData.append("folderId", folder);

    fetch(`${ApiURL}/documentCenter/fileMove`, {
      method: "POST",
      headers: { Authorization: token },
      body: formData,
    })
      .then((res) => res.json())
      .then((output) => {
        if (output.status === 1) {
          toast.success("Move was successful!");
          setConfirmProps({});
          setFolderLocation("");
          props.getFiles();
        } else {
          toast.error("Move Failed!");
        }
      });
  };

  const confirmSaveFile = (clickedFileName) => {
    const token = store.getState().auth.token;
    const formData = new FormData();
    formData.append("clientId", clientId);
    formData.append("fileId", editFileId);
    formData.append("fileName", clickedFileName);

    fetch(`${ApiURL}/documentCenter/fileRename`, {
      method: "POST",
      headers: { Authorization: token },
      body: formData,
    })
      .then((res) => res.json())
      .then((output) => {
        if (output.status === 1) {
          setShowModal(false);
          setModalType("");
          props.getFiles();
          toast.success("File Rename was successful!");
        } else {
          toast.error("File Rename Failed!");
        }
      });
  };

  const multiMoveFiles = (fileIds) => {
    setModalType("moveFiles");
    setEditFileId(fileIds);
    setShowModal(true);
    setReload(!reload);
  };

  const confirmMoveFiles = (fileIds, folderId) => {
    const token = store.getState().auth.token;
    const formData = new FormData();
    formData.append("clientId", clientId);
    formData.append("folderId", folderId);
    formData.append("fileIds", fileIds);

    fetch(`${ApiURL}/documentCenter/filesMove`, {
      method: "POST",
      headers: { Authorization: token },
      body: formData,
    })
      .then((res) => res.json())
      .then((output) => {
        if (output.status === 1) {
          toast.success("Move Files was successful!");
          props.getFiles();
        } else {
          toast.error("Move Files Failed!");
        }
      });
  };

  const multiDownloadFiles = (fileIds) => {
    window.open(ApiURL + "/documentCenter/viewFiles/" + fileIds);
  };

  const favoriteFiles = (fileIds) => {
    const token = store.getState().auth.token;
    const formData = new FormData();
    formData.append("clientId", clientId);
    formData.append("fileIds", fileIds);

    fetch(`${ApiURL}/documentCenter/favoriteFiles`, {
      method: "POST",
      headers: { Authorization: token },
      body: formData,
    })
      .then((res) => res.json())
      .then((output) => {
        if (output.status === 1) {
          toast.success("Favorite was successful!");
          props.getFiles();
        } else {
          toast.error("Favorite Failed!");
        }
      });
  };

  const unFavoriteFiles = (fileIds) => {
    const token = store.getState().auth.token;
    const formData = new FormData();
    // this.setState({ uploadWarning: '', saveMessage: "", saveMessageType: ""});
    formData.append("clientId", clientId);
    formData.append("fileIds", fileIds);

    fetch(`${ApiURL}/documentCenter/unFavoriteFiles`, {
      method: "POST",
      headers: { Authorization: token },
      body: formData,
    })
      .then((res) => res.json())
      .then((output) => {
        if (output.status === 1) {
          toast.success("Un-Favorite was successful!");
          props.getFiles();
        } else {
          toast.error("Un-Favorite Failed!");
        }
      });
  };

  const onFavoriteFile = (fileId) => {
    const token = store.getState().auth.token;
    const formData = new FormData();
    formData.append("clientId", clientId);
    formData.append("fileId", fileId);

    fetch(`${ApiURL}/documentCenter/favoriteFile`, {
      method: "POST",
      headers: { Authorization: token },
      body: formData,
    })
      .then((res) => res.json())
      .then((output) => {
        if (output.status === 1) {
          toast.success("Favorite was successful!");
          props.getFiles();
        } else {
          toast.error("Favorite Failed!");
        }
      });
  };

  const onUnFavoriteFile = (fileId) => {
    const token = store.getState().auth.token;
    const formData = new FormData();
    formData.append("clientId", clientId);
    formData.append("fileId", fileId);

    fetch(`${ApiURL}/documentCenter/unFavoriteFile`, {
      method: "POST",
      headers: { Authorization: token },
      body: formData,
    })
      .then((res) => res.json())
      .then((output) => {
        if (output.status === 1) {
          toast.success("Un-Favorite was successful!");
          props.getFiles();
        } else {
          toast.error("Un-Favorite Failed!");
        }
      });
  };

  const multiSelectOnChange = (value) => {
    const fileIndex = files.findIndex((file) => file.id === value);
    if (fileIndex !== -1) {
      files[fileIndex].selected = !files[fileIndex].selected;
      setReload(!reload);
    }
  };

  const deleteFile = (fileId) => {
    setConfirmProps({
      show: true,
      title: "Confirm Delete",
      button: {
        primary: {
          text: "Delete",
          action: () => {
            onDeleteFile(fileId);
            setConfirmProps({});
          },
          color: "danger",
        },
      },
      children: (
        <p>
          Are you sure you want to delete these files. You can not restore later
          on.
        </p>
      ),
    });
  };

  const onDeleteFile = (fileId) => {
    const token = store.getState().auth.token;
    const formData = new FormData();
    // this.setState({ uploadWarning: '', saveMessage: "", saveMessageType: ""});
    formData.append("clientId", clientId);
    formData.append("fileId", fileId);

    fetch(`${ApiURL}/documentCenter/deleteFile`, {
      method: "POST",
      headers: { Authorization: token },
      body: formData,
    })
      .then((res) => res.json())
      .then((output) => {
        if (output.status === 1) {
          toast.success("Delete was successful!");
          props.getFiles();
        } else {
          toast.error("Delete File Failed!");
        }
      });
  };

  const multiDeleteFiles = (fileIds) => {
    setConfirmProps({
      show: true,
      title: "Confirm Delete",
      button: {
        primary: {
          text: "Delete",
          action: () => {
            confirmedMultiDeleteFiles(fileIds);
            setConfirmProps({});
          },
          color: "danger",
        },
      },
      children: (
        <p>
          Are you sure you want to delete these files. You can not restore later
          on.
        </p>
      ),
    });
  };

  const confirmedMultiDeleteFiles = (fileIds) => {
    const token = store.getState().auth.token;
    const formData = new FormData();
    formData.append("clientId", clientId);
    formData.append("fileIds", fileIds);

    fetch(`${ApiURL}/documentCenter/deleteFiles`, {
      method: "POST",
      headers: { Authorization: token },
      body: formData,
    })
      .then((res) => res.json())
      .then((output) => {
        if (output.status === 1) {
          toast.success("Delete Files was successful!");
          props.getFiles();
        } else {
          toast.error("Delete File Failed!");
        }
      });
  };

  const closeFolderModal = () => {
    setShowModal(false);
    setModalType("");
  };

  const updatePermissions = () => {
    const {
      filesCanDelete,
      filesCanDownload,
      filesCanEdit,
      filesCanMove,
      filesCanUpload,
      foldersAdd,
      foldersDelete,
      foldersEdit,
    } = props.auth.user.userRole;
    /*
        filesCanDelete
        filesCanDownload
        filesCanEdit
        filesCanMove
        filesCanUpload
        foldersAdd
        foldersDelete
        foldersEdit
    */

    let localActions = [];
    if (filesCanDownload === "1" || userInfo.userType === "A") {
      localActions.push({ id: "download", name: "Download Files" });
    }
    localActions.push({ id: "favorite", name: "Favorite Files" });
    localActions.push({ id: "unfavorite", name: "Un-Favorite Files" });
    if (filesCanMove === "1" || userInfo.userType === "A") {
      localActions.push({ id: "move", name: "Move Files" });
    }
    if (filesCanDelete === "1" || userInfo.userType === "A") {
      localActions.push({ id: "delete", name: "Delete Files" });
    }

    setActions(localActions);
    // const actions = [
    //   { id: "download", name: "Download Files" },
    //   { id: "move", name: "Move Files" },
    //   { id: "delete", name: "Delete Files" },
    // ];
    console.log("updatePermissions", props.auth.user.userRole);
  };

  useEffect(() => {
    if (props.auth.user.userRole) {
      setUserInfo(props.auth.user);
      setUserRole(props.auth.user.userRole);
    }
    if (props.data.favorites) {
      files.map((file, index) => {
        let objIndex = props.data.favorites.findIndex(
          (fav) => fav.fileId === file.id,
        );
        files[index].favorite = objIndex !== -1;
        return null;
      });
    }
    setReload(!reload);
  }, [props]);

  useEffect(() => {
    updatePermissions();
    if (props.favorites) {
      files.map((file, index) => {
        let objIndex = props.favorites.findIndex(
          (fav) => fav.fileId === file.id,
        );
        files[index].favorite = objIndex !== -1;
        return null;
      });
    }
  }, []);

  return (
    <>
      {files.length > 0 &&
        files.map((fileRow, index) => {
          return (
            <tr key={`file-${index}`}>
              <td width="50" className="text-center">
                <input
                  name={`fileSelected${fileRow.id}`}
                  value={fileRow.id}
                  type="checkbox"
                  onChange={(e) => multiSelectOnChange(e.target.value)}
                  checked={fileRow.selected}
                />
              </td>
              <td width="50">
                <Button
                  className="btn btn-sm btn-secondary mx-1 my-0 p-1"
                  onClick={() => toggleMenu("file" + fileRow.id)}
                >
                  <MoreIcon size={15} />
                </Button>
                <ControlledMenu
                  style={{ marginLeft: "-50px", display: "block" }}
                  onClose={() => toggleMenu("file" + fileRow.id)}
                  state={selectedMenu["file" + fileRow.id] ? "open" : "closed"}
                >
                  {(userRole.filesCanEdit === "1" ||
                    userInfo.userType === "A") && (
                    <MenuItem onClick={() => editFile(fileRow)}>
                      Rename
                    </MenuItem>
                  )}
                  {(userRole.filesCanMove === "1" ||
                    userInfo.userType === "A") && (
                    <MenuItem onClick={() => moveFile(fileRow.id)}>
                      Move
                    </MenuItem>
                  )}
                  {(userRole.filesCanDownload === "1" ||
                    userInfo.userType === "A") && (
                    <MenuItem onClick={() => downloadFile(fileRow.id)}>
                      Download
                    </MenuItem>
                  )}
                  {(userRole.filesCanDelete === "1" ||
                    userInfo.userType === "A") && (
                    <MenuItem
                      onClick={() => deleteFile(fileRow.id)}
                      className="bg-danger text-white"
                    >
                      Delete
                    </MenuItem>
                  )}
                </ControlledMenu>
              </td>
              <td
                nowrap="true"
                valign="middle"
                className="align-middle text-center"
              >
                {fileRow.favorite && (
                  <i
                    onClick={() => onUnFavoriteFile(fileRow.id)}
                    className="fas fa-star text-warning cursor-pointer"
                  />
                )}
                {!fileRow.favorite && (
                  <i
                    onClick={() => onFavoriteFile(fileRow.id)}
                    className="far fa-star cursor-pointer"
                  />
                )}
              </td>
              <td>{fileRow.documentType}</td>
              <td nowrap="true" valign="middle" className="align-middle">
                {fileRow.name ?? fileRow.documentName}
                <br />
                <small>{fileRow.documentName}</small>
              </td>
              <td nowrap="true" valign="middle" className="align-middle">
                {fileRow.dateUploaded}
              </td>
              <td nowrap="true" valign="middle" className="align-middle">
                {fileRow.documentSize}
              </td>
            </tr>
          );
        })}

      {files.filter((file) => file.selected).length > 0 && (
        <tr>
          <td colSpan="4">
            <FormInput
              type="select"
              name="multiSelect"
              hideBorder="true"
              value={multiSelect}
              options={actions}
              onChange={(value) => {
                setMultiSelect(value);
                processMultiSelect(value);
              }}
            />
          </td>
          <td colSpan="3">&nbsp;</td>
        </tr>
      )}

      {confirmProps?.show && (
        <tr>
          <td colSpan={7}>
            <ConfirmAlert
              title={confirmProps.title}
              button={confirmProps.button}
              closeModal={() => {
                setFolderLocation("");
                setConfirmProps({});
              }}
            >
              {confirmProps.children}
            </ConfirmAlert>
          </td>
        </tr>
      )}

      {showModal && (
        <tr>
          <td colSpan={7}>
            <FolderModal
              clientFolders={props.clientFolders}
              folderTypes={props.folderTypes}
              showModal={showModal}
              modalType={modalType}
              originalFileName={originalFileName}
              confirmSaveFile={confirmSaveFile}
              confirmMoveFile={confirmMoveFile}
              confirmMoveFiles={confirmMoveFiles}
              closeFolderModal={closeFolderModal}
              fileId={editFileId}
            />
          </td>
        </tr>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  documentList: state.pages.documentList,
  dataList: state.pages.dataList,
  data: state.pages.data,
});
export default connect(mapStateToProps, {
  deleteFile,
  moveFile,
  favoriteFile,
  unFavoriteFile,
  archiveFile,
})(DocumentFiles);
