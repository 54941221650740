import React, { Component } from "react";
import { connect } from "react-redux";
import {
  toggleSidebar,
  hideSidebar,
  toggleSidebarNew,
} from "../../client/actions/sidebarActions";
import { languageText } from "../../config";
import { Link } from "react-router-dom";

import { getNotifications } from "../../client/actions/apiActions";
import {
  Button,
  Collapse,
  Navbar,
  Nav,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  ListGroup,
  ListGroupItem,
} from "reactstrap";
import {
  AlertCircle,
  Bell,
  Bell as NotificationsIcon,
  Home,
  Settings,
  MessageSquare as MessageIcon,
  User,
  UserPlus,
  Power as LogoutIcon,
  HelpCircle as HelpIcon,
} from "react-feather";

class NavbarComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: false,
      defaultLanguage: "en",
      notifications: [],
      messages: [],
    };
  }
  componentDidMount() {
    this.setState({
      notifications: this.props?.auth?.user?.notifications,
      messages: this.props?.auth?.user?.messages,
    });
    // this.startTimer();
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps !== this.props) {
      if (this.state.notifications !== this.props.notifications) {
        this.setState({
          notifications: this.props.auth?.user?.notifications,
          messages: this.props.auth?.user?.messages,
        });
      }
    }
  }

  windowLinkClick = (linkTo) => {
    window.location.href = linkTo;
  };

  render() {
    const { notifications } = this.state;
    let userInfo = {};

    if (typeof this.props.auth !== "undefined" && this.props.auth !== null) {
      if (
        typeof this.props.auth.user !== "undefined" &&
        this.props.auth.user !== null
      ) {
        userInfo = this.props.auth?.user?.user;
      }
    }
    let avatar = "";
    if (userInfo?.image === "") {
      avatar = "/assets/img/avatars/default.jpg";
    }

    return (
      <Navbar color="white" light expand>
        <span
          className="sidebar-toggle d-flex me-2"
          onClick={() => toggleSidebarNew()}
        >
          <i className="hamburger align-self-center" />
        </span>

        <Collapse navbar>
          <Nav navbar>
            <NavbarDropdown
              header={languageText["en"].navBar.newNotifications}
              footer={languageText["en"].navBar.showAllNotifications}
              footerLink={() => this.windowLinkClick("/notifications")}
              icon={NotificationsIcon}
              count={notifications?.length}
              showBadge
            >
              {notifications?.map((item, key) => {
                let notificationUrl = "/notifications";
                if (item.notificationUrl !== "")
                  notificationUrl = item.notificationUrl;

                let icon = <Bell size={18} className="text-warning" />;
                if (item.notificationType === "important") {
                  icon = <AlertCircle size={18} className="text-danger" />;
                }
                if (item.notificationType === "message") {
                  icon = <MessageIcon size={18} className="text-dark" />;
                }
                if (item.notificationType === "login") {
                  icon = <Home size={18} className="text-primary" />;
                }
                if (item.notificationType === "request") {
                  icon = <UserPlus size={18} className="text-success" />;
                }
                return (
                  <NavbarDropdownItem
                    key={key}
                    icon={icon}
                    onClick={() => this.windowLinkClick(notificationUrl)}
                    title={item.notificationSubject}
                    description={item.notificationMessage}
                    time={item.time}
                  />
                );
              })}
            </NavbarDropdown>

            <UncontrolledDropdown nav inNavbar>
              <span className="d-inline-block d-sm-none">
                <DropdownToggle nav caret>
                  <Settings size={18} className="align-middle" />
                </DropdownToggle>
              </span>
              <span className="d-none d-sm-inline-block">
                <DropdownToggle nav caret>
                  {avatar && (
                    <img
                      src={avatar}
                      className="avatar img-fluid rounded-circle me-1"
                      alt={`${userInfo.firstName} ${userInfo.lastName}`}
                    />
                  )}
                  <span className="text-dark">
                    {userInfo.firstName} {userInfo.lastName}
                  </span>
                </DropdownToggle>
              </span>
              <DropdownMenu>
                <DropdownItem>
                  <Link to="/profile">
                    <User size={18} className="align-middle me-2" />
                    {languageText["en"].navBar.profile}
                  </Link>
                </DropdownItem>

                <DropdownItem divider />

                <DropdownItem>
                  <Link to="/help-center">
                    <HelpIcon size={18} className="align-middle me-2" />
                    {languageText["en"].navBar.help}
                  </Link>
                </DropdownItem>

                <DropdownItem>
                  <Link to="/auth/sign-out">
                    <LogoutIcon size={18} className="align-middle me-2" />
                    {languageText["en"].navBar.signOut}
                  </Link>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>
        </Collapse>
      </Navbar>
    );
  }
}

const NavbarDropdown = ({
  children,
  count,
  showBadge,
  header,
  footer,
  footerLink,
  icon: Icon,
}) => {
  if (count === 0) showBadge = false;
  return (
    <UncontrolledDropdown nav inNavbar className="me-2">
      <DropdownToggle nav className="nav-icon dropdown-toggle">
        <div className="position-relative">
          <Icon className="align-middle" size={18} />
          {showBadge ? <span className="indicator">{count}</span> : null}
        </div>
      </DropdownToggle>
      <DropdownMenu end className="dropdown-menu-sm-start py-0">
        <div className="dropdown-menu-header position-relative">
          {count} {header}
        </div>
        <ListGroup>{children}</ListGroup>
        {count !== 0 && (
          <DropdownItem header className="dropdown-menu-footer">
            {typeof footerLink !== "undefined" &&
              footerLink !== "" &&
              footerLink !== "undefined" && (
                <Button color="link" onClick={footerLink}>
                  <span className="text-muted">{footer}</span>
                </Button>
              )}
            {typeof footerLink === "undefined" && (
              <span className="text-muted">{footer}</span>
            )}
          </DropdownItem>
        )}
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};

const NavbarDropdownItem = ({
  icon,
  title,
  description,
  time,
  spacing,
  onClick,
  messageCount,
}) => (
  <ListGroupItem onClick={onClick}>
    <div className="row align-items-center cursor-pointer no-gutters">
      <div className="col-2">{icon}</div>
      <div className={spacing ? "pl-2 col-10" : "col-10"}>
        <div className="text-dark">{title}</div>
        <div className="text-muted small mt-1">{description}</div>
        <div className="text-muted small mt-1">{time}</div>
        {messageCount ? (
          <span className="indicator">{messageCount}</span>
        ) : null}
      </div>
    </div>
  </ListGroupItem>
);

const mapDispatchToProps = (dispatch) => {
  return {
    getNotifications: () => dispatch(getNotifications()),
    toggleSidebar: () => dispatch(toggleSidebar()),
    hideSidebar: () => dispatch(hideSidebar()),
    toggleSidebarNew: () => dispatch(toggleSidebarNew()),
    dispatch,
  };
};

export default connect(
  (store) => ({
    sidebar: store.sidebar,
    app: store.app,
    auth: store.auth,
    data: store.pages.data,
  }),
  mapDispatchToProps,
)(NavbarComponent);
