import React, { useEffect, useState } from "react";
import { Button } from "reactstrap";
import { Plus as PlusIcon, X as TimesIcon } from "react-feather";
import { toast } from "react-toastify";

export const SearchFilters = (props) => {
  const { showFilters, filters, columns, updateFilter } = props;
  const [filterText, setFilterText] = useState("");
  const [filterColumn, setFilterColumn] = useState("");
  const [filterColumnData, setFilterColumnData] = useState(props.columns);
  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);

  const onFilterTextChange = (e) => {
    setFilterText(e.target.value);
  };

  const onFilterDropdownChange = (e) => {
    setFilterText(e.target.value);
  };

  const selectColumn = (e) => {
    let selectedColumn = e.target.value;
    setFilterColumn(selectedColumn);
    let tColumn = columns.filter(
      (column) => column.dataField === selectedColumn,
    )[0];
    setFilterColumnData(tColumn);
  };

  const saveFilter = () => {
    if (filterColumn === "") {
      toast.error("You did not select a column to search", {
        theme: "colored",
      });
    } else if (filterText === "") {
      toast.error("You did not enter a search term to search", {
        theme: "colored",
      });
    } else {
      let display = columns.filter(
        (column) => column.dataField === filterColumn,
      )[0].displayName;

      let newFilters = filters;
      newFilters.push({
        display: display,
        keyword: filterText,
        column: filterColumn,
      });
      updateFilter(newFilters);
      setFilterColumn("");
      setFilterText("");
      forceUpdate();
    }
  };

  const deleteFilter = (columnName) => {
    let newFilters = filters.filter((filter) => filter.column !== columnName);
    updateFilter(newFilters);
    forceUpdate();
  };

  useEffect(() => {
    forceUpdate();
  }, [filters]);

  if (!showFilters) return null;

  return (
    <div className="row">
      <div className="col-12 col-sm text-left text-white mb-0">
        <div className="container p-0 m-0">
          <div className="row">
            <div className="col">Field:</div>
            <div className="col">Search Term:</div>
            <div className="col-8">&nbsp;</div>
          </div>
          <div className="row">
            <div className="col">
              <select
                className="form-control"
                id="recordsPerPage"
                name="recordsPerPage"
                onChange={selectColumn}
                value={filterColumn}
              >
                <option value="">-- SELECT --</option>
                {columns &&
                  columns.length > 0 &&
                  columns.map((column, index) => {
                    if (column.filter === false) return null;
                    return (
                      <option value={column.dataField} key={index}>
                        {column.displayName}
                      </option>
                    );
                  })}
              </select>
            </div>
            <div className="col">
              <div className="input-group">
                {filterColumnData &&
                  filterColumnData.filterType &&
                  filterColumnData.filterType === "dropdown" && (
                    <select
                      className="form-control"
                      id="recordsPerPage"
                      name="recordsPerPage"
                      onChange={onFilterDropdownChange}
                      value={filterText}
                    >
                      <option value="">-- SELECT --</option>
                      {filterColumnData.filterData &&
                        filterColumnData.filterData.length > 0 &&
                        filterColumnData.filterData.map((data, index) => {
                          if (data.filter === false) return null;
                          return (
                            <option value={data.id} key={index}>
                              {data.name}
                            </option>
                          );
                        })}
                    </select>
                  )}
                {filterColumnData &&
                  (typeof filterColumnData.filterType === "undefined" ||
                    filterColumnData.filterType !== "dropdown") && (
                    <input
                      id="searchText"
                      name="searchText"
                      value={filterText}
                      className="form-control"
                      onChange={onFilterTextChange}
                    />
                  )}
                <Button color="success" onClick={() => saveFilter()}>
                  <PlusIcon size={18} />
                </Button>
              </div>
            </div>
            <div className="col-8">
              {filters.map((filter, index) => {
                return (
                  <div
                    key={`filter${index}`}
                    className="badge rounded-pill bg-primary px-3 mr-1"
                  >
                    {filter.display} : {filter.keyword} &nbsp;
                    <TimesIcon onClick={() => deleteFilter(filter.column)} />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
