import React from "react";
import Main from "./Main";

const Auth = ({ children }) => (
  <>
    <Main className="d-flex w-100 justify-content-center bg-dark">
      <div className="d-flex flex-column container">
        <div className="h-100 row">
          <div className="col-sm-10 col-md-6 col-lg-4 col-12 mx-auto d-table h-100">
            <div className="d-table-cell align-middle">{children}</div>
          </div>
        </div>
      </div>
    </Main>
  </>
);

export default Auth;
