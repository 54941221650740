import React from "react";
import { languageText } from "../config";
import { connect } from "react-redux";
import { Link, useResolvedPath } from "react-router-dom";

const ProfileLinks = () => {
  const { pathname } = useResolvedPath();

  return (
    <div className="card">
      <div className="card-header">
        <div className="card-title mb-0">
          {languageText["en"].components["usersProfile"].links.title}
        </div>
      </div>
      <div className="card-body">
        <div className="list-group">
          <Link
            to="/profile"
            className={`list-group-item ${
              pathname === "/profile" ? "active" : ""
            }`}
          >
            {languageText["en"].components["usersProfile"].links.link1}
          </Link>
          <Link
            to="/profile-password"
            className={`list-group-item ${
              pathname === "/profile-password" ? "active" : ""
            }`}
          >
            {languageText["en"].components["usersProfile"].links.link3}
          </Link>
          <Link
            to="/profile-notifications"
            className={`list-group-item ${
              pathname === "/profile-notifications" ? "active" : ""
            }`}
          >
            {languageText["en"].components["usersProfile"].links.link4}
          </Link>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth.user,
});

export default connect(mapStateToProps, {})(ProfileLinks);
